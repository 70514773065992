@mixin sparkjop-button {
    @include lib-button-primary(
        $_button-border: 2px solid $sparkjop_DarkBlue,
        $_button-border-hover: 2px solid darken($sparkjop_DarkBlue, 5%),
        $_button-border-active: 2px solid darken($sparkjop_DarkBlue, 5%),
        $_button-color: $sparkjop_DarkBlue,
        $_button-color-hover: $sparkjop_White,
        $_button-color-active: $sparkjop_White,
        $_button-background: $sparkjop_White,
        $_button-background-hover: $sparkjop_DarkBlue,
        $_button-background-active: $sparkjop_DarkBlue
    );
    padding: 1rem;
    font-weight: 700;
    font-size: 14px;
    text-transform: unset;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    transition: background 0.25s ease;
    &:hover{
        text-decoration: none;
    }
}
@mixin sparkjop-button-black {
    @include lib-button-primary(
        $_button-border: 2px solid $sparkjop_Black,
        $_button-border-hover: 2px solid darken($sparkjop_Black, 5%),
        $_button-border-active: 2px solid darken($sparkjop_Black, 5%),
        $_button-color: $sparkjop_Black,
        $_button-color-hover: darken($sparkjop_Black, 5%),
        $_button-color-active: darken($sparkjop_Black, 5%),
        $_button-background: $sparkjop_White,
        $_button-background-hover: darken($sparkjop_White, 5%),
        $_button-background-active: darken($sparkjop_White, 5%)
    );
    padding: 1rem;
    font-weight: 700;
    font-size: 14px;
    text-transform: unset;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    transition: background 0.25s ease;
    &:visited {
        color: $sparkjop_DarkBlue;
    }
}
@mixin sparkjop-button-cta {
    @include lib-button-primary(
        $_button-border: $polarcore-primary-cart-button__border,
        $_button-border-hover: $polarcore-primary-cart-button__border,
        $_button-border-active: $polarcore-primary-cart-button__border,
        $_button-color: $polarcore-primary-cart-button__color,
        $_button-background: $polarcore-primary-cart-button__background,
        $_button-background-hover: darken($polarcore-primary-cart-button__background, 8%),
        $_button-background-active: darken($polarcore-primary-cart-button__background, 8%)
    );
    padding: 1rem;
    text-transform: none;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: $polarcore-primary-cart-button__box-shadow;
    transition: background 0.25s ease;
}

@mixin sparkjop-button-cta-white {
    @include lib-button-primary(
        $_button-border: 2px solid $polarcore-primary-cart-button__border,
        $_button-border-hover: 2px solid $polarcore-primary-cart-button__border,
        $_button-border-active: 2px solid darken($polarcore-primary-cart-button__border, 5%),
        $_button-color: $polarcore-primary-cart-button__background,
        $_button-color-hover: darken($polarcore-primary-cart-button__background, 5%),
        $_button-color-active: darken($polarcore-primary-cart-button__background, 5%),
        $_button-background: $sparkjop_White,
        $_button-background-hover: darken($sparkjop_White, 5%),
        $_button-background-active: darken($sparkjop_White, 5%)
    );
    padding: 1rem;
    font-weight: 700;
    font-size: 14px;
    text-transform: unset;
    border-radius: 5px;
    transition: background 0.25s ease;
    &:visited {
        color: $polarcore-primary-cart-button__border;
    }
    &:hover,
    &:active {
        background: $polarcore-primary-cart-button__background;
        color: $sparkjop_White;
    }
}
@mixin sparkjop-text-button {
    background: none;
    border: none;
    color: $sparkjop_CodGrey;

    &:hover {
        color: $sparkjop_CodGrey;
        text-decoration: underline;
    }
}
@mixin sparkjop-button-black-text {
    @include sparkjop-button();
    color: $sparkjop_CodGrey;
    padding: 8px 20px;
}
@mixin sparkjop-button-blue {
    @include sparkjop-button();
    color: white;
    background-color: $sparkjop_DarkBlue;

    &:hover {
        color: darken($sparkjop_DarkBlue, 5%);
        background-color: white;
    }
}
