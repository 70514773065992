$grid-size: 12 !default;

@mixin grid-columns($grid-bp) {
  @for $i from 1 through $grid-size {
    .custom-grid-#{$grid-bp}-#{$i} {
      -ms-grid-columns: (1fr)[ #{$i} ];
      grid-template-columns: repeat(#{$i}, calc((100% / #{$i}) - 1em));
    }
  }
}

@mixin grid-columns-moz($grid-bp) {
  @-moz-document url-prefix() {
    .custom-grid-#{$grid-bp}-1 {
      grid-template-columns: 100%;
    }
    @for $i from 2 through $grid-size {
      .custom-grid-#{$grid-bp}-#{$i} {
        grid-template-columns: repeat(#{$i}, calc((100% / #{$i}) - calc(1em / (#{$i} / (#{$i} - 1)))));
      }
    }
  }
}

@mixin grid-h_center($grid-bp) {
  @for $i from 1 through $grid-size {
    @if $i % 2 == 0 {
      .custom-grid-#{$grid-bp}-#{$i}.h_center > * {
        width: 50%;
      }
    }
  }
  .custom-grid-#{$grid-bp}-2.h_center > * {
    grid-column: 1 / 3;
  }
  .custom-grid-#{$grid-bp}-3.h_center > * {
    grid-column: 3 / 2;
  }
  .custom-grid-#{$grid-bp}-4.h_center > * {
    grid-column: 4 / 2;
  }
  .custom-grid-#{$grid-bp}-5.h_center > * {
    grid-column: 3 / 3;
  }
  .custom-grid-#{$grid-bp}-6.h_center > * {
    grid-column: 5 / 3;
  }
  .custom-grid-#{$grid-bp}-7.h_center > * {
    grid-column: 4 / 5;
  }
  .custom-grid-#{$grid-bp}-8.h_center > * {
    grid-column: 4 / 6;
  }
  .custom-grid-#{$grid-bp}-9.h_center > * {
    grid-column: 6 / 5;
  }
  .custom-grid-#{$grid-bp}-10.h_center > * {
    grid-column: 7 / 5;
  }
  .custom-grid-#{$grid-bp}-11.h_center > * {
    grid-column: 7 / 6;
  }
  .custom-grid-#{$grid-bp}-12.h_center > * {
    grid-column: 8 / 6;
  }
}


@media only screen and (min-width: 768px) {
  $grid-bp: tablet !default;
  @include grid-columns($grid-bp);
  @include grid-columns-moz($grid-bp);
  @include grid-h_center($grid-bp);
}
@media only screen and (min-width: 1200px) {
  $grid-bp: desktop !default;
  @include grid-columns($grid-bp);
  @include grid-columns-moz($grid-bp);
  @include grid-h_center($grid-bp);
}

.custom-grid {
  display: -ms-grid;
  display: grid;
  grid-gap: 2em;
  word-break: break-word;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  &.collapsed {
    grid-gap: 0;
  }
  &[class*="-stacked"] {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }
  &[class*="-half"] {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  &[class*="-left"] {
    -ms-grid-columns: 2fr 1fr;
    grid-template-columns: 2fr 1fr;
  }
  &[class*="-right"] {
    -ms-grid-columns: 1fr 2fr;
    grid-template-columns: 1fr 2fr;
  }

  $grid-bp: mobile !default;
  @include grid-columns($grid-bp);
  @include grid-columns-moz($grid-bp);
  @include grid-h_center($grid-bp);

  .custom-grid.h_center > * {
    margin: auto;
  }
}
