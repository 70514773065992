body.account {
    // order page
	.order {
		&-status {
			position: absolute;
			right: 0;
			font-weight: 600;
			text-transform: initial;
			font-size: 17px;
			border: none;
			transition: opacity .25s ease-in-out;
			opacity: 0;
			&.ferdig {
				background: #28a745;
				border: none;
				color: white;
				opacity: 1;
			}
			&.p-vent {
				background: #ffc107;
				opacity: 1;
			}
			&.behandler {
				background: #17a2b8;
				color: #ffffff;
				opacity: 1;
			}
		}
	}
	&.page-print,
    &.sales-order-shipment,
    &.sales-order-invoice,
    &.sales-order-view {
        .column.main {
            .page-title {
                margin-top: 0;
                margin-bottom: 1.5rem;
                &-wrapper {
                    flex-direction: column;
                    margin-bottom: 1rem;
                    .order-actions-toolbar {
						.action.order {
							display: none;
						}
                    }
                    .order-date {
                        margin-bottom: 0;
                    }
                }
            }
            .table-wrapper {
                margin: 0;
                border: none;
            }
            .order {
                &-title {
                    display: none;
                }
                &-links {
                    margin-bottom: 0;
                    border: none;
                    .item {
                        display: inline-block;
                    }
                    strong {
                        font-size: 1.4rem;
                    }
                }
                &-details-items {
                    border-bottom: none;
                    margin: 0;
                    padding: 0;
                    .actions-toolbar {
                        display: none;
                    }
                    strong {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                    .table-order-items {
                        tbody {
                            tr[id^="order-item-row"] {
                                td.col {
                                    &.name {
                                        padding-left: 100px;
                                        position: relative;
                                        padding-bottom: 20px;
                                        min-height: 100px;
                                        display: block;
                                        img {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                        }
                                    }
                                    &.price,
                                    &.qty,
                                    &.subtotal {
                                        span {
                                            font-size: 1rem;
                                            font-weight: 600;
                                            &.summary {
                                                background: transparent;
                                            }
                                        }
                                    }
                                    &.subtotal {
                                        padding: 11px 0 1rem 0;
                                    }
                                }
                            }
                        }
                        tfoot {
                            tr {
                                &.subtotal {
                                    border-top: 1px solid #d1d1d1;
                                    margin-top: 0.5rem;
                                    padding-top: 0.5rem;
                                }
                                th, td {
                                    font-size: 1rem;
                                }
                                th {
                                    padding: 5px 10px 0 0;
                                }
                                td {
                                    padding: 5px 0 0 10px;
                                }
                                &:first-of-type {
                                    th, td {
                                        padding-top: 1reM;
                                    }
                                }
                                &:last-of-type {
                                    th, td {
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }
                        .product-item {
                            &-name {
                                margin-bottom: 0.5rem;
                            }
                        }
                        dl.item-options {
                            margin: 0;
                            display: flex;
                            flex-wrap: wrap;
                            dt {
                                width: 30%;
                            }
                            dd {
                                width: 70%;
                                margin: 0 0 4px;
                                &:last-of-type {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
            // order details
            .block-order-details-view {
                .block-title {
                    border-color: #d1d1d1;
                    strong {
                        font-size: 1.4rem;
                        font-weight: 700;
                    }
                }
                .block-content {
                    display: flex;
                    flex-wrap: wrap;
                    .box {
                        width: 25%;
                    }
                    address {
                        line-height: 1.5;
                    }
                    .box-order {
                        &-shipping {
                            &-method {
                                order: 3;
                            }
                            &-address {
                                order: 1;
                            }
                        }
                        &-billing {
                            &-method {
                                word-break: break-all;
                                order: 4;
                                img {
                                    max-width: 100px;
                                }
                                th, td {
                                    padding: 5px 0 0 0;
                                }
                            }
                            &-address {
                                order: 2;
                            }
                        }
                    }
                }
            }
        }
    }
	&.sales-order-shipment,
	&.sales-order-invoice {
		.column.main {
			.order-details-items {
				.table-order-items {
					tbody {
						tr[id^="order-item-row"] {
							td.col {
								&.name {
									padding-left: 10px;
									position: relative;
									padding-bottom: 20px;
									min-height: 100px;
									display: block;
									dl.item-options {
										margin: 0;
										display: flex;
										flex-wrap: wrap;
										dt {
											width: 30%;
										}
										dd {
											width: 70%;
											margin: 0 0 4px;
											&:last-of-type {
												margin: 0;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.page-print {
		.logo {
			max-width: 100px;
		}
		.column.main {
			.order-details-items {
				.table-order-items {
					thead {
						.col {
							&.name {
								display: table-cell !important;
								padding-left: 10px !important;
								width: 40% !important;
							}
						}
					}
					tbody {
						.col {
							&.name {
								display: table-cell !important;
								padding-left: 10px !important;
								width: 40% !important;
								.product-item-name {
									display: block;
									width: 100%;
									margin-right: 100px;
								}
								img {
									position: initial !important;
									float:left;
								}
							}
							&.sku {
								width: 20%;
							}
							&.price {
								width: 10%;
							}
							&.qty {
								width: 10%;
							}
							&.subtotal {
								width: 10%;
							}
						}
					}
				}
			}
		}
	}
}
@include max-screen($screen__m) {
    body.account {
        .column.main {
            padding: 0 1rem;
        }
        // order page
        &.sales-order-view {
            .column.main {
                .page-title-wrapper {
                    margin-top: 1rem;
					margin-bottom: 0;
                    .page-title {
                        margin-bottom: 0.5rem;
                    }
                    .order-date {
                        margin: 0.5rem 0;
                    }
                    .order-status {
                        position: initial;
						text-align: center;
						order: 10;
                    }
					.order-actions-toolbar {
						display: none;
					}
                }
                .order {
					&-links {
						&:not(.current) {
							display: none;
						}
					}
                    &-details-items {
                        #my-orders-table {
                            tbody {
                                tr[id^="order-item-row"] {
                                    td.col {
										&.name {
											&:before {
												display: none;
											}
										}
                                        &.price,
                                        &.qty,
                                        &.subtotal {
                                            display: flex;
                                            justify-content: space-between;
                                            padding: 5px 0 0 0;
                                            span {
                                                font-size: 1rem;
                                                font-weight: 600;
                                            }
                                        }
                                        &.subtotal {
                                            padding-bottom: 1rem;
                                        }
                                    }
                                }
                            }
                            tfoot {
                                tr {
                                    &.subtotal {
                                        border-top: 1px solid #d1d1d1;
                                        margin-top: 0.5rem;
                                        padding-top: 0.5rem;
                                    }
                                    th, td {
                                        font-size: 1.2rem;
                                    }
                                    th {
                                        padding: 5px 10px 0 0;
                                    }
                                    td {
                                        padding: 5px 0 0 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                // order details
                .block-order-details-view {
                    .block-content {
                        display: flex;
                        flex-wrap: wrap;
                        .box {
                            width: 50%
                        }
                        .box-order {
                            &-shipping {
                                &-method {
                                    order: 3;
                                }
                                &-address {
                                    order: 1;
                                }
                            }
                            &-billing {
                                &-method {
                                    order: 4;
                                    th, td {
                                        padding: 5px 0 0 0;
                                    }
                                }
                                &-address {
                                    order: 2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
