body{
    .cartamount{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-inner{
            display: none;
            width: 100%;
            text-align: center;
            padding-top: 1rem;
            border-top: 1px solid darken($sparkjop_WhiteGrey, 8%);
            &.visible{
                display: block;
            }
        }
        .remaining-amount{
            font-size: 16px;
            color: $sparkjop_CodGrey;
            &.free-shipping{
                font-weight: 700;
                font-size: 16px;
            }
        }

        // Progress Bar
        progress[value]{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            height: 15px;
            color: #40c353;
            position: relative;
            margin: 10px 0;
            padding: 0;
            width: 85%;
            border-radius: 7px;
        }

        /* IE11 FIX */
        @media all and (-ms-high-contrast:none){
             *::-ms-backdrop, progress[value] { padding: 0 0; } /* IE11 */
        }

        progress[value]::-webkit-progress-bar {
            background-color: #ebebeb;
            border-radius: 50px;
        }

        progress[value]::-moz-progress-bar {
            background-image: -webkit-linear-gradient( left, #40c353, #40c353);
            border-radius: 50px;
        }

        .amount-percent::-webkit-progress-value {
            background-image: -webkit-linear-gradient( left, #40c353, #40c353);
            border-radius: 50px;
        }

        .progress-bar-default {
            background-color: #ebebeb;;
            border-radius: 4px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
            width: 85%;
            height: 15px;
            position: relative;
            display: block;
            > span {
                background-color: #40c353;
                border-radius: 4px;
                display: block;
                text-indent: -9999px;
            }
        }


    }
}

