.polarcore.cms-home,
[class*='wpcloud'].polarcore {
	.columns .column.main {
		padding-bottom: 0;
	}
	.page-main {
		margin-bottom: 0;
	}
	.page-title-wrapper {
		display: none;
	}
	.home-quick-cat {
		padding: 2rem 0;
		&.wpcmdl-grid--width-full {
			padding: 5rem calc((100% - 1440px) / 2) !important;
		}
		.wpb_text_column ~.wpcmdl-grid {
			padding: 0;
			& > .wpcmdl-cell:first-child {
				margin-left: 0;
			}
			& > .wpcmdl-cell:last-child {
				margin-right: 0;
			}
		}
	}
	.wpcmdl-grid {
		padding: 8px;
	}
	.wpcloud-card {
		margin: 0.5rem 1rem;
		.content {
			padding: 1rem;
			background-color: $color__white;
			box-shadow: inset 0 0 0 7px $color__white, inset 0 0 0 8px $color__accent;
			margin-bottom: 1.5rem;
			display: flex;
			flex-direction: column;
			text-align: center;
			color: $color__base;
			.mdl-card__title > * {
				margin: 0;
			}
			.mdl-card__supporting-text {
				font-size: 3em;
				font-weight: bold;
			}
		}
		.mdl-card__actions {
			position: relative;
			button {
				@include sparkjop-button();
				width: 100%;
			}
		}
	}
	.popular-categories {
		.wpb_single_image {
			.vc_box_circle {
				img {
					width: 8rem;
					height: 8rem
				}
			}
		}
		.wpb_text_column {
			a,
			strong {
				color: $color__base;
				font-weight: normal;
				font-size: 1.1em;
			}
			p {
				margin-top: 2rem;
			}
		}
	}

    .butikker {
        padding: 1rem;
        .wpcloud-post-title-title {
            margin-bottom: 3rem;
        }
        .store-wrap {
            margin-bottom: 1.5rem;

            &:after {
                content: '';
                display: table;
                clear: both;
            }

            > div {
                display: inline-block;
                vertical-align: bottom;

                &:first-child {
                    padding-right: 1.5rem;
                    min-width: 320px;
                    vertical-align: top;
                    b {
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                    }
                }
            }

            ul {
                list-style-type: none;
                padding-left: 0;
            }
        }
    }
}
