#ajax-top-filters {
    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }

    .filter-options-title {
        position: relative;
        &.loading {
            @keyframes spinner {
                to {transform: rotate(360deg);}
            }
            &:before {
                content: '';
                position: absolute;
                opacity: 1;
                z-index: 999999999;
                top: 50%;
                left: 10%;
                width: 14px;
                height: 14px;
                margin-top: -7px;
                margin-left: -7px;
                border-radius: 50%;
                border: 1px solid #ccc;
                border-top-color: $color__action;
                animation: spinner 1s linear infinite;
            }
        }
    }
}

#ajax-stock-filter {
    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }
}

#ajax-product-list {
    position: relative;
    min-height: 50vh;
    &.loading {
        opacity: 0.4;
        @keyframes spinner {
            to {transform: rotate(360deg);}
        }
        &:before {
            content: '';
            position: absolute;
            opacity: 1;
            z-index: 999999999;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            margin-top: -25px;
            margin-left: -25px;
            border-radius: 50%;
            border: 2px solid #ccc;
            border-top-color: $color__action;
            animation: spinner 1s linear infinite;
        }
    }
}
