body[class*="customer"],
body[class*="customer-account"] {
    // general
    .legend {
		width: 100%;
		margin-top: 16px;
		margin-bottom: 25px;
	}
    .field,
    .fieldset {
        &.job_title,
        &.company,
        &.region {
            display: none;
        }
        &.country {
            display: none !important;
        }
    }
    #password-strength-meter-container {
        .password-strength-meter {
            background: transparent;
            padding: 0;
            margin-top: 0.5rem;
        }
        #password-strength-meter-label {
            font-weight: 900;
        }
        &.password {
            &-weak {
                #password-strength-meter-label {
                    color: #e02b27;
                }
            }
            &-medium {
                #password-strength-meter-label {
                    color: #72afe4;
                }
            }
            &-strong,
            &-very-strong {
                #password-strength-meter-label {
                    color: #8ed0bc;
                }
            }
        }
    }
    .control {
        position: relative;
        display: flex;
    }
    #password-error {
        width: 100%;
        position: relative;
        height: initial;
        top: 0;
    }
    .column:not(.sidebar-additional) form .actions-toolbar,
    .column:not(.sidebar-main) form .actions-toolbar {
        margin: 0;
    }
    p {
        font-size: 16px;
    }
}
body[class*="customer"],
body[class*="customer-account"],
body[class*="account"] {
    .block-collapsible-nav .item.current a,
    .block-collapsible-nav .item.current > strong {
        border-color: $sparkjop_DarkBlue;
    }
    @include max-screen($screen__m) {
        .block-collapsible-nav {
            position: static;
            margin-bottom: 1rem;
            .title {
                border-top: 0;
                background: $sparkjop_WhiteGrey;
            }
        }
        .page.messages,
        .messages {
            margin-bottom: 0.25rem;
        }
    }
}
body[class*="customer"],
body[class*="customer-account"] {
    @include max-screen($screen__m) {
        .legend {
            margin-bottom: 2rem;
        }
        .form.create.account {
            .fieldset {
                width: 100%;
                padding: 0;
            }
            .actions-toolbar {
                width: 100%;
                margin: 0;
            }
            #password-error {
                position: initial;
                width: 100%;
            }
        }
    }
}
body[class*="customer-account-edit"] {
    .field.password-info p {
        display: none;
    }
}
.polarcore.customer-account-login {

    // create account page
	.form.create.account {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		.fieldset {
			width: 50%;
			padding-right: 4rem;
		}
		.actions-toolbar {
			width: 100%;
			margin-left: 65%;
			margin-right: 4rem;
		}
	}

	.actions-toolbar {
		margin: 0;
	}

	.login-container .fieldset:after {
		display: none;
	}

	.block {
		&-new-customer {
			.actions-toolbar {
				margin-top: 2rem;
			}
			a.action.create.primary {
                @include sparkjop-button();
			}
		}
	}

    //vipps button styles
    .login-vipps {
        margin: 2rem 0;
        .vipps-btn {
            display: flex;
            background-color: $color__vipps;
            border-radius: 100px;
            border: none;
            color: $color__white;
            text-transform: none;
            padding: 15px 25px;

            &:hover,
            &:focus,
            &:active {
                background: lighten($color__vipps, 10%);
                border: 0;
                color: $color__white;
            }
            .icon-vipps {
                background: url("../icons/vipps/vipps-white-logo.png") left center no-repeat;
                display: inline-block;
                background-size: 55px 14px;
                padding-left: 62px;
                height: 15px;
                margin-left: 10px;
            }
        }
    }
}

// newsletter page
.newsletter-manage-index {
    .form-newsletter-manage {
        width: 50%;
        margin-right: auto;
        .fieldset {
            margin: 1rem 0 0 0;
            > .field {
                &:before {
                    display: none;
                }
                span {
                    font-weight: 600;
                }
            }
        }
    }
    .column:not(.sidebar-additional) form .actions-toolbar,
    .column:not(.sidebar-main) form .actions-toolbar {
        margin: 0;
    }
    .legend {
        width: 100%;
        display: none;
    }
}

// Addresses
.form-address-edit {
    .fieldset .street .control .nested .field.additional .control {
        .label {
            clip: inherit;
            height: auto;
            width: auto;
            overflow: visible;
            padding: 0 5px;
            margin: inherit;
        }
    }

    .fieldset .field:not(.choice) {
        margin-bottom: 2rem;
    }

    .nested {
        margin: 15px 0;
    }

    #bring-address-validation-messages .sparkjop-alert {
        margin-bottom: 30px;
    }

    .fieldset .field.telephone {
        @include telephone(12px);
    }
}

.customer-address-form {
    .message.info {
        display: none;
    }
}

// Customer notificattion
.wrapperOutside {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999999;
}
.dialogContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 50%;
  max-width: 500px;
  height: auto;
  box-shadow: 0 -2px 25px 0 rgba(0, 0, 0, 0.15), 0 13px 25px 0 rgba(0, 0, 0, 0.3);
  background-color: #FFFFFF;
}
.dialogContent {
  width: auto;
  height: auto;
  background-color: #FFFFFF;
  &-Title {
      font-weight: 500;
      font-size: 20px;
      color: rgba(0,0,0,.8);
      line-height: 24px;
      text-align: left;
      letter-spacing: 0.03px;
      margin-bottom: 1rem;
      font-weight: 600;
  }
  &-Body {
      font-weight: 400;
      font-size: 16px;
      color: rgba(0,0,0,.5);
      line-height: 24px;
      text-align: left;
      letter-spacing: 0.03px;
  }
}
.dialogActions {
margin-top: 1rem;
  width: auto;
  height: 48px;
  background-color: #FFFFFF;
  text-align: right;
}
//My Page Wishlist
.wishlist-index-index {
    // Prevent product styling in wishlist from affecting Minicart
    .form-wishlist-items {
        .product-items {
            padding-left: 0;
        }

        .product-item {
            margin: 0 0.5rem 1rem;
            padding-bottom: 1rem;
            border: none !important;
            width: calc((100% / 3) - 1rem);

            .product-item-info {
                width: 100%;
                padding: 1rem;
            }

            .product-item-name {
                font-size: 16px;
                margin: 0;
            }

            .price-box {
                &.price-final_price {
                    margin: 0;

                    span {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }

            .product-item-inner {
                .comment-box {
                    margin-bottom: 0.5rem;

                    textarea {
                        border-radius: 5px;
                    }
                }

                .product-item-actions {
                    display: flex;
                    justify-content: space-evenly;
                }

                .box-tocart {
                    width: 100%;

                    .field.qty {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        padding-right: 0;

                        .control {
                            width: 60px;
                        }

                        label {
                            position: relative;
                            margin-top: -30px;
                            height: 20px;
                        }
                    }

                    .product-item-actions {
                        justify-content: center;

                        .actions-primary {
                            margin-top: 0.5rem;
                            display: flex;
                            justify-content: center;
                        }
                    }

                }
            }
        }

        .actions-toolbar .primary {
            button {
                @include sparkjop-button();
            }
        }
    }
}

@include max-screen($screen__m){
    .wrapperOutside {
        top: 20%;
    }
    .dialogContainer {
        width: 80%;
    }

    .products-grid.wishlist {
        .product-item-photo,
        .product-item .box-tocart {
            margin-right: 0;
            float: none;
        }

        .product-item {
            margin: 0 0 1rem;
            width: 100%;
        }
    }
}

//
//  Tablet Landscape + Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    body.account {
        .column.main {
            flex-basis: initial;
        }

        .sidebar.sidebar-main {
            flex-basis: initial;
            padding-right: 2%;
        }
    }

    .wishlist-index-index {
        .product-item {
            .product-item-inner {
                html:not(.isTouch) & {
                    width: 100%;
                    display: block;
                    max-height: 0;
                    opacity: 0;
                    border-radius: 5px;
                    overflow: hidden;
                    position: absolute;
                    top: 70%;
                    left: 0;
                    padding: 0 1rem 1rem;
                    transition: all 350ms ease, opacity 1ms ease 349ms, top 1ms ease 349ms;
                }
            }
            &:hover {
                z-index: 9;
                .product-item-inner {
                    html:not(.isTouch) & {
                        height: auto;
                        overflow: visible;
                        display: block;
                        max-height: 500px;
                        top: calc( 100% - 0.5rem );
                        background-color: $sparkjop_White;
                        opacity: 1;
                        transition: all 450ms ease, opacity 100ms;
                        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.2);
                    }
                }
            }
        }
    }
}

//
//  Tablet Landscape + Desktop
//  _____________________________________________
@include min-screen($screen__l) {
    .wishlist-index-index {
        .product-item {
            margin-right: 0;
        }
    }
}
