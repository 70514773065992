.checkout-cart-index {
    background: $sparkjop_WhiteGrey;
    .page-title-wrapper {
        visibility: hidden;
        h1 {
            margin: 0;
        }
    }
    .cart-title {
        display: block;
        margin-left: 1rem;
        font-size: 24px;
        color: $sparkjop_CodGrey;
        width: 100%;
        padding: 1rem;
        .count {
            margin: 0 10px;
            .value {
                margin-right: 5px;
            }
            &:before {
                content: '(';
            }
            &:after {
                content: ')';
            }
        }
    }
    .cart-empty{
        padding: 0.5rem 1rem;
    }
    .cart.table-wrapper .items thead + .item {
        border-top: none;
    }
    .cart.table-wrapper {
        background: $sparkjop_White;
        padding-top: 11px;
        thead {
            display: none;
        }
        .items {
            > .item {
                display: flex;
                flex-wrap: wrap;
                margin: 0 1.25rem;
                .item {
                    &-info {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 100%;

                        &.single-item {
                            .col.price > .price-label {
                                display: inline;
                            }

                            .price-total {
                                display: none;
                            }
                        }

                        .price-label {
                            color: lighten($text__color, 20%);
                            font-size: 18px;
                            line-height: 1;
                            padding-right: 10px;
                        }
                        .product-item {
                            &-name {
                                font-size: 16px;
                                font-weight: 700;
                            }
                        }
                        .item-options {
                            font-size: 16px;
                            color: $sparkjop_Grey;
                            dd,dt {
                                font-weight: 400;
                            }
                        }
                        .col {
                            &.item{
                                padding: 2rem 0.5rem 0rem;
                                min-height: unset;
                            }
                            &.price.qty {
                                display: flex;
                                flex-wrap: wrap;
                                width: 20%;
                            }

                            &.qty {
                                width: auto;
                                padding-top: 0px;
                                position: absolute;
                                right: 0;
                                top: 2rem;
                            }
                            &.price {
                                width: auto;
                                margin-top: 6rem;
                                text-align: right;

                                > .price-label {
                                    display: none;
                                }

                                .price-including-tax{
                                    display: inline-flex;
                                    align-items: center;
                                    flex-direction: column;
                                    .label{
                                        font-size: 16px;
                                        margin-bottom: 0.5rem;
                                        color: $sparkjop_Grey;
                                    }
                                }
                                .old-price {
                                    color: $sparkjop_old-price__color;
                                    font-size: 18px;
                                    line-height: 1;
                                    margin-right: 0.5rem;
                                    .price{
                                        font-weight: 400;
                                    }
                                }
                                .special-price {
                                    .price {
                                        color: $sparkjop_special-price__color;
                                    }
                                }
                            }
                            &.subtotal {
                                display: flex;
                                flex-wrap: wrap;

                                width: 100%;
                                padding: 0;
                                text-align: left;
                                margin-left: auto;
                                margin-bottom: 1.5rem;
                                .actions-toolbar{
                                    margin: 0;
                                    padding: 0 0 0 118px;
                                    width: 100%;
                                }

                                .price-label {
                                    flex-grow: 1;
                                    text-align: right;
                                }

                                .price-including-tax {
                                    color: black;
                                    flex-shrink: 1;
                                    text-align: right;
                                    padding-right: 10px;
                                }

                                .action.action-edit {
                                    display: none;
                                }
                            }

                            .action-delete {
                                border: initial;
                                background: initial;
                                padding: 0;
                                display: inline-flex;
                                color: $sparkjop_Grey;
                                align-items: center;
                                transition: color 250ms ease;
                                span{
                                    font-size: 16px;
                                    font-weight: 400;
                                }
                                &:before {
                                    content: "\E872";
                                    font-family: 'Material icons';
                                    font-size: 1.5rem;
                                    top: 0;
                                    margin-right: 0.25rem;
                                    position: relative;
                                }
                                &:hover{
                                    color: darken($sparkjop_Grey, 8%);
                                }
                            }

                        }
                    }
                    &-actions {
                        width: 100%;
                        padding: 0 0 20px 115px;
                        margin: 0;
                        td {
                            padding: 0;
                        }
                        .actions-toolbar {
                            padding: 0;
                            .action {
                                &-delete {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }
        .item-actions {
            .actions-toolbar {
                .action {
                    &-towishlist {
                        display: none;
                    }
                    &-delete {
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        background: transparent;
                        border: none;
                        font-weight: 400;
                        &:before {
                            content: "";
                            display: block;
                            height: 20px;
                            width: 20px;
                            background-size: 20px 20px;
                            margin-right: 2px;
                            background-image: url(../icons/trashcan_cart.svg);
                            background-repeat: no-repeat;
                        }
                    }
                    &-edit {
                        display: none;
                    }
                }
            }
        }
    }
    .cart.main.actions {
        .action.update {
            @include sparkjop-button-black();
            box-shadow: none;
            padding: 10px;
        }
    }
    .cart-summary {
        background: $sparkjop_White;
        padding: 0 1rem 1rem 1rem;
        .block .title > strong {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
        }
        .field.note {
            display: none;
        }
        .fieldset.estimate {
            legend {
                display: none;
            }
            .field {
                margin: 20px 0 10px 0;
                &:first-of-type {
                    margin: 5px 0 10px 0;
                }
                &[name="shippingAddress.region"] {
                    display: none !important;
                }
            }
        }
        table {
            margin: 0;
        }
        .summary.title {
            background: transparent;
            font-size: 24px;
            font-weight: 600;
            padding: 0;
        }
        #block-shipping {
            margin: 0;
        }
        #block-shipping-heading {
            margin: 0;
            padding-top: 5px;
        }
        #block-discount {
            margin-bottom: 0;
            #discount-coupon-form {
                .action.apply.primary {
                    padding: 8px;
                }
            }
        }
        #block-discount-heading {
            font-size: 14px;
            font-weight: 900;
            padding: 0 0 1rem;
        }
        .cart-totals {
            .totals {
                font-size: 16px;
                th,td {
                    padding: 4px 0;
                }
                &.shipping {
                    span.value {
                        display: none;
                    }
                }
                &-tax {
                    th, td {
                        padding-bottom: 10px;
                    }
                }
            }
        }
        .checkout-actions {
            .checkout-methods-items {
                .login-btns-container {
                    .login-before-checkout-info {
                        color: $sparkjop_DarkBlue;
                        font-size: 1.15rem;
                    }
                    .vipps-btn,
                    .ajax-login-btn {
                        line-height: 14px;
                    }
                }
            }
        }
        .action.primary.checkout {
            @include sparkjop-button-cta;
            line-height: 22px;
            font-size: 18px;
            width: 100%;
        }

        .login-vipps {
            margin: 1rem 0;
            .action {
                .icon-vipps {
                    background: url("../icons/vipps/vipps-blue-logo.svg") center no-repeat;
                    display: inline;
                    background-size: 85px 40px;
                    padding-left: 70px;
                    height: 25px;
                }

                &:hover,
                &:active {
                    .icon-vipps {
                        background-image: url("../icons/vipps/vipps-white-logo.svg");
                    }
                }
            }
        }

        .checkout-usp {
            display: flex;
            align-items: center;
            padding: 20px 2px 10px;
            img {
                margin-right: 4px;
            }
        }
    }

    // Mobile
    @include max-screen($screen__m + 1px) {
        .page-title-wrapper {
            display: none;
        }
        #maincontent {
            padding: 0 !important;
        }
        .cart-container{
            .form.form-cart{
                width: 100%;
            }
            .cart-summary{
                width: 100%;
                .cart.table-wrapper{
                    padding-top: 0;
                }
            }
        }
        .cart {
            &-title {
                font-size: 20px;
                margin: 0;
            }
            &-container {
                display: flex;
                flex-direction: column-reverse;
            }
            table {
                tr, td, div.col {
                    &:before {
                        display: none !important;
                    }
                }
            }
            &.table-wrapper {
                // products
                .items {
                    > .item {
                        .item {
                            &-info {
                                border: none;
                                margin-top: 1rem;
                                .col {
                                    &.item {
                                        border: none;
                                        width: 100%;
                                        padding: 5px 0 0px 75px;
                                        width: 85%;
                                        order: 1;
                                    }
                                    &.price.qty {
                                        width: 100%;
                                        padding: 0 0 0 36px;
                                    }
                                    &.qty {
                                        padding: 0 0 0 74px;
                                        order: 2;
                                        position: static;
                                        margin-top: 1rem;
                                    }
                                    &.price, &.subtotal {
                                        width: fit-content;
                                        padding: 10px 0 0 0;
                                    }
                                    &.price {
                                        padding-left: 75px;
                                        order: 3;
                                        margin-top: 1rem;
                                        margin-bottom: 1.5rem;
                                        width: 100%;
                                        .price-including-tax{
                                            align-items: flex-start;
                                            .label{
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                    &.subtotal {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: inherit;
                                        align-items: center;
                                        width: auto;
                                        position: absolute;
                                        right: 0;
                                        padding: 0;
                                        margin-top: 0.5rem;

                                        a.action.action-delete {
                                            order: 1;
                                            max-width: 25px;
                                            margin-left: 140px;
                                            span {
                                                display: none;
                                            }
                                        }
                                        a.towishlist {
                                            margin-top: 2rem;
                                            right: 0;
                                            position: absolute;
                                            order: 2;
                                            max-width: 100px;
                                            white-space: normal;
                                            padding: 5px;
                                            text-align: center;
                                        }
                                    }
                                }
                                .product {
                                    &-item {
                                        &-photo {
                                            top: 5px;
                                        }
                                        &-name {
                                            font-size: 14px;
                                        }
                                        &-details {
                                            text-align: left;
                                            .product-item-name {
                                                font-size: 14px;
                                            }
                                            .item-options {
                                                font-size: 14px;
                                                margin-top: 4px;
                                                margin-bottom: 0;
                                                dt, dd {
                                                    margin: 0 0 2px;
                                                }
                                                dd {
                                                    margin-left: 6px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &-actions {
                                border: none;
                                .actions-toolbar {
                                    .action {
                                        &-delete {
                                            position: absolute;
                                            top: 15px;
                                            right: 0;
                                            span {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-summary {
                .cart-totals {
                    .totals {
                        tr {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            padding: 0px 0px;
                            margin: 0;
                            border: 0;
                            th, td {
                                border: none;
                            }
                        }
                        .totals-tax {
                            border-bottom: 1px solid #c8c8c8;
                        }
                    }
                }
            }
        }
        .cart-summary {
            .checkout-actions {
                .checkout-methods-items {
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    padding: 10px 16px 16px 16px;
                    left: 0;
                    z-index: 9;
                    background: $sparkjop_White;
                    margin: 0;
                    .login-btns-container {
                        margin-bottom: 3rem;
                    }
                }
                tr.grand.totals {
                    position: fixed;
                    bottom: 4.5rem;
                    left: 0;
                    padding: 0 16px;
                    width: 100%;
                    display: flex;
                    z-index: 10;
                    background: $sparkjop_White;
                }
            }
            &.sticky {
                .checkout-actions {
                    .checkout-methods-items {
                        position: sticky;
                        bottom: 0;
                        width: 100%;
                        left: 1rem;
                        z-index: 9;
                        margin: 0;
                        padding: 10px 0 16px 0;
                        .item {
                            .login-btns-container{
                                margin-bottom: 0;
                                .vipps-btn,
                                .ajax-login-btn {
                                    font-size: 16px;
                                    padding: 0.85rem;
                                }
                            }
                        }
                    }
                    tr.grand.totals {
                        position: sticky;
                        bottom: 4.5rem;
                        left: 0rem;
                        padding: 0;
                        width: 100%;
                        display: flex;
                        z-index: 8;
                    }
                }
            }
        }
    }

    // Tablet
    @include screen($screen__m, $screen__m + 2px){
        .cart.table-wrapper{
            .items{
                > .item{
                    .item-info{
                        .col.qty,
                        .col.item,
                        .col.price{
                            padding-left: 0;
                        }
                        .col.qty{
                            margin-top: 3.5rem;
                        }
                    }
                }
            }
        }
    }

    // Desktop
    @include min-screen($screen__m) {
        .cart-container .form-cart {
            width: 70%;
            padding-right: 0;
        }
        .cart-summary {
            width: 29%;
        }
    }
}
