input[type="radio"]:checked {
    background: #2196f3;
}
input[type="radio"] {
    background: #ffffff;
    border: 2px solid #3b3f49;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

div.mage-error {
    color: $sparkjop_Red2;
    font-size: 0.86rem;
}

.fieldset .field:not(.choice) input.mage-error {
    border-color: $sparkjop_Red2;

    ~ label {
        color: $sparkjop_Red2;
    }
}

.footer-logo {

    .nav-toggle {
        display: none !important;
    }

    .logo {
        display: block;
        margin: 2rem 0;
    }
}

/**
*
* Newsletter block/componenet
*
*/

.block .field.newsletter div.control:before {
    line-height: 44px;
}

#newsletter {
    height: 44px;
}

/*
 see Snowdog/blank/styles/mixins/_media-queries.scss
 screen range for tablets/halfscreen
*/
@include screen($screen__m, ($layout__max-width)) {

    .page-wrapper .breadcrumbs,
    .page-header .panel.wrapper .header.panel {
        padding-left: 1rem;
        padding-right: 1rem;
    }

}

$polarcore-header-breakpoint__offset: 200px !default;

@include screen($screen__m, ($layout__max-width - $polarcore-header-breakpoint__offset)) {

    .block.block-search {
        order: 100;
        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 0;
        max-height: 40px;
        height: 40px;
        #search {
            height: 40px;
        }
    }

}


@include screen(440px, 639px) {

    .products-grid {
        .product-item.item.product {
            width: 50%;
        }
    }

}
