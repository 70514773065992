// Desktop + Tablet
@include min-screen($screen__m) {
    .catalog-product-view {
        .MagicToolboxSelectorsContainer {
            .MagicScroll > .mcs-button-arrow {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
                border: 1px solid $color_black;
                background: $color_white;

                &:before {
                    color: $color_black;
                    border-color: $color_black;
                    width: 18px;
                    height: 18px;
                }
            }

            .mcs-item {
                margin-top: 5px;
            }
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    .product.media .MagicToolboxContainer {
        .MagicToolboxSelectorsContainer.MagicToolboxSelectorsContainer {
            display: none !important;
            position: static;
            .MagicScroll {
                padding: 0 2rem;
            }
            .mcs-wrapper {
                position: static;
                .mcs-item {
                    img {
                        max-height: 75px;
                    }
                }
            }
        }
        .MagicToolboxMainContainer {
            position: relative;
            .mcs-arrow {
                display: flex;
                position: absolute;
                top: 50%;
                z-index: 10;
                border-radius: 50%;
                margin: -1.5rem 0 0;
                padding: 0;
                background-color: $color__white;
                i {
                    color: $color__black;
                    padding: 0.5rem;
                }
                &.mcs-next {
                    right: 0;
                }
                &.mcs-prev {
                    left: 0;
                }
            }
            .gallery-counter {
                display: flex;
                position: absolute;
                z-index: 10;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                padding: 0.75rem;
                font-size: 16px;
                font-weight: 700;
                color: $sparkjop_DarkBlue;
            }
        }
    }
}
