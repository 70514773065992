.catalog-product-view {
    .page-main {
        margin-bottom: 0;

        .column.main {
            padding-bottom: 0;
        }
    }
    .usp-list {
        display: none;
    }
    .shipping-info {
        text-align: left;
        font-weight: 700;
        display: flex;
        width: 50%;
        float: left;
        padding-top: 25px;
        .truck-icon {
            background: url('../icons/Lastebil.jpg');
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
            height: 22px;
            width: 40px;
            margin-right: 10px;
        }
        span {
            line-height: 30px;
        }
    }
    .product-badge {
        &-10 {
            background-image: url('../images/productlabelling/oekotex.png');
        }

        &-20 {
            background-image: url('../images/productlabelling/swanbadge.png');
        }

        &-30 {
            background-image: url('../images/productlabelling/organic-cotton.png');
        }

        &-40 {
            background-image: url('../images/productlabelling/100wool.png');
        }

        &-50 {
            background-image: url('../images/productlabelling/blendedwool.png');
        }

        &-60 {
            background-image: url('../images/productlabelling/100cotton.png');
        }

        &-70 {
            background-image: url('../images/productlabelling/leather.png');
        }

        &-80 {
            background-image: url('../images/productlabelling/bpafree.png');
        }

        &-90 {
            background-image: url('../images/productlabelling/sanatized.png');
        }
    }

    .columns .column .product.media .product-badges {
        height: auto;
        float: right;
        padding-top: 1rem;
        display: flex;
        justify-content: flex-end;
        .product-badge {
            width: 65px;
            height: 45px;
            margin-left: 0.5rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            content: '';
        }
    }
}

.product-bottom {
    .sparkjop-content-bottom__left {
        .product.info.detailed {
            .data.item.content {
                .inner {
                    .properties-section {
                        .properties-logos {
                            display: flex;
                            margin-bottom: 10px;
                            .property-logo-dish-washer {
                                background-image: url('../images/safetylabels/washingmachine.png');
                            }
                            .property-logo-gas-oven-safe {
                                background-image: url('../images/safetylabels/gas.png');
                            }
                            .property-logo-induction {
                                background-image: url('../images/safetylabels/induction.png');
                            }
                            .property {
                                height: 65px;
                                width: 60px;
                                margin-right: 10px;
                                background-position: center center;
                                content: '';
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    .attributes-section {
                        &.care-instructions {
                            .section-data {
                                .section-row {
                                    &:not(wash-separately),
                                    &:not(wash-inside-out),
                                    &:not(maintenance-tips) {
                                        display: inline-flex;
                                    }
                                    &.wash-separately,
                                    &.wash-inside-out,
                                    &.maintenance-tips {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        .section-caption {
                            font-weight: 700;
                            margin: 1rem 0 0.5rem;
                        }
                        .section-row {
                            &:not(wash-separately),
                            &:not(wash-inside-out),
                            &:not(maintenance-tips) {
                                display: flex;
                                flex-wrap: wrap;
                            }

                            .attribute-label {
                                &:after {
                                    content: ':';
                                    margin-right: 0.5rem;
                                }
                            }
                            &.cleaning-methods {
                                display: flex;
                                .cleaning-method {
                                    &-10 { //wash 90 degrees
                                        background-image: url('../images/washingsymbols/wash90degrees.png');
                                    }

                                    &-20 { //wash 60 degrees
                                        background-image: url('../images/washingsymbols/wash60degrees.png');
                                    }

                                    &-30 {
                                        background-image: url('../images/washingsymbols/wash40degrees.png');
                                    }

                                    &-40 {
                                        background-image: url('../images/washingsymbols/wash30degrees.png');
                                    }

                                    &-50 { //Finvask 40 grader
                                        background-image: url('../images/washingsymbols/finewash4.png');
                                    }

                                    &-60 { //Finvask 30 grader
                                        background-image: url('../images/washingsymbols/finewash30.png');
                                    }

                                    &-70 { //Ullvask 30 grader
                                        background-image: url('../images/washingsymbols/wool30.png');
                                    }

                                    &-80 { //Wool wash 40
                                        background-image: url('../images/washingsymbols/wool40.png');
                                    }

                                    &-90 { //Hand wash
                                        background-image: url('../images/washingsymbols/handwash.png');
                                    }

                                    &-100 { //Do not wash
                                        background-image: url('../images/washingsymbols/donotwash.png');
                                    }
                                }
                            }
                            &.dryer {
                                .dryer {
                                    display: flex;
                                    &-1 {
                                        background: url('../images/washingsymbols/dryerlowtemp.png');
                                    }

                                    &-2 {
                                        background: url('../images/washingsymbols/dryerhightemp.png');
                                    }

                                    &-3 {
                                        background: url('../images/washingsymbols/donotdryer.png');
                                    }
                                }
                            }
                            &.ironing {
                                .ironing {
                                    display: flex;

                                    &-1 {
                                        background: url('../images/washingsymbols/ironlowtemp.png');
                                    }

                                    &-2 {
                                        background: url('../images/washingsymbols/ironmediumtemp.png');
                                    }

                                    &-3 {
                                        background: url('../images/washingsymbols/ironghighttemp.png');
                                    }
                                }
                            }
                            &.air-drying {
                                .air-drying {
                                    &-1 {
                                        background: url('../images/washingsymbols/airdryflat.png');
                                    }

                                    &-10 {
                                        background: url('../images/washingsymbols/airdryhanging.png');
                                    }

                                    &-20 {
                                        background: url('../images/washingsymbols/airdryhangingshade.png');
                                    }

                                    &-30 {
                                        background: url('../images/washingsymbols/airdryflatshadow.png');
                                    }
                                }
                            }
                            &.dry-cleaning {
                                .dry-cleaning {
                                    display: flex;
                                    &-1 {
                                        background: url('../images/washingsymbols/regularcrycleaning.png');
                                    }

                                    &-10 {
                                        background: url('../images/washingsymbols/drycleanflourcarb.png');
                                    }

                                    &-20 {
                                        background: url('../images/washingsymbols/flourline.png');
                                    }

                                    &-30 {
                                        background: url('../images/washingsymbols/drycleandont.png');
                                    }
                                }
                            }
                            &.dry-cleaning .dry-cleaning-item,
                            &.airdrying .air-drying-item,
                            &.ironing .ironing-item,
                            &.dryer .dryer-item,
                            .cleaning-method-item {
                                height: 40px;
                                width: 45px;
                                margin-right: 10px;
                                background-position: center center;
                                content: '';
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                    //Beskrivelse tab
                    .product.attribute.description {
                        .labelling {
                            .labelling-item {
                                &.labelling {
                                    &-10 {
                                        background-image: url('../images/safetylabels/CE.png');
                                    }
                                    &-20 {
                                        background-image: url('../images/safetylabels/acid.png');
                                    }
                                    &-30 {
                                        background-image: url('../images/safetylabels/firehazard.png');
                                    }
                                    &-40 {
                                        background-image: url('../images/safetylabels/toxic.png');
                                    }
                                    &-50 {
                                        background-image: url('../images/safetylabels/utensilsproof.png');
                                    }
                                    &-60 {
                                        background-image: url('../images/safetylabels/microwaveproof.png');
                                    }
                                    &-70 {
                                        background-image: url('../images/safetylabels/dishwasherproof.png');
                                    }
                                    &-80 {
                                        background-image: url('../images/safetylabels/ovenproof.png');
                                    }
                                    &-90 {
                                        background-image: url('../images/safetylabels/candle.png');
                                        min-width: 200px;
                                        min-height: 80px;
                                    }
                                    &-100 {
                                        background-image: url('../images/safetylabels/healthhazard.png');
                                    }
                                    &-110 {
                                        background-image: url('../images/safetylabels/irritant.png');
                                    }
                                }
                                &.waste-management-10 { //spesialavfall
                                    background-image: url('../images/wastemanagement/specialwaste.png')
                                }
                                &.waste-management-20 {
                                    background-image: url('../images/wastemanagement/recycled-glass.png');
                                }
                                &.waste-management-30 {
                                    background-image: url('../images/wastemanagement/recycled-metal.png');
                                }
                                &.waste-management-40 {
                                    background-image: url('../images/wastemanagement/recycled-plastic.png');
                                }
                                &.waste-management-50 {
                                    background-image: url('../images/wastemanagement/recycled-paper.png');
                                }
                                &.waste-management-60 {
                                    background-image: url('../images/wastemanagement/recycled-paperboard.png');
                                }
                                &.waste-management-70 {
                                    background-image: url('../images/wastemanagement/batteries.png');
                                }
                                &.waste-management-80 {
                                    background-image: url('../images/wastemanagement/lightbulb.png');
                                }
                                &.waste-management-90 {
                                    background-image: url('../images/wastemanagement/flourescentlamp.png');
                                }
                                &.waste-management-100 {
                                    background-image: url('../images/wastemanagement/hazardous.png');
                                }
                                &.waste-management-110 {
                                    background-image: url('../images/wastemanagement/spraycan.png');
                                }
                                height: 45px;
                                min-width: 45px;
                                margin-right: 10px;
                                background-position: top left;
                                content: '';
                                background-repeat: no-repeat;
                                background-size: contain;
                                &:nth-last-child(n) {
                                    margin-bottom: 1rem;
                                }
                            }
                        }

                        .product-washing-symbols {
                            .washing-title {
                                font-weight: 700;
                                margin: 0.7rem 0 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
.product-extra-usp {
    width: 100%;
    ul {
        list-style-type: none;
        padding-left: 0.5rem;
        li {
            &:before {
                content: url('../icons/li-icon.png');
                height: 21px;
                width: 21px;
                margin: 0 0.5rem;
            }
        }
    }
}
.product.media {
    margin-bottom: 1rem;
    a[data-zoom-id] img, .mz-thumb img {
        box-shadow: none;
    }
    .tw-productlabels {
        top: auto;
        left: 130px;
        bottom: 20%;

        &__label--discount {
            .tw-productlabels__text {
                font-size: 20px;
                padding: 0.5rem 1.5rem;
                font-weight: 400;
            }
        }
    }
}
.page-title-wrapper {
    display: flex;
    flex-direction: column;
    .sku {
        order: 2;
        &:before {
            content: "Art";
        }
    }
    .page-title {
        order: 2;
    }
}

.vdc-brand-link {
    display: inline-block;
    float: right;
    width: 60px;
    margin-top: -1rem;
    // hide brand image if nothing is uploaded
    img[src=""] {
        display: none;
    }
}

.product-info-main {
    background: white;
    padding: 1rem 2rem 0;
    margin-bottom: 1rem;

    .page-title-wrapper {
        .page-title {
            font-size: 20px;
            font-weight: 600;
            font-family: $font-family__base;
            margin-bottom: 0.5rem;
            margin-top: 0;
        }
    }

    .product.attribute {
        .type,
        .sku {
            font-size: 11px;
            color: $color__black;
            font-weight: normal;
        }
        .type {
            display: none;
        }

        &.sku {
            .type {
                display: inline-block;
                font-size: 14px;
            }
        }
    }

    .product-info-manufacturer {
        font-size: 16px;
        font-weight: 100;
        margin-bottom: 1rem;
    }

    .product-info-price {
        .price-box {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            .special-price {
                margin: 0 0 0 5px;
                align-items: center;
                .price {
                    color: $sparkjop_special-price__color;
                    font-weight: 800;
                    font-size: 24px;
                }
            }
            .old-price {
                color: $sparkjop_old-price__color;
                .price {
                    font-size: 16px;
                    font-weight: 100;
                    margin-right: 8px;
                }
            }
            .regular-price {
                display: none !important;
            }
        }

        .price-rule-label {
            margin-top: 14px;
            font-weight: 100;
            font-size: 16px;
        }
    }
    .product.attribute.overview {
        margin: 10px 0;
        width: auto; // fallback
        width: fit-content;
        display: flex;
        align-items: center;

        .read-more {
            border-bottom: 1px solid #2c448e;
            color: #1a1a1a;
            margin-left: 10px;
            &:hover {
                text-decoration: none;
            }
            &:after {
                @include material-icon($icon-arrow-right-thin);
                font-size: 14px;
                position: relative;
                top: 3px;
            }
        }
    }
    .swatch-attribute {
        padding-bottom: 1rem;
        &.level_2{
            padding-bottom: 0.25rem;
        }
    }

    .sizeguide-text{
        font-size: 17px;
    }

    .product-options-bottom{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2rem;

        .usp-list {
            width: 100%;

            ul {
                display: flex;
                flex-direction: column;
                padding: 0;

                li {
                    list-style-type: none;
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: 8px;

                        &__box {
                            &:before {
                                content: url(../icons/box.svg);
                                width: 22px;
                                height: 22px;
                            }
                        }

                        &__delivery {
                            &:before {
                                content: url(../icons/delivery-truck.svg);
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }

        .box-tocart {
            margin: 0 0 0.5rem 0;

            .fieldset {
                margin-bottom: 0;
            }

            .field.qty {
                margin-bottom: 0;
                margin-right: 1rem;
            }

            .product-button-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-around;
                max-width: fit-content;
            }

            .actions {
                .action.primary.tocart {
                    margin: 0;
                    font-weight: 700;
                    padding: 0;

                    span {
                        height: 100%;
                    }

                    span:before {
                        display: none;
                    }
                }
            }

            .action.towishlist{
                position: relative;
                width: auto;
                height: auto;
                margin: 0;
                padding: 0;
                span{
                    display: none;
                }
                &:before{
                    background-image: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.01 97.37'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23fcba63;stroke-width:4px;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M62.33,29.81c9-21.4,39-25.23,49.31-3.5,13,27.5-24.16,61.85-49.32,78.43C37.16,88.16,0,53.81,13,26.31,23.28,4.58,53.36,8.41,62.33,29.81Z' transform='translate(-8.32 -9.77)'/%3E%3C/svg%3E");
                    display: block;
                    content: '';
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 56px;
                    height: 42px;
                    margin-left: 0.75rem;
                    transition: all 300ms ease;
                }
                &.already-in-wishlist{
                    &:before{
                        background-image: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.01 97.37'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fcba63;stroke:%23fcba63;stroke-width:4px;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M62.33,29.81c9-21.4,39-25.23,49.31-3.5,13,27.5-24.16,61.85-49.32,78.43C37.16,88.16,0,53.81,13,26.31,23.28,4.58,53.36,8.41,62.33,29.81Z' transform='translate(-8.32 -9.77)'/%3E%3C/svg%3E");
                    }
                }

                &:hover{
                    background: transparent;
                    &:before{
                        filter: brightness(97%);
                    }
                }

            }
        }
        .product-usp {
            margin: 0 0 20px 0;
            padding: 0;
            list-style: none;
            width: 100%;
            p {
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }
}

.product-info {
    &-price {
        .product-reviews-summary {
            display: none;
        }
        .price-label {
            display: none !important;
        }
    }
}
$products_container: $layout__max-width - 20px;
.product-bottom {
    clear: both;
    display: flex;
    background: $sparkjop_WhiteGrey;
    max-width: $layout__max-width;
    margin: 0 auto 1rem;
    .sparkjop-content-bottom__left,
    .sparkjop-content-bottom__right{
        background: $sparkjop_WhiteGrey;
        display: flex;
        padding: 1rem 0;
    }
    .sparkjop-content-bottom__left{
        width: 50%;
    }
    .sparkjop-content-bottom__right{
        width: 50%;
        .nosto-wrapper{
            width: 94%;
        }
    }
}
.product.info.detailed {
    width: 100%;
    margin-bottom: 0;
    .product.data.items {
        justify-content: flex-start;
        margin: 0 30px 0;
    }
    .data.item {
        &.title {
            order: -1;
            width: fit-content;
            border: none;
            height: fit-content;
            padding-bottom: 6px;
            margin: 1rem 1rem 0;
            &:first-child {
                padding-left: 1rem;
            }
            a {
                font-family: $font-family__headers;
                font-size: 18px;
                color: $color__black;
                padding: 0;
            }
            &:not(.disabled){
                &> .switch:focus,
                &>.switch:hover {
                    background: transparent;
                }
            }
            .switch {
                padding: 0;
                background: transparent;
                color: $color__black;
                &:after {
                    display: none;
                }
                &:visited {
                    color: $color__black;
                }
                .counter {
                    &:before {
                        content: '( ';
                        letter-spacing: -2px;
                    }
                    &:after {
                        content: ' )';
                        letter-spacing: -3px;
                    }
                }
            }
            &.active {
                .switch {
                    padding: 0;
                    color: $color__black;
                    border-bottom: 2px solid black;
                    &:after {
                        display: none;
                    }
                }
            }
        }
        &.content {
            border: none;
            background: inherit;
            padding: 1rem 1rem 1rem 2rem;
            font-size: 16px;
            border-radius: 5px;
            display: flex;
            &[aria-hidden="false"] {
                display: flex;
            }
            .inner {
                overflow-y: auto;
                width: 100%;
                table {
                    width: 100%;
                    > tbody > tr > th,
                    > tbody > tr > td {
                        padding: 11px 10px;
                    }
                }
                &.show-more {
                    max-height: 157px;
                    overflow: hidden;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        right: 0;
                        margin: 0;
                        height: 50px;
                        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
                    }
                }
            }
            #show-more-action {
                @include sparkjop-button();
                margin: auto;
                display: block;
            }
        }
    }
    // review
    #product-review-container {
        padding: 0;
    }
    .no-reviews {
        text-align: center;
        margin: 2rem 1rem 1rem 1rem;
        display: block;
    }
    // table
    table {
        width: 100%;
        tr {
            border-bottom: 1px solid #f3f3f3;
        }
        &.sizeguide-tabell {
            margin-bottom: 0;
            tr {
                &:first-of-type {
                    td {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.block.related {
    width: 50%;
    padding: 0.75rem 1rem;
    .block-title {
        margin-bottom: 11px;
        strong {
            font-family: $font-family__headers;
            font-size: 18px;
            color: #2c448e;
            padding: 0;
            margin: 4px 0 16px 0;
            font-weight: 400;
            display: block;
            width: 100%;
            text-align: center;
        }
    }
    .block-actions {
        display: none;
    }
    .products-grid {
        margin: 0;
        width: 100%;
        display: flex;
        .products {
            margin: 0;
            padding: 0;
            width: 100%;
            background: transparent;
        }
        .product {
            width: 100%;
            &-item {
                width: 33.333%;
                padding: 0;
                &:nth-of-type(4n) {
                    margin-right: 1rem;
                }
                &-info {
                    width: 100%;
                    box-shadow: 0px 1px 15px rgba(0,0,0,0.05);
                    border-radius: 5px;
                    padding: 14px;
                    background: $sparkjop_White;
                    .product-item-details {
                        flex-wrap: wrap;
                    }
                }
                &-actions {
                    .actions {
                        &-primary {
                            width: 100%;
                            button {
                                padding: 0 !important; // polarcore - fuck you - kittens are dying...
                                width: 100%;
                                display: flex;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                            a {
                                width: 100%;
                            }
                            span {
                                @include sparkjop-button-cta-white();
                                padding: 12px;
                                width: 100%;
                                &:before {
                                    display: none;
                                }
                            }
                        }
                        &-secondary {
                            display: none;
                        }
                    }
                }
                &-name {
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    padding: 0;
                    a {
                        $font-size: 14px;
                        $line-height: 1.3;
                        $lines-to-show: 2;
                        display: block;
                        display: -webkit-box;
                        height: $font-size*$line-height*$lines-to-show;
                        font-size: $font-size;
                        line-height: $line-height;
                        -webkit-line-clamp: $lines-to-show;
                        -webkit-box-orient: vertical; // moved this to template becouse sass don't compile it???
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                    }
                }
                .price-box {
                    width: 100%;
                    justify-content: center;
                    flex-direction: row-reverse;
                    align-items: center;
                    margin: 10px 0;
                    .price-container {
                        width: 100%;
                        text-align: center;
                    }
                    .price {
                        padding: 0;
                        font-size: 20px;
                        font-weight: 700;
                    }
                    .old-price {
                        position: initial;
                        margin-right: 10px;
                        color: $sparkjop_old-price__color;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        span {
                            height: 100%;
                        }
                        span.price {
                            font-weight: 400;
                            font-size: 20px;
                            display: flex;
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                            margin-top: 2px;
                        }
                    }
                    .special-price {
                        span {
                            color: $sparkjop_special-price__color;
                        }
                    }
                }
                .field.choice.related {
                    display: none;
                }
            }
        }
        .slick-track {
            padding: 4px;
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    body {
        &.catalog-product-view {
            .page-main {
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            .columns .column.main {
                padding-bottom: 0;
            }
            .page-footer {
                margin-top: 0;
            }
            .shipping-info {
                width: 100%;
                padding-left: 0;
                justify-content: center;
                margin-bottom: 1rem;
            }
            .columns .column .product.media .product-badges {
                justify-content: space-evenly;
                width: 100%;
                padding-top: 0;
            }
        }
    }
    .product.media {
        .tw-productlabels {
            width: 100%;
            top: 0;
            left: 0;
            &__label--discount {
                position: absolute;
                right: 0;
                .tw-productlabels__text {
                    font-size: 20px;
                    text-align: center;
                }
            }

            &__label--new {
                position: absolute;
                left: 0;
                padding: 0 0.5rem 0;
                font-size: 18px;
                .tw-productlabels__text {
                    padding: 14px 5px 0 3px;
                }
            }
        }
    }

    .vdc-brand-link {
        margin-top: 0;
    }

    .product-info-main {
        padding: 0.75rem;

        .usp-list {
            li {
                font-size: 12px;
            }
        }

        .product-options-bottom {
            flex-wrap: wrap;
            .box-tocart {
                margin: 0;
                width: 100%;
                .actions {
                    margin: 0;
                    .action.primary.tocart {
                        padding: 0.75rem;
                        font-size: 13px;
                    }
                    .action.towishlist {
                        margin: 0;
                        &:before{
                            margin-left: 0.25rem;
                        }
                    }
                }
            }
        }
    }

    .product-bottom {
        width: 100%;
        margin: 0;
        display: block;
        background: $sparkjop_White;
        .sparkjop-content-bottom__left{
            width: 100%;
        }
        .sparkjop-content-bottom__right{
            width: 100%;
            background: $sparkjop_White;
            padding-bottom: 0;
            margin: 0;
            .nosto-wrapper{
                width: 100%;
            }
        }
    }
    .product.info.detailed {
        width: 100%;
        margin-bottom: 0;
        .product.data.items {
            padding: 0 1rem;
            margin: 0;
        }
        .data.item {
            padding: 0;
            font-size: 24px;
            color: $color__black;
            a.switch {
                font-size: inherit;
            }
            &.content {
                padding: 0 1rem 1rem;
            }
            &.title {
                width: 100%;
                margin: 0;
                border-top: 1px solid $color__black;
                display: flex;
                justify-content: space-between;
                padding-left: 1rem;
                font-size: 18px;
                &:after {
                    @include material-icon($icon-up);
                    padding-top: 10px;
                }
                &:first-child {
                    border: none;
                }

                &.active {
                    a.switch {
                        color: $color__black;
                        border-bottom: none;
                    }
                    &:after {
                        transform: rotate(180deg);
                        padding-top: 10px;
                    }
                }
            }
        }
        #reviews {
            padding: 16px;
        }
        #product-review-container {
            #add-review {
                width: 100%;
                margin: 1rem 0;
            }
        }
    }
    .block.related {
        width: 100%;
        padding: 1rem;
        overflow: hidden;
        .products-grid {
            .product-item-actions {
                .actions-primary span {
                    padding: 8px;
                }
            }
        }
    }
}

// Desktop small
@include min-screen($screen__m) {
    .catalog-product-view {
        .product.media {
            .tw-productlabels {
                position: relative;
                text-align: center;
                bottom: 20%;
                width: 70%;
                width: 70%;
                left: 114px;
                &__label--discount {
                    position: absolute;
                    right: 0;
                    span.tw-productlabels__text {
                        width: 130px;
                        height: 90px;
                        background-color: transparent;
                    }
                }
                &__label--new {
                    position: absolute;
                    left: 0;
                    padding-top: 0.5rem;
                    span.tw-productlabels__text {
                        padding-top: 0.25rem;
                    }
                }
            }
        }
        .product-bottom {
            margin-top: 1rem;
        }
        .columns .column .product.media .product-badges {
            justify-content: center;
        }
    }
}

// Desktop
@include min-screen($screen__l) {

    .catalog-product-view {
        .page-main {
            margin-bottom: 0;
        }
        .product.media {
            .tw-productlabels {
                top: auto;
                left: 130px;
            }
        }
        .shipping-info {
            width: 100%;
            justify-content: center;
            padding-left: 0;
        }
        .product-badges {
            width: 100%;
            justify-content: center;
            padding-left: 0;
        }
        .product-extra-usp {
            width: 40%;
            float: right;
            padding-left: 1rem;
            ul {
                margin-left: 0;
                li{
                    display: flex;
                    align-items: center;
                }
            }
        }

    }
}

@include min-screen($screen__xl) {
    .catalog-product-view {
        .shipping-info {
            text-align: left;
            font-weight: 700;
            display: flex;
            width: 50%;
            float: left;
            padding-top: 25px;
            span {
                line-height: 30px;
            }
        }
        .product-badges {
            width: 50%;
        }
    }
}


// Tablet + small laptop
@include screen($screen__m, ($layout__max-width - $polarcore-header-breakpoint__offset)) {
    .page-layout-1column {
        .product-info-main {
            width: 50%;
        }
        .product.media {
            width: 50%;
        }
    }
    .product-options-bottom {
        flex-wrap: wrap;
        .box-tocart {
            flex-wrap: wrap;
            .fieldset {
                justify-content: space-between;
                width: 100%;
                .actions {
                    width: 100%;
                }
            }
        }
    }
    .product-bottom {
        width: 100%;
        margin: 0;
    }

    .catalog-product-view {
        .product-extra-usp {
            width: 47%;
            float: right;
            padding-left: 0;
            ul {
                margin-left: 0;
            }
        }

        .shipping-info {
            width: 100%;
            padding-left: 0;
            justify-content: center;
        }

        .product-badges {
            width: 100%;
            justify-content: center;
        }
    }
    .product-info-main {
        .box-tocart {
            .field .qty {
                margin-right: 0;
            }
            .polarcart-qty-wrapper {
                max-width: 100px;
            }
            .actions {
                margin: 0;
                button.action.primary.tocart {
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@include screen($screen__l, ($layout__max-width - $polarcore-header-breakpoint__offset)) {
    .product-add-form .product-options-bottom {
        .modal-button-placeholder {
            button {
                margin-right: 66px;
                margin-left: 114px;
            }
        }
    }
}


