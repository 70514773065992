a.action.primary,
button {
    transition: background 0.25s ease;
}

.action,
button {
    &:active {
        box-shadow: $button__shadow;
    }
    transition: background 0.25s ease;
}

a.action.primary {
    @include lib-link-as-button();
    transition: background 0.25s ease;
}

.action.primary {
    @include sparkjop-button();
}

.product-item .actions .action.primary.tocart,
:not(.product-item) .action.primary.tocart {
    @include lib-button-primary(
        $_button-border: $polarcore-primary-cart-button__border,
        $_button-border-hover: $polarcore-primary-cart-button__border,
        $_button-border-active: $polarcore-primary-cart-button__border,
        $_button-color: $polarcore-primary-cart-button__color,
        $_button-background: $polarcore-primary-cart-button__background,
        $_button-background-hover: darken($polarcore-primary-cart-button__background, 8%),
        $_button-background-active: darken($polarcore-primary-cart-button__background, 8%)
    );
    padding: 1rem;
    text-transform: none;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: $polarcore-primary-cart-button__box-shadow;
    transition: background 0.25s ease;
    span:before{
        display: none;
    }
}


.vc_btn3-style-outline {
    @include sparkjop-button();
    width: 100%;
    text-align: center;
}
