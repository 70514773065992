//
//  Category view
//  ---------------------------------------------

.p2-category-top {
    .page-title {
        font-size: 30px;
        font-weight: 500;
        color: rgb(85, 85, 85);
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .category-description {
        padding-bottom: 1rem;
        border-bottom: 1px solid $polarcore__border-color;
    }
}

.old-price,
.old.price {
    text-decoration: line-through;
    font-weight: 300;
    color: $sparkjop_old-price__color;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                font-size: 11px;
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.category {
    &-image {
        .image {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &-image,
    &-description {
        margin-bottom: $indent__base;
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: inline-block;
        max-width: 100%;
    }

    &-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &-photo {
        display: block;
        height: 100%;
        margin: auto;
        max-width: 100%;
        position: absolute;
        object-fit: contain;
        bottom: 0;
        right: 0;
        top: 0;
        left: 0;
    }
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        color: $text__color__muted;
        font-size: $font-size__s;
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}
.product-add-form .product-options-bottom {
    .modal-button-placeholder {
        width: 100%;
        height: 34px;
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        button {
            width: 100%;
            border: none;
            height: 25px;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-weight: normal;
        }

        .center-align {
            line-height: 25px;
            span {
                color: rgba(0, 0, 0, 0.5);
            }
        }

        .modal-swatch-error {
            color: $sparkjop_Red;
            margin-left: 0.5rem;
            width: 100%;
            text-align: center;
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        @include lib-table-resize(
            $_th-padding-left  : 0,
            $_th-padding-right : $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                color: $color__black;
                margin-bottom: 1.5rem;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__xs;
            font-size: 14px;
            border: none;
            display: inline-block;
            padding: 0;
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size  : $form-field-label-asterisk__font-size,
                        $_color      : $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style : $form-field-label-asterisk__font-style
                    );
                    margin: $form-field-label-asterisk__margin;
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                font-size: 12px;
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            font-size: 24px;
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            font-size: 14px;
            .price {
                color: $sparkjop_special-price__color;
                font-size: 24px;
            }
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        padding-left: 0rem;
        .price-label {
            display: none;
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            display: none;
        }

        .fieldset .field:not(.choice) {
            margin: 0;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 28px;
            height: 45px;
            text-align: center;
            width: 45px;
            border-color: $border-color__base;
        }

        .actions {
            text-align: center;
            margin-left: 1rem;
            height: 42px;
        }

        .action.tocart {
            @extend .abs-button-l;
            max-height: 44px;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    background: $sidebar__background-color;
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            font-size: 14px;
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            color: $primary__color__lighter;
            font-size: 12px;
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//Search autocomplete
.form.minisearch .control {
    display: flex;
    justify-content: center;
    .search-autocomplete {
        background: $color__white;
        margin-top: 40px;
        padding: 2rem;
        max-width: 555px;
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.05);
        border-left: 1px solid rgba(0,0,0,0.1);
        border-right: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.2);
        dd {
            display: flex;
            flex-direction: row;
            width: 100%;
            .product-image-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .product-item {
                width: calc(100% - 45px);
                .f-fix {
                    display: flex;
                    flex-direction: row;
                    .product-primary {
                        width: 70%;
                        .product-name {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .product-secondary {
                        width: 30%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            display: flex;
            flex-direction: column;
        }

        .product.media {
            order: -1;
        }
        .product-add-form .product-options-bottom {
            .modal-button-placeholder {
                width: 100%;
                height: 34px;
                margin-top: 1rem;

                button {
                    float: none;
                    width: 100%;
                }

                .modal-button {
                    margin-left: 108px;
                    margin-right: 50px;
                    height: 100%;
                    align-items: center;
                }
            }
        }
    }

    .p2-category-top {
        .page-title {
            margin: 1rem 0;
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: flex;
            flex-wrap: wrap;

            .field.qty {
                display: none;
            }

            .actions {
                display: table-cell;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        float: left;
                        left: auto;
                        margin: 0 $indent__s $indent__s 0;
                        position: relative;
                        top: auto;
                    }
                }

                &-details {
                    margin: 0;
                }

                &-actions {
                    clear: left;
                }
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

@include min-screen($screen__l) {
    .product-add-form .product-options-bottom {
        .modal-button-placeholder {
            button {
                margin-right: 57px;
                margin-left: 154px;
            }
            .modal-button {
                margin-left: 152px;
                margin-right: 56px;
                height: 100%;
                align-items: center;
            }
        }
    }
}

// add comment about size
@include max-screen($screen-custom__l) {
    .product-add-form .product-options-bottom {
        .modal-button-placeholder {
            .modal-button {
                margin-left: 112px;
                margin-right: 56px;
                height: 100%;
                align-items: center;
            }
        }
    }
}

@include screen($screen__m, $screen__l) {
    .product-add-form .product-options-bottom {
        .modal-button-placeholder {
            .modal-button {
                margin-left: 112px;
                margin-right: 56px;
                height: 100%;
                align-items: center;
            }
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }
                }

                &-details {
                    margin-left: 85px;
                }
            }
        }
    }

    .modal-button-placeholder {
        .modal-button {
            margin-left: 154px;
            margin-right: 50px;
            height: 100%;
            align-items: center;
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

.catalogsearch-result-index {
    .tw-productlabels__label--discount {
        span.tw-productlabels__text {
            width: 85px;
            height: 85px;
        }
    }
}

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: 40%;
        }
        .product.media {
            width: 57%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            font-size: 13px;
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    background: $page__background-color;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.tw-productlabels {
    &__label--discount {
        background: url('../images/markers-23.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        span.tw-productlabels__text {
            width: 130px;
            height: 90px;
            background-position: top left;
            padding-top: 22px;
            background-color: transparent;
        }
    }
    &__label--new {
        background: url('../images/sk-logo-empty.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        span.tw-productlabels__text {
            width: 35px;
            height: 45px;
            padding: 10px 5px 0 5px;
            font-size: 12px;
            font-weight: 700;
            background-color: transparent;
        }
    }
}

.price-rule-gallery-labels-container {
    position: absolute;
    right: 0;
    top: 1.5rem;

    .price-rule-description-label {
        display: none;
        background: url('../images/markers-23.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        color: white;
        width: 130px;
        height: 90px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 1.7rem;
        padding-top: 1rem;
        text-align: center;
    }
}
