.slick-slider {
    .slick {
        &-prev,
        &-next {
            &:before {
                color: #474747;
            }
        }
        &-prev {
            &:before {
                @include material-icon($icon-arrow-left-thin);
                font-size: 47px;
            }
        }
        &-next {
            &:before {
                @include material-icon($icon-arrow-right-thin);
                font-size: 47px;
            }
        }
    }
}
