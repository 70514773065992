#maincontent {
    #algolia-autocomplete-container {
        a {
            color: $sparkjop_DarkBlue;
        }
        .autocomplete-wrapper {
            flex-direction: row;
        }
        .aa-dropdown-menu {
            position: absolute;
            left: 50% !important;
            transform: translate(-50%, 0);
            width: 50% !important;
            border: none;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-top: 5px;
            .footer_algolia {
                display: none;
            }
            #autocomplete-products-footer {
                width: 100%;
                background: transparent;
            }
            .category {
                color: #1a1a1a;
                text-transform: none;
                font-size: 16px;
            }
            &.aa-without-products {
                .col9 {
                    background: transparent;
                }
            }
        }
        .autocomplete-wrapper {
            .col9 {
                min-width: initial;
                width: 70%;
                border: none;
            }
            .col3 {
                min-width: initial;
                width: 30%;
            }
            .aa-dataset-products {
                width: 100%;
                .aa-suggestions {
                    margin: 10px auto 40px 10px;
                    .aa-suggestion {
                        width: 33.333%;
                        overflow: hidden;
                        .algoliasearch-autocomplete {
                            &-hit {
                                .info {
                                    margin: 0;
                                    width: 100%;
                                    text-align: center;
                                    color: #1a1a1a;
                                    .algoliasearch-autocomplete-category {
                                        display: none;
                                    }
                                }
                                .thumb {
                                    width: 100%;
                                    text-align: center;;
                                }
                            }
                            &-price {
                                color: $sparkjop_DarkBlue;
                                font-weight: 700;
                            }
                        }
                        .info-without-thumb {
                            .details {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include max-screen($screen__m) {
    #maincontent {
        #algolia-autocomplete-container {
            .aa-dropdown-menu {
                position: absolute;
                left: 50% !important;
                transform: translate(-50%, 0);
                width: calc(100% - 1rem) !important;
                border: none;
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                .aa-no-results-products {
                    min-height: initial;
                    padding: 40px 20px 20px 20px;
                }
            }
            .autocomplete-wrapper {
                .col9 {
                    width: 100%;
                }
                .col3 {
                    width: 100%;
                }
                .aa-dataset-products {
                    .aa-suggestions {
                        margin: 10px auto 0 auto;
                        .aa-suggestion {
                            width: 100%;
                            .algoliasearch-autocomplete {
                                &-hit {
                                    padding: 0px 10px;
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    .info {
                                        margin: 0;
                                        width: 100%;
                                        text-align: left;
                                        .algoliasearch-autocomplete-category {
                                            display: none;
                                        }
                                    }
                                    .thumb {
                                        margin-right: 10px;
                                        width: auto; // fallback
                                        width: fit-content;
                                    }
                                }
                                &-price {
                                    color: $sparkjop_DarkBlue;
                                    font-weight: 700;
                                }
                            }
                            .info-without-thumb {
                                .details {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .other-sections {
                margin: 20px 10px 20px;
            }
        }
    }
}
