html {
    scroll-behavior: smooth;
}

hr {
    height: 3px;
    border: none;
    background-color: $color__accent;
    width: 50%;
    margin-left: 0;
}

._keyfocus *:focus {
    box-shadow: none;
}
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
    box-shadow: 0 0 2px 0px $sparkjop_DarkBlue;
}

a:visited{
    color: $sparkjop_DarkBlue;
}

.page-wrapper {
    > .breadcrumbs {
        margin: 1rem auto;
        .items {
            font-size: 14px;
        }
        .item {
            color: #1a1a1a;
            strong {
                font-weight: 600;
            }
            a {
                color: #1a1a1a;
                font-weight: 400;
                padding: 0 0.25rem;
            }
            &:after {
                color: #8c8c8c;
            }
            &.product {
                font-weight: 700;
            }
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    .page-wrapper {
        > .breadcrumbs {
            margin: 0;
            .items {
                padding: 0 0.5rem;
            }
        }
        .page-title-wrapper{
            .page-title{
                padding: 0 0.5rem;
                margin: 0.5rem 0;
            }
        }
    }
}

.wpb_single_image .vc_figure,
.vc_single_image-wrapper {
    width: 100%;
}


/* Sparkjop_CatalogOrder */
@include min-screen($screen__m) {
    .catalog-order-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;
        }
    }
}

@include max-screen($screen__m) {
    .page-main {
        .page.messages {
            z-index: 10;
        }
    }
}
