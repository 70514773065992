body{
    #maincontent{
        // Qty
        .polarcart-qty-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border: 1px solid $sparkjop_input-border__color;
            border-radius: 0.25rem;
            .qty {
                &-decrease,
                &-increase {
                    height: 40px;
                    width: 40px;
                    color: $sparkjop_LightGrey;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    font-size: 28px;
                    padding-bottom: 0.2rem;
                    line-height: 1;
                    font-weight: 600;
                    cursor: pointer;
                    user-select: none;
                    background: $sparkjop_White;
                    transition: background 0.25s ease;
                    cursor: pointer;
                    background: $sparkjop_White;
                    &:hover,
                    &:active {
                        background: darken($sparkjop_White, 4%);
                    }
                }
                &-decrease{
                    border-radius: 0.25rem 0 0 0.25rem;
                }
                &-increase{
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
            input {
                height: 40px;
                width: 44px;
                font-size: 19px;
                border-left: none;
                border-right: none;
                color: $sparkjop_CodGrey;
                margin: 0;
                padding: 0 0.25rem;
                background-color: $sparkjop_White;
                border: none;
                transition: background-color 250ms ease;
                &:focus {
                    box-shadow: none;
                    background-color: darken($sparkjop_White, 4%);
                }
                &.valid {
                    border-color: #c8c8c8;
                }
            }
        }
    }
    // Popup Polarcart UX
    #toast-container{
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 15vh;
        width: 100%;
        .toast{
            margin: 0 auto;
            border-radius: 0.25rem;
            background-position: 1rem center;
            color: $sparkjop_CodGrey;
            font-size: 16px;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            line-height: 1;
            .toast-close-button{
                margin-top: 0.6rem;
            }
        }
    }
    // Overwrite product-qty.css from Polarcart UX for message styling
    .page.messages{
        > div{
            position: static;
        }
    }
}

// Mobile
@include max-screen($screen__m + 1px) {
    body{
        #maincontent{
            .polarcart-qty-wrapper{
                .input-text {
                    margin-top: initial;
                    height: 34px;
                    width: 32px;
                    font-size: 18px;
                    border-left: 0;
                    border-right: 0;
                    margin: 0;
                }
                .qty-increase, .qty-decrease {
                    height: 34px;
                    width: 30px;
                    font-size: 24px;
                }

                .p2-qty-wrapper {
                    justify-content: flex-start;
                }
            }
        }
        #toast-container{
            .toast{
                padding: 0.5rem 1rem;
                font-size: 14px;
            }
        }
    }
}
