// General nosto slot styling
.nosto-wrapper{
    max-width: $layout__max-width;
    margin: 0 auto;
    .nosto-title{
        color: $sparkjop_Black;
        margin-bottom: 1rem;
        padding-left: 0.5rem;
        h3{
            line-height: 1.5;
        }
    }
    .products-grid{
        // Prevent Nosto products from being affected by configurable options hover effect
        .product-item:hover{
            margin-bottom: 1rem;
        }
    }
    .product-items{
        overflow: visible !important;
    }
    .product-item{
        background: $sparkjop_White;
        .product-reviews-summary{
            display: none;
        }
        .product-link{
            display: block;
            width: 100%;
            margin-bottom: 0;
            .nosto-add-to-cart{
                width: 70%;
            }
        }
        .product-image-wrapper{
            .product-image-photo{
                height: 100%;
                width: auto;
            }
        }
    }
    .slick-slider{
        .slick-arrow{
            margin: 0;
            &:before{
                background: $sparkjop_White;
                border-radius: 50%;
                color: $sparkjop_CodGrey;
                font-size: 38px;
                border: 1px solid $sparkjop_LightGrey;
            }
            &.slick-prev{
                left: -3rem;
            }
            &.slick-next{
                right: -3rem;
            }
        }
        .products-grid{
            padding: 0 3rem;
        }
        .product-item{
            margin-bottom: 1rem;
        }
    }
}

// Mobile + Tablet
@include max-screen($screen__l){
    .nosto-wrapper{
        .slick-slider{
            .slick-arrow{
                &.slick-prev{
                    left: 0;
                }
                &.slick-next{
                    right: 0;
                }
            }
        }
    }
}

// Mobile
@include max-screen($screen__m){
    .nosto-wrapper{
        .product-item{
            .product-link{
                .nosto-add-to-cart{
                    width: 100%;
                }
            }
        }
    }
}
