@mixin checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }
    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: #ffffff;
        border: 2px solid #474747;
        border-radius: 4px;
    }
    &:hover + label:before {
        background: #f4f5f4;
    }
    &:checked + label:before {
        background: #ffffff;
    }
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }
    &:checked + label:after {
        @include material-icon($icon-checkmark);
        position: absolute;
        left: 2px;
        top: 2px;
        font-weight: 900;
        font-size: 16px;
    }
}

@mixin input-label {
    position: absolute;
    top: 11px;
    left: 5px;
    font-size: 14px;
    padding: 0 5px;
    color: #474747;
    transform: scale(0.85);
    background: $sparkjop_White;
    -webkit-transform-origin: 10% -650%;
    transform-origin: 10% -650%;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease,  background-color 0.5s ease;
    z-index: 1;
}

@mixin telephone($top) {
    .control > span {
        color: $sparkjop_Grey3;
        position: absolute;
        top: $top;
        left: 11px;
    }

    input {
        padding-left: 40px;
    }
}

.fieldset {
    .field.required, .field._required{
        label.label:after {
            color: $sparkjop_Red2;
            content: '*';
            padding-right: 5px;
        }
    }
}

.payment-option-inner,
.fieldset {
    margin: 0 0 0px;
    &:last-of-type {
        margin-bottom: 10px;
    }
    > .legend + br {
        display: none;
    }
    // checkboxes
    input[type="checkbox"] {
        @include checkbox();
    }
    .field.choice {
        &:before {
            display: none;
        }
        margin-bottom: 10px;
    }
    //textfields
    .field:not(.choice){
        position: relative;
        padding: 0;
        margin: 15px 0;
        border: none;
        overflow: visible;
        &:first-of-type {
            margin-top: 0;
        }
        > .control {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        select,
        input {
            box-sizing: border-box;
            width: 100%;
            padding: 10px 10px 8px;
            border: none;
            border-radius: 0;
            box-shadow: none;
            border: 1px solid #c6c6c6;
            border-radius: 4px;
            font-size: 100%;
            outline: none;
            height: 40px;
            cursor: text;
            &::-webkit-input-placeholder {
                transition: color 300ms ease;
            }
            &:not(:focus)::-webkit-input-placeholder {
                color: transparent;
            }
        }
        label {
            @include input-label();
        }
        select,
        input {
            &:focus ~ label, &:not([value=""]):valid ~ label {
            -webkit-transform: scale(0.85);
            transform: scale(0.85);
            background: #ffffff;
            }
        }
    }
}
