.catalog-category-view {
	.p2-category-top {
		width: 100%;
		.page-title {
			margin-top: 0;
			color: #1a1a1a;
		}
		.category {
			&-description {
				padding: 0;
				margin-bottom: 1rem;
				.wpcmdl-bg-image {
					padding: 0 1rem;
					> .wpcmdl-cell {
						margin: 0;
					}
				}
			}
		}
	}

	.am-show-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1rem;
		&.-horizontal {
			width: 100%;
		}
		&.-vertical {
			width: auto;
			max-width: 15vw;
			margin: 0;
		}
		& > .am-button {
			margin-left: 0;
			margin-top: 1rem;
			padding: 1rem;
			width: 50%;
		}
	}
	.swatch-attribute {
		&.manufacturer,
		&.size {
			.swatch-option {
				&.selected {
					background-color: $color__white;
					box-shadow: 0 0 0 2px $color__action !important;
					.product-item & {
						color: black;
						border: none;
						box-shadow: none !important;
						font-size: 1.3em;
						&:before {
							font-size: 0.65em;
							top: -2px !important;
							position: relative;
						}
					}
				}
			}
		}
	}
	&.ajax-loading {
		opacity: 0.5;
		pointer-events: none;
		@keyframes spinner {
			to {transform: rotate(360deg);}
		}
		&:before {
			content: '';
			position: fixed;
			opacity: 1;
			z-index: 999999999;
			top: 50%;
			left: 50%;
			width: 50px;
			height: 50px;
			margin-top: -25px;
			margin-left: -25px;
			border-radius: 50%;
			border: 2px solid #ccc;
			border-top-color: $color__action;
			animation: spinner 1s linear infinite;
		}
		.am_shopby_apply_filters {
			&,
			&.visible {
				bottom: -100%;
			}
		}
	}

    .tw-productlabels {
        &__label--discount {
            span.tw-productlabels__text {
                width: 85px;
                height: 85px;
                background-position: top left;
                padding-top: 22px;
                background-color: transparent;
            }
        }
        &__label--new {
            span.tw-productlabels__text {
                width: 35px;
                height: 45px;
                padding: 10px 5px 0 4px;
                font-size: 12px;
                font-weight: 700;

            }
        }
    }
}

.products-grid {
    .product-items .product-item {
        .product-item-details__bottom {
            display: flex;
            justify-content: space-between;
            .availability {
                font-size: 11px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .page-products .products-grid .product-item-photo .tw-productlabels {
        top: 0;
        left: 0;
        &__label--discount {
            right: 0px;
            span.tw-productlabels__text {
                padding: 1.5rem 0.5rem 0;
            }
        }
    }
}

html:not(.isTouch) .products-grid .product-item.has-options {
    .swatch-attribute {
        background-color: $sparkjop_White;
    }
    .product-item-details .polarcore__details-wrapper {
        top: 5rem;
    }
    &:hover .product-item-details .polarcore__details-wrapper {
        top: 6.25rem;
    }
}
