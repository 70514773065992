.checkout-index-index {
    .modal-custom-overlay {
        display: none;
    }
    .modal-title {
        color: $sparkjop_CodGrey;
    }

    // inputs
    .radio {
      -webkit-appearance: none;
      height: 18px;
      width: 18px;
      position: relative;
      transition: all 0.15s ease-out 0s;
      background: #fff;
      color: #fff;
      cursor: pointer;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background: $sparkjop_WhiteGrey2;
      }
      &:checked {
        border: 2px solid $sparkjop_DarkBlue !important;
        background: transparent;
        &:before {
            @include material-icon($icon-done);
            color: $sparkjop_DarkBlue2;
            font-size: 20px;
            font-weight: bold;
            position: relative;
            right: -2px;
            top: -2px;
            text-shadow: 0 -1px 0 white, 1px 0 0 white, 0 1px 0 white, -1px 0px 0px white;
        }
      }
      &.radio {
        border-radius: 50%;
        border: 2px solid $sparkjop_LightGrey2;
        &:after {
          border-radius: 4px;
        }
      }
    }
    #checkout input[type="checkbox"]:checked {
        + label:before {
            border-color: $sparkjop_Grey3;
        }

        + label:after {
            color: $sparkjop_Grey3;
            font-size: 22px;
            right: -2px;
            text-shadow: 0 -1px 0 white, 1px 0 0 white, 0 1px 0 white, -1px 0px 0px white;
            top: -3px;
            left: 1px;
        }
    }
    .fieldset {
        .field:not(.choice) {
            input {
                border-color: $sparkjop_LightGrey2;
                height: 50px;
            }

            label {
                color: $sparkjop_CodGrey;
            }

            input:not([value=""]) ~ label {
                left: 8px;
                top: -9px;
                transform: scale(1);
            }
        }

        .field-error, #customer-email-error {
            color: $sparkjop_Red2;
            font-size: 0.86rem;
        }

        .field._error {
            input {
                border-color: $sparkjop_Red2;
            }

            label {
                color: $sparkjop_Red2
            }
        }
    }

    // notes
    .note, .field-note,.fieldset > .field .note {
        &:before {
            display: none;
        }

        color: $sparkjop_Grey3;
        font-size: 0.86rem;
        letter-spacing: 0.3px;
        line-height: 16px;
        margin-top: 10px;
        width: 100%;
    }

    a {
        color: $sparkjop_CodGrey;
        text-decoration: underline;
    }

    .step-title {
        border-bottom: 1px solid $sparkjop_DarkBlue2;
        color: $sparkjop_CodGrey;
        font-family: $font-family__headers;
        font-size: 24px;
        margin-bottom: 20px;
        padding: 0 0 10px 0;
    }

    // body
    .page-wrapper {
        background: $sparkjop_WhiteGrey;
    }
    #maincontent {
        min-height: calc(100vh - 235px);
    }

    // header
    .page-header {
        border-bottom: 1px solid $sparkjop_footer;
        z-index: 99;
    }
    .header.content {
        justify-content: space-between;
        min-height: 120px;
        margin: auto;
        max-width: 960px;
        padding: 10px;

        .logo {
            left: 50%;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .action.nav-toggle{
            display: none;
        }
    }
    #button-backtostore,
    #button-backtostore-mobile {
        color: $sparkjop_CodGrey;
        font-weight: bold;
        font-size: 1rem;
        &:before {
            @include material-icon($icon-arrow-left-thin);
            color: $sparkjop_DarkBlue2;
            font-size: 1.3rem;
            font-weight: bold;
            text-decoration: none;
            vertical-align: middle;
        }
    }
    .customer-support {
        text-align: right;
        flex-grow: 1;
        p {
            color: $sparkjop_CodGrey;
            font-size: 1rem;
            margin: 2px 0;

            &:first-of-type {
                color: $sparkjop_DarkBlue;
                font-weight: 700;
            }
        }
    }
    .page-title-wrapper {
        text-align: center;
        font-size: 32px;
    }

    .opc-progress-bar-item {
        &:not(:last-child) {
            margin-right: 2px;
        }

        &:before {
            height: 2px;
        }

        &._active > {
            span {
                font-weight: 700;
            }

            span:after {
                color: $sparkjop_LightBlue;
            }
        }

        span {
            color: $sparkjop_Grey3;
            font-size: 0.86rem;
            padding-top: 35px;

            &:after {
                font-family: inherit;
                font-size: 1rem;
            }
        }

        &._complete {
            span:after {
                @include material-icon($icon-done);
                font-size: 20px;
            }
        }
    }

    // Log in popup
    @mixin login-actions {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        flex-direction: row-reverse;

        .primary button {
            @include sparkjop-button-black-text();
        }

        > .secondary {
            padding: 0;
            margin: 0;
            .action {
                margin: 0;
                padding: 8px 32px 8px 0;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .authentication-wrapper {
        color: $sparkjop_CodGrey;
        float: none;
        margin: 0;
        max-width: 100%;
        width: 100%;

        .action {
            color: $sparkjop_CodGrey;
            font-weight: bold;
        }

        .authentication-dropdown {
            border: 1px solid $sparkjop_footer;
            border-radius: 16px;
            box-shadow: none;
            margin-left: -30px;
            min-width: 480px;
            right: -2px;
            top: 0;
        }

        .block-title {
            border-bottom: 1px solid $sparkjop_DarkBlue2;
            font-size: 1.7rem;
            font-family: "Bitter", serif;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }

        .login-vipps-holder {
            margin-bottom: 25px;

            .login-vipps {
                margin-bottom: 20px;

                button {
                    padding: 10px 20px;
                }
            }
        }

        .actions-toolbar {
            @include login-actions();
        }
    }

    .checkout-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 960px;
        margin: 30px auto 0 auto;
        padding: 0 10px;
    }

    .opc-wrapper {
        float: none;

        .step-content {
            margin: 0;
        }
    }

    #opc-shipping_method {
        margin-top: 20px;
    }

    #opc-shipping_method, #shipping, div.opc-block-summary, #checkout-payment-method-load {
        background: #fff;
        border: 1px solid $sparkjop_footer;
        border-radius: 8px;
        box-shadow: none;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    }

    // Email with log in
    .form.form-login {
        border: none;
        margin-top: 20px;
        max-width: none;

        #customer-email {
            margin-right: 0;
        }

        .fieldset.hidden-fields {
            margin-top: 30px;
        }

        .actions-toolbar {
            @include login-actions();

            .action {
                color: $sparkjop_CodGrey;
                font-weight: bold;
            }
        }
    }
    .vipps-login-shipping {
        align-items: center;
        display: flex;
        justify-content: end;
        margin-top: -25px;
        text-align: right;

        p {
            color: $sparkjop_CodGrey;
            margin-right: 10px;
        }

        button {
            @include sparkjop-button-black-text();
        }
    }

    // Adress forms
    #shipping-new-address-form, [data-form="billing-new-address"] {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        > div {
            width: 100%;
        }

        [name$=".firstname"] {
            padding-right: 5px;
            width: 50%;
        }

        [name$=".lastname"] {
            width: 50%;
        }

        fieldset.street {
            margin: 15px 0 0 0;
            width: 100%;

            .field {
                width: 100%;
            }

            legend {
                display: none;
            }
        }

        [name$=".country_id"] {
            display: none;
        }

        [name$=".postcode"] {
            padding-right: 5px;
            width: 100px;
        }

        [name$=".city"] {
            width: calc(100% - 100px);


        }

        .field:not(._error) [name$=".city"] {
            input {
                border-color: $sparkjop_WhiteGrey3;
            }
        }

        [name$=".telephone"] {
            @include telephone(17px);
        }

        .field.choice {
            color: $sparkjop_CodGrey;
        }
    }
    #co-shipping-form, .billing-address-form {
        max-width: none;
    }
    .checkout-billing-address {
        input[name="billing-address-same-as-shipping"] {
            @include checkbox();
        }

        .actions-toolbar .primary {
            display: flex;
            align-items: baseline;
            justify-content: end;
        }

        .action.action-update {
            @include sparkjop-button-black-text();
        }

        .action.action-cancel {
            @include sparkjop-text-button();
            margin-right: 5px;
            order: -1;
            width: auto;
        }
    }


    // shipping address
    #shipping {
        .shipping-address-items {
            margin-top: 20px;

            .shipping-address-item {
                border: 1px solid $sparkjop_LightBlue;
                border-radius: 4px;
                color: $sparkjop_CodGrey;
                line-height: 20px;
                padding: 16px;
                width: calc(50% - 8px);

                &::before {
                    display: none;
                }

                &:nth-child(odd) {
                    margin-right: 16px;
                }

                &.selected-item {
                    background-color: $sparkjop_LightBlue;

                    &:after {
                        background-color: $sparkjop_DarkBlue2;
                        border-radius: 30px;
                        line-height: 23px;
                        font-size: 20px;
                        right: -9px;
                        top: -9px;
                    }
                }

                .action-select-shipping-item {
                    @include sparkjop-text-button();
                    text-align: right;
                }

                a {
                    color: inherit;
                }
            }
        }
        .new-address-popup {
            text-align: right;

            .action-show-popup {
                @include sparkjop-text-button();
                margin: 0;
                width: auto;

                span:before {
                    color: $sparkjop_DarkBlue2;
                    font-size: 1.5rem;
                    text-decoration: none;
                }
            }
        }

        .consent-fields {
            background-color: $sparkjop_LightBlue;
            border-radius: 16px;
            margin-top: 10px;
            padding: 20px;

            .checkbox + label:before {
                background-color: $sparkjop_LightBlue;
            }

            .actions {
                text-align: right;

                > span {
                    color: $sparkjop_CodGrey;
                    cursor: pointer;
                    font-weight: bold;
                    padding-left: 25px;
                    position: relative;
                }

                .material-icons {
                    color: $sparkjop_DarkBlue2;
                    font-size: 1.1rem;
                    font-weight: bold;
                    left: 0;
                    top: 1px;
                    position: absolute;

                    &:nth-of-type(2) {
                        left: 5px;
                    }
                }
            }
        }
    }

    // shipping methods
    #opc-shipping_method {
        color: $sparkjop_CodGrey;

        .table-checkout-shipping-method {
            margin-bottom: 0;
            min-width: initial;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            thead {
                display: none;
            }

            tbody {
                width: 100%;
            }

            td {
                border: none;
                padding: 0;

                &:first-child {
                    min-width: 25px;
                }

                &:nth-child(2) {
                    min-width: 70px;
                }

                &:nth-child(3) {
                    flex-grow: 1;
                }

                &.bring-logo {
                    height: 35px;
                    min-width: 50px;

                    img {
                        width: 50px;
                        margin-top: 9px;
                    }
                }
            }

            .row {
                align-items: center;
                margin: 12px 0;
                display: flex;
                border-radius: 10px;
                width: 100%;

                .col-price {
                    .price-suffix {
                        margin-right: 20px;
                    }
                }
            }
        }

        .actions-toolbar {
            margin-top: 20px;

            button[data-role='opc-continue'] {
                @include sparkjop-button-blue();
                padding: 14px 20px;
            }
        }
    }

    #checkout-step-shipping_method .no-quotes-block {
        white-space: pre-line;
    }

    // sidebar
    .opc-sidebar {
        float: none;
        margin: 0;

        //summary
        .opc-block-summary {

            > .title {
                border-bottom: 1px solid $sparkjop_DarkBlue2;
                color: $sparkjop_CodGrey;
                font-family: $font-family__headers;
                font-size: 24px;
                padding: 0 0 10px 0;
            }

            .table-totals {
                color: $sparkjop_CodGrey;
            }

            .totals.sub {
                th, td {
                    padding-top: 14px;
                }
            }

            .totals.shipping {
                .mark, .amount {
                    padding-bottom: 14px;
                    padding-top: 0;
                }

                .value {
                    color: $sparkjop_Grey3;
                    font-size: 0.86rem;
                }
            }

            .grand.totals {
                border-top: 1px solid $sparkjop_footer;

                .mark, .amount {
                    border: none;
                    font-size: 1.15rem;
                    padding-bottom: 0;
                    padding-right: 0;
                    padding-top: 10px;
                }
            }

            .totals-tax {
                font-size: 0.86rem;

                th, td {
                    padding-bottom: 0;
                    padding-top: 3px;
                }
            }

            .minicart-items-wrapper {
                border: none;
                margin: 0;
                max-height: none;
                padding: 5px 0 0 0;
            }

            .items-in-cart {
                margin-top: 20px;

                > .title {
                    border: none;
                    color: $sparkjop_CodGrey;
                    padding-right: 0;
                    margin: 0;
                    padding-top: 0;

                    strong {
                        font-size: 1.15rem;
                    }

                    &::after {
                        color: $sparkjop_Grey3;
                        font-size: 2rem;
                        top: 3px;
                    }
                }

                .product-item {
                    border-top: 1px solid $sparkjop_footer;
                    color: $sparkjop_CodGrey;
                    padding-top: 15px;

                    .product-item-name-block {
                        display: block;

                        .product-item-name {
                            color: $sparkjop_CodGrey !important;
                            font-size: 1.15rem;
                            font-weight: bold;
                        }

                        .details-qty {
                            font-size: 1rem;
                        }
                    }

                    .subtotal {
                        display: block;
                        text-align: left;

                        .price-including-tax {
                            margin: 0;

                            .price {
                                font-size: 1rem;
                                font-weight: bold;
                            }
                        }
                    }

                    .product .toggle:after {
                        color: $sparkjop_Grey3;
                    }

                    .item-options {
                        dt {
                            clear: left;
                            float: left;
                            margin-bottom: 0;
                            margin-right: 5px;
                        }

                        dd {
                            float: left;
                            margin-bottom: 0;
                        }

                        &:before {
                            content: '';
                            display: block;
                            height: 1px;
                        }
                    }
                }
            }
        }

        .opc-block-shipping-information {
            margin-top: 20px;
            padding: 0;

            .shipping-information-title {
                border-bottom: 1px solid $sparkjop_DarkBlue2;
                font-size: 1.7rem;
                margin: 0 0 6px;

                button {
                    top: 0;

                    &::before {
                        color: $sparkjop_Grey3;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    // Address information
    #checkout {
        .shipping-information-content, .billing-address-details {
            color: $sparkjop_CodGrey;
            line-height: 20px;
        }

        .shipping-information-title {
            color: $sparkjop_CodGrey;
        }
    }

    // payment
    .checkout-payment-method {
        #co-payment-form > fieldset {
            > br, > hr, > legend {
                display: none;
            }
        }

        .terms-and-conditions {
            color: $sparkjop_CodGrey;
            margin-bottom: 20px;
            margin-top: 15px;
        }

        .payment-method {
            color: $sparkjop_CodGrey;

            .payment-method-title {
                align-items: center;
                border-color: $sparkjop_footer;
                border-width: 0;
                display: flex;
                margin-bottom: 0;
                padding: 15px 0;

                input{
                    margin-right: 5px;
                }

                label {
                    align-items: center;
                    display: flex;

                    img {
                        max-height: 25px;
                    }
                }
            }

            & ~ .payment-method .payment-method-title {
                border-width: 1px;
            }

            .actions-toolbar > .primary button[type="submit"] {
                @include sparkjop-button-blue();
                font-size: 1rem;
            }

            .payment-method-content {
                .order-additional-b2b-customer-form {
                    .payment-option-title {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        margin-bottom: 0;
                    }

                    .payment-option-content {
                        padding-bottom: 0;

                        .payment-option-inner {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .discount-code.payment-option {
            .payment-option-title {
                border: none;
                padding: 15px 5px;

                .action-toggle {
                    color: $sparkjop_CodGrey;
                    font-weight: 700;
                    font-size: 14px;

                    &:after {
                        color: $sparkjop_DarkBlue2;
                        font-size: 20px;
                        margin: 0;
                    }
                }
            }

            .payment-option-inner {
                margin: 0 0 10px;
            }

            .payment-option-content {
                padding: 0 5px;

                .form-discount {
                    display: flex;
                    max-width: none;
                }

                .payment-option-inner {
                    flex-grow: 1;

                    input {
                        height: 40px;

                        &::placeholder {
                            color: $sparkjop_Grey3;
                        }
                    }
                }

                .actions-toolbar {
                    margin-left: 5px;
                    padding: 0;
                }

                .action {
                    @include sparkjop-button-black-text;
                    user-select: none;
                    padding: 10px 15px;
                    white-space: nowrap;
                }
            }
        }
    }
    // adyen payments
    .checkout-payment-method .payment-method #adyen-cc-form {
        label {
            left: 0;
            padding: 0;
            position: relative;
            top: 0;
            transform: none;
            transform-origin: unset;

            > span {
                @include input-label();
                color: $sparkjop_CodGrey;
                left: 8px;
                top: 7px;
            }
        }

        .adyen-checkout__input {
            border-color: $sparkjop_LightGrey2;
            box-shadow: none;
            height: 52px;
            padding: 10px;
        }

        .adyen-checkout__card__brands {
            justify-content: end;
            margin-top: -12px;
        }

        .adyen-checkout__card__exp-cvc {
            .adyen-checkout__field__cvc {
                margin-left: 0;
            }
        }
    }

    .checkout-footer {
        background-color: white;
        border-top: 1px solid $sparkjop_footer;
        color: $sparkjop_DarkBlue2;
        padding: 24px 0;
        text-align: center;
    }
}

// Success page
.checkout-success{
    color: $sparkjop_CodGrey;
    max-width: 650px;
    margin: 0 auto;
    word-break: break-word;

    p {
        font-size: 16px;
    }
    a {
        color: $sparkjop_CodGrey;
        text-decoration: underline;
    }

    .order-info {
        margin: 0 auto;
        text-align: center;

        .thank-you {
            font-size: 36px;
            font-weight: 500;
            font-family: "Bitter", serif;
        }

        .order-number {
            margin-bottom: 1.5rem;

            span{
                font-weight: 600;
            }
        }

        .order-confirmation {
            margin-bottom: 1.5rem;
        }
    }

    #registration {
        background-color: $sparkjop_LightBlue;
        border-radius: 16px;
        text-align: center;

        br {
            display: none;
        }

        p {
            margin: 10px 0;
        }

        > div:not(.messages) {
            padding: 16px 10px 23px 10px;
        }

        .action.primary {
            @include sparkjop-button-blue();
            padding: 8px 20px;
        }
    }

    .order {
        margin-top: 2rem;

        .headline {
            border-bottom: 1px solid $sparkjop_DarkBlue2;
            color: $color_black;
            font-size: 24px;
            font-family: "Bitter", serif;
            padding-bottom: 1rem;
        }

        &-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding: 0.75rem 0;
            border-bottom: 1px solid $sparkjop_footer;
            font-size: 16px;

            > span:first-of-type {
                flex-shrink: 0;
                margin-right: 2rem;
            }

            > span:last-of-type {
                color: $color_black;
                font-weight: bold;
                text-align: right;
            }
        }
    }

    .continue-shopping {
        padding: 0.5rem 0;
        margin-top: 20px;
        text-align: center;

        .action.primary.continue {
            @include sparkjop-button-black-text;
            transition: all 300ms ease;
        }
    }
}

@include max-screen($screen__m) {
    .checkout-index-index {
        // General
        .page-wrapper {
            background-color: white;
        }
        #maincontent {
            padding-top: 0;
        }
        #checkout {
            margin-top: 0;
            padding: 0;
        }
        .opc-wrapper {
            width: 100%;
        }
        .header.content {
            padding: 14px;
        }
        #opc-shipping_method, #shipping, div.opc-block-summary, #checkout-payment-method-load {
            border: none;
            border-radius: 0;
            padding: 0;
        }
        .customer-support {
            display: none;
        }
        .opc-progress-bar {
            display: none;
        }

        // Modals
        .modal-custom-overlay, .modals-overlay {
            background-color: $sparkjop_DarkBlue2;
            display: block;
            opacity: 0.3;
        }
        div.modal-inner-wrap {
            background-color: white;
            max-width: none;
        }
        .modal-custom, .modal-popup {
            margin-left: 5%;
        }
        .modal-popup.modal-slide[data-role="modal"] .modal-inner-wrap {
            .modal-header .modal-title {
                font-size: 1.71rem;
                padding: 24px;
            }

            .modal-content {
                padding: 24px;
            }

            .modal-footer {
                padding: 24px;
                padding-top: 0;
            }
        }

        // Estimation and summary
        .opc-estimated-wrapper {
            align-items: center;
            display: flex;
            background-color: $sparkjop_LightBlue;
            border: none;
            margin: 0 -15px 15px -15px;
            padding: 16px;
            width: calc(100% + 30px);

            .estimated-block {
                flex-grow: 1;

                .estimated-label {
                    font-weight: normal;
                }
            }

            .minicart-wrapper {
                margin-top: 0;

                button.action.showcart {
                    align-items: center;
                    flex-direction: row;

                    &:before {
                        color: $sparkjop_DarkBlue2;
                    }
                }

                .counter.qty {
                    background-color: $sparkjop_DarkBlue2;
                    bottom: auto;
                    height: 20px;
                    left: auto;
                    margin: 0 0 0 5px;
                    position: relative;
                    right: auto;
                    top: auto;
                    transform: scale(1);
                    width: 20px;
                }
            }
        }
        .opc-sidebar.opc-summary-wrapper #opc-sidebar {
            padding: 24px;

            .product-item > .product {
                flex-wrap: nowrap;
                padding: 0;

                .product-image-container {
                    flex-shrink: 0;
                }

                .product-item-details {
                    padding-left: 1rem;
                }
            }
        }

        // Authentication popup
        .authentication-wrapper {
            .authentication-dropdown {
                border: none;
                border-radius: 0;
                margin-left: 5%;
                min-width: unset;
            }
        }

        // Shipping methods
        #opc-shipping_method {
            .action.continue.primary {
                width: 100%;
            }
        }

        // Payment
        #payment {
            .payment-methods {
                margin: 0;

                .action.primary.checkout {
                    width: 100%;
                }
            }

            .discount-code {
                margin: 0;
                text-align: right;
            }
        }
    }

    .checkout-onepage-success {
        .order-info .thank-you {
            font-size: 1.86rem;
        }

        #registration {
            > div:not(.messages) {
                p:nth-of-type(2) span:first-child {
                    font-weight: bold;
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .checkout-index-index {
        #shipping .shipping-address-items .shipping-address-item {
            margin-right: 0;
            width: 100%;
        }
    }
}

.sparkjop-alert {
    border-radius: 16px;
    padding: 16px;
    padding-left: 40px;
    position: relative;

    .clickable-text-message {
        cursor: pointer;
        text-decoration: underline;
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    &:before {
        -webkit-mask-image: url('../Sparkjop_BringAddress/images/error.svg');
        -webkit-mask-size: contain;
        content: ' ';
        height: 20px;
        left: 16px;
        position: absolute;
        width: 20px;
    }

    &.error {
        background-color: $sparkjop_Red3;

        &:before {
            background-color: $sparkjop_Red2;
        }
    }

    &.warning {
        background-color: $sparkjop_WhiteGrey;
        border: 1px solid $sparkjop_WhiteGrey3;

        &:before {
            background-color: $sparkjop_DarkBlue2;
        }
    }
}
