.page-header {
    position: relative;
    z-index: 11;
    border: none;
    .header.panel {
        position: relative;
        .widget.block {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            margin: 0;
            font-size: 12px;
            color: #595959;
        }
        a.support {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            color: #1a1a1a;
            line-height: 18px;

            &:hover {
                text-decoration: none;
            }
            span {
                &:hover {
                    text-decoration: underline;
                }
            }
            &:before {
                @include material-icon($icon-help);
                font-size: 18px;
                margin-right: 0.25rem;
            }
        }
        ul {
            display: flex;
            list-style: none;
            width: 100%;
            padding: 0;
            margin: 0;
            &.header.links {
                display: none;
            }
            li {
                display: flex;
                align-items: center;
                padding: 0 2rem;
                margin: 0;
                i {
                    margin-right: 0.25rem;
                    font-size: 20px;
                }
            }
        }
    }
}
.header.content {
    .logo {
        margin: 0 10px 14px 0;
        max-width: 60px;
    }
    .block-search {
        width: 600px;
        margin: auto;
        #algolia-searchbox {
            .search-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 2rem;
                height: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 0.5rem;
                color: #2c448e;

                .clear-query-autocomplete {
                    display: none;
                }
            }
            .magnifying-glass {
                bottom: auto;
                right: auto;
                width: auto;
                height: auto;
                background: transparent;
            }

            .algolia-search-input:focus:not([value=""]) {
                background: #edf1ff;
                border: none;
                box-shadow: 0 0 3px 1px #d1d7e6;
            }
        }

        .control {
            border: none;
        }
        input {
            background: #edf1ff;
            border-radius: 20px;
            height: 40px;
            max-height: none;
            padding: 0 2rem;
            font-size: 14px;
            &:focus {
                box-shadow: 0 0 3px 1px #d1d7e6;
            }
        }
        ::placeholder {
            color: #595959;
            opacity: 1;
        }
        :-ms-input-placeholder {
            color: #595959;
        }
        ::-ms-input-placeholder {
            color: #595959;
        }
        .action.search {
            right: 1.5rem;
            opacity: 1;
            &:before {
                color: #2c448e;
                font-size: 32px;
                line-height: 32px;
            }
        }
    }
    .polarcore-header-links {
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $sparkjop_DarkBlue;

            &:hover {
                text-decoration: none;
            }
        }

        .company-link {
            .company-text {
                @extend %header-icon-text;
            }
            .company-icon {
                font-size: 26px;

                &:before {
                    @extend %header-icon;
                    content: fa-content($fa-var-briefcase);
                }
            }
        }

        .stores-link {
            .store-text {
                @extend %header-icon-text;
            }
            .store-icon {
                font-size: 24px;

                &:before {
                    @extend %header-icon;
                    content: fa-content($fa-var-map-marker-alt);
                }
            }
        }

        .account-link {
            .account-text {
                @extend %header-icon-text;
            }

            .account-icon {
                font-size: 26px;

                &:before {
                    @extend %header-icon;
                    content: fa-content($fa-var-user);
                }
            }
        }

        .wishlist-link {
            display: block;
            margin-left: 0;
            position: relative;

            .wishlist-counter {
                display: none;
                justify-content: center;
                align-items: center;
                background: #ffb03b;
                border-radius: 50%;
                color: $page__background-color;
                height: 24px;
                position: absolute;
                right: 3px;
                text-align: center;
                transform: scale(0.75);
                top: -4px;
                width: 24px;

                &.displayCounter {
                    display: flex;
                }

                .counter-number {
                    font-weight: 700;
                }
            }
            .wishlist-icon {
                font-size: 26px;

                &:before {
                    @extend %header-icon;
                    font-weight: 900;
                    content: fa-content($fa-var-heart);
                }
            }
            .wishlist-text {
                @extend %header-icon-text;
            }
        }
        .minicart-wrapper {
            margin-left: 0;
            .action.showcart {
                align-items: center;

                .text {
                    @extend %header-icon-text;
                }

                &:before {
                    @extend %header-icon;
                    content: fa-content($fa-var-shopping-cart);
                    color: $sparkjop_DarkBlue;
                }
                &.active {
                    display: flex;
                }
                .counter.qty {
                    top: -7px;
                    right: 10px;
                    background: #ffb03b;
                    font-weight: 700;
                }
            }
        }
    }
}

// Mobile
@include max-screen($screen__xs) {
    header.page-header .header.content .polarcore-header-links {
        .company-text, .store-text, .account-text, .wishlist-text, .text {
            display: none;
        }
    }
}
@include max-screen($screen__s) {
    header.page-header .header.content .polarcore-header-links {
        width: 60%;
    }
}
@include max-screen($screen__m) {
    header.page-header {
        display: flex;
        .panel.wrapper {
            display: none;
        }
        .polarcore-header-links {
            width: 60%;
            justify-content: space-around;

            .account-link,
            .company-link,
            .stores-link,
            .wishlist-link,
            .minicart-wrapper {
                margin-top: 10px;
            }

            .company-link {
                display: none;
            }
        }
        .meganav_wrapper {
            order: -1;
        }
        .nav-toggle {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            height: 64px;
            width: 55px;
            &:before {
                border-radius: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 40px;
            }
            &:after {
                content: 'Meny';
                font-size: 10px;
                text-transform: uppercase;
            }
        }

        .header.content {
            .logo {
                max-width: 50px;
            }
            .block-search {
                .control,
                #search {
                    margin: 0;
                }
                .field.search .label {
                    top: auto;
                    &:before {
                        color: #2c448e;
                        font-size: 38px;
                        line-height: 38px;
                    }
                }
            }
        }
    }
    .polarcore-header-links {
        .minicart-wrapper {
            .action.showcart {
                .text {
                    display: block;
                }
            }
        }
    }
}

$polarcore-header-breakpoint__offset: 200px !default;

// Tablet (small iPad)
@include screen($screen__m , $screen__l) {
    .page-header{
        .header.content{
            .logo{
                margin: 0 2vw 0 7vw;
            }
            .nav-toggle{
                display: block;
                top: auto;
            }
            .polarcore-header-links {
                .company-link,
                .stores-link,
                .account-link,
                .wishlist-link,
                .minicart-wrapper {
                    margin: 0 1px;
                }
            }
            .nav-sections{
                width: 75%;
                .navigation{
                    ul{
                        display: block;
                        .submenu{
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}


// Tablet (all sizes 768px -> 1280px)
@include screen($screen__m, ($layout__max-width - $polarcore-header-breakpoint__offset)) {

    .polarcore {
        #maincontent #algolia-autocomplete-container {
            .aa-dropdown-menu {
                width: calc(100% - 1rem) !important;
            }
        }
    }

    .page-wrapper > .breadcrumbs{
        margin: 0;
    }

    .header.content {
        .block.block-search {
            order: 20;
            width: calc(100% - 430px);
            max-width: 46vw;
            padding: 0;
        }
        .polarcore-header-links {
            order: 30;
            width: auto;
        }
    }
}

// Desktop
@include min-screen($screen__l + 1px) {
    .page-header {
        box-shadow: 0px 1px 5px rgba(0,0,0,0.05);
    }
    .header.panel {
        widget.block {
            margin: 10px 0 0 0;
        }
        ul {
            justify-content: center;
        }
    }
    .nav-sections {
        order: 100;
        width: 100%;
        margin-top: 2rem;
    }
    .header.content {
        margin-top: 10px;

        .logo{
            position: absolute;
            left: 0.5rem;
            top: -4px;
            margin: 0;
        }

        .polarcore-header-links {
            width: 27%;
            justify-content: space-around;
            position: absolute;
            top: -2px;
            right: 0;
        }
    }
}

@include min-screen($layout__max-width) {
    .header.content .polarcore-header-links {
        width: 20%;
    }
}

%header-icon {
    @extend %fa-icon;
    @extend .fas;
    display: flex;
    justify-content: center;
    line-height: 40px;
    width: 40px;
    height: 40px;

    @include max-screen($screen__s) {
        width: 35px;
        height: 35px;
    }
}

%header-icon-text {
    font-size: (10rem/14);
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 2px;

    &:hover {
        text-decoration: underline;
    }
}
