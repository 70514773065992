.page-main {
  h1,
  h2,
  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    font-family: $font-family__headers;
  }
}