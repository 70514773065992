/* Using default body setup */
.sticky {
    /*
     * CLEAR Activates when going past the bottom border of target (default: .page-header)
     */
    &--clear {
        .page-header {
            position: fixed;
            opacity: 0;
            transform: translateY(-200%);
            width: 100%;
            top: 0;
            z-index: 40;
            box-shadow: none;
        }
    }

    /*
     * DIRTY Activates immediately on scrolling
     */

    &--dirty {
    }

    /*
     * UP Activates when scrolling down
     */
    &--up {
        .page-header {
            transform: translateY(-100%);
            transition: transform 0.6s ease, top 0.6s ease, opacity 0.8s ease;
        }
    }

    /*
     * DOWN Activates when scrolling up
     */
    &--down {
        .page-header {
            transition: transform 0.4s ease, top 0.4s ease, opacity 0.4s ease;
            transform: translateY(0%);
            opacity: 1;
        }
    }
}

@include max-screen($screen__m) {
    .sticky {
        &--down {
            .page-header {
                .panel.header {
                    .usp-wrapper {
                        display: none;
                    }
                }
            }
        }
    }
}
