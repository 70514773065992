// /**
//  * Copyright © 2013-2017 Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________

.filter {

	&-title {
		strong {
			@include sparkjop-button();
			text-align: center;
			z-index: 2;

			display: flex;
			justify-content: space-between;
			align-items: center;
            flex-direction: row-reverse;
            height: 50px;

			&:before {
				@include material-icon($icon-sorter);
				padding-right: 0.5rem;
				font-size: 1.5em !important;
				transform: scale(1.5);
				color: $color__base;
			}

            &:focus, &:active {
                border: 2px solid $sparkjop_DarkBlue;
                background-color: $sparkjop_White;
                color: $sparkjop_DarkBlue;
            }

			&[data-count]:after {
				color: $color-white;
				background: $color-orange-red1;
				border-radius: 2px;
				content: attr(data-count);
				display: inline-block;
				font-size: 0.8em;
				line-height: 1;
				margin: 0 $indent__xs;
				min-width: 1em;
				padding: 2px;
			}

			&.disabled {
				opacity: 0.5;
			}
		}
	}

	.block-subtitle {
		border-bottom: $border-width__base solid $border-color__base;
		font-size: $font-size__base;
		font-weight: $font-weight__semibold;
		line-height: 1em;
		padding: $indent__s $indent__s $indent__base;
	}

	&-subtitle {
		display: none;
	}

	&-current {
		margin: 0;

        .filter-current-subtitle {
            border: none;
            display: block;
            padding-top:7px;
            padding-bottom: 7px;
        }

		.items {
			padding: $indent__xs $indent__s;
           > .item {
                padding: 0.4rem 0.5rem 0.4rem 0.6rem;
                position: relative;
                z-index: 1;
                border: 1px solid;
                border-radius: 5px;
                display: inline-flex !important;
                margin: 0 0.25rem 0.5rem 0;
                .filter {
                    &-label {
                        display: none;
                    }
                    &-value {
                        color: $color__base;
                        clip: unset;
                        margin-right: 5px;
                        height: auto;
                        width: auto;
                        position: relative;
                        padding: unset;
                        overflow: unset;
                        vertical-align: middle;
                        line-height: 1;
                    }
                }
            }
		}

		.action.remove {
			@extend .abs-remove-button-for-blocks;
			left: -2px;
			top: -1px;
            position: relative;
            padding: 0;
            display: flex;
            align-items: center;
            &:before {
                display: none;
            }
            &:after {
                @include material-icon($icon-close);
                font-size: 1em;
                font-weight: bold;
                color: $color__white;
                background: $color__base;
                border-radius: 50%;
                width: 1.3rem;
                height: 1.3rem;
                text-align: center;
                line-height: 1.3em;
                padding: 0;
                margin-left: 0.5rem;
                transform: translateX(3px);
            }
		}
	}

	&-actions {
		margin-bottom: $indent__m;
		padding: 0 $indent__s;
	}

	&-label {
		font-weight: $font-weight__bold;

		&:after {
			content: ': ';
		}
	}

	&-value {
		color: $filter-quantity;
	}

	&-options {
		display: none;
		margin: 0;

		&-item {
			border: none;
			background-color: #f9f9f9;
			padding: 1rem;
			margin-bottom: $indent__s;
			border-radius: 5px;
            // Price filter
            .smile-es-range-slider{
                display: flex;
                flex-wrap: wrap;
                padding: 0.5rem;
                div[data-role="from-label"]{
                    text-align: left;
                    width: 50%;
                }
                div[data-role="to-label"]{
                    text-align: right;
                    width: 50%;
                }
                .ui-slider{
                    width: 100%;
                    margin-top: 0.5rem;
                    display: flex;
                    align-items: center;
                    &-handle{
                        width: 17px;
                        height: 17px;
                        background: $sparkjop_DarkBlue;
                        cursor: pointer;
                    }
                    > a:last-of-type{
                        margin-left: -10px;
                    }
                }
                .actions-toolbar{
                    width: 100%;
                    text-align: center;
                    .actions-primary{
                        margin-top: 0.75rem;
                    }
                }
            }
		}

		&-title {
			cursor: pointer;
			font-weight: 700;
            font-size: 16px;
			margin: 0;
			overflow: hidden;
			padding: 7px $indent__s + 30px 7px $indent__s;
			position: relative;
			word-break: break-all;
			z-index: 1;
			color: #1a1a1a;
			border-bottom: 1px solid $color__muted;
            border-radius: 0px;

			@include lib-icon-font(
				$_icon-font-content: $icon-down,
				$_icon-font-size: 28px,
				$_icon-font-position: after,
				$_icon-font-display: block
			);

			&:after {
			    position: absolute;
				right: 2px;
                top: -4px;
                color: $sparkjop_Grey;
			}

			&:hover {
				color: $filter-link-hover;

                &:after {
                  color: $sparkjop_Grey;
                }
			}

			.active > & {
				@include lib-icon-font-symbol(
					$_icon-font-content: $icon-up,
					$_icon-font-position: after
				);
			}
		}

		&-content {
			margin: 0;
			padding-top: 1rem;

			.item {
				line-height: 1.5em;
				margin: $indent__s 0;

                .label {
                    font-size: 16px;
                    color: #1a1a1a;
                }
			}

			a {
				color: $sparkjop_Grey;

				&:hover {
					text-decoration: none;
					color: lighten($color__base, 10%);
				}
				input[type="radio"] {
					-webkit-appearance: none;
					width: 0;
					height: 0;
					margin: 0;
					border: none;
				}
                label {
                    cursor: pointer;
                }
			}

			.count {
				color: #757575;
				padding-right: 5px;

				&:before {
					content: '(';
				}

				&:after {
					content: ')';
				}
			}

			.filter-count-label {
				@extend .abs-visually-hidden;
			}
		}
	}
}

.left-navigation {

    ul > li.level0:not(.has-active):not(.active) {
        //display: none !important;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            margin-left: 1rem;
            margin-bottom: 0.5rem;

            a {
                text-decoration: none;
            }

            &.active > a {
                font-weight: 600;
            }
        }
    }

    .submenu {
        display: none;
    }

    .category-item.has-active > .submenu,
    .category-item.active > .submenu,
    .category-item.open > .submenu {
        display: block;
    }
}

//
//  Mobile Portrait
//  _____________________________________________
@media screen and (max-width: 439px) {
	.filter {
        &-title strong:before {
            padding-right: 0;
        }
		&-content {
			margin: 0.5rem 0;
		}
		&-current {
			.items {
				padding: 0;
				margin-right: -0.25rem;
				.item {
					align-items: center;
					justify-content: flex-end;
				}
			}
		}
		&-actions {
			display: none;
		}
		.sidebar &:not(.active).block &-title {
			width: calc(50% - 0.5rem);
			margin-right: 0.5rem;
			strong {
				height: auto;
				padding: 1rem;
			}
		}
	}
}

//
//  Mobile Only
//  _____________________________________________
@include max-screen($screen__m) {
	.filter {
		&-title {
			strong {
				justify-content: space-between !important;
				&:after {
					display: none;
				}
			}
		}
	}
}

//
//  Mobile & tablet
//  _____________________________________________
@include max-screen($screen__l) {
	body {
		&.filter-active {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;

			.columns {
				z-index: 999;
			}
		}
	}

	.filter {
		&.block {
            display: inline-block;
            width: calc(50% - 0.5rem);
            margin-right: 0.5rem;
			margin-bottom: 1rem;

            .sidebar-main & {
                display: none;
            }
		}
		&.active {
			position: relative;
			visibility: visible;
			z-index: 102;

			.filter-options-item:last-child {
				margin-bottom: $indent__xl;
			}

			.filter-title {
				height: 50px;
				left: 0;
				position: fixed;
				right: 0;
				top: 0;
				z-index: 2;
                width: calc(100% - 50px);
                max-width: 335px;

				strong {
					box-shadow: none;
					background: $color-white;
					border: 0;
					display: flex;
					font-size: 16px;
                    border-radius: 0;

                    &:before {
                        @include material-icon($icon-close);
                    }

					&:after {
                        content: '';
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0,0,0,0.7);
                        display: block;
                        z-index: -1;
					}

                    &:hover,
                    &:focus,
                    &:active {
                        color: $sparkjop_DarkBlue;
                    }
				}
			}

			.filter-subtitle {
				background: $toolbar-background;
				display: none;
				height: 50px;
				left: 0;
				line-height: 32px;
				position: fixed;
				right: 0;
				top: 0;
				z-index: 1;
			}

			.filter-options {
				background: $color-white;
				bottom: 0;
				display: block;
				left: 0;
				overflow-y: auto;
				position: fixed;
				right: 0;
				top: 50px;
				z-index: 10;
				padding: 1rem;
                width: calc(100% - 50px);
                max-width: 335px;
			}
		}
	}

	.filter {
		& &-subtitle {
			font-size: 20px;
			font-weight: $font-weight__light;
		}

		&-actions {
			margin: -35px -$indent__s $indent__m;
		}

		&-options-content {
			padding: $indent__xs $indent__s;
		}
		&-content {
			margin: 0.5rem 0;
    		width: 100%;
		}
	}

	.filter-current {

        .filter-current-subtitle {
			position: relative;
			text-transform: uppercase;
			z-index: 1;
			margin: 0.5rem 0;
			padding: 0;
            display: none;

			@include lib-icon-font(
				$_icon-font-content: $icon-down,
				$_icon-font-size: 13px,
				$_icon-font-position: before,
				$_icon-font-display: none
			);

			&:before {
				position: absolute;
				right: 10px;
				top: -5px;
			}

			&:after {
				color: $text__color__muted;
				content: ' (' attr(data-count) ')';
				font-size: .9em;
			}
		}

		&.active {
			padding-bottom: 3rem;

			.block-subtitle {
				@include lib-icon-font-symbol(
					$_icon-font-content: $icon-up,
					$_icon-font-position: before
				);
			}

			.items {
				display: flex;
				flex-wrap: wrap;
				margin: 1rem 0 0 0;
				padding: 0;
			}

			& + .block-actions {
				display: block;
				margin: 0;
				transform: translateY(-100%);
			}
		}

        .items {
            padding: 0.5rem 0;
        }

		& + .block-actions {
			display: none;
		}

        .sidebar-main & {
            display: none;
        }
	}

	.filter-no-options {
		.filter-title {
			display: none;
		}
		.toolbar-sorter {
			flex-grow: 1;
			.sorter-options {
				line-height: 2em;
			}
		}
	}

	.page-with-filter {
		.columns {
			.sidebar-main {
				order: 0;
			}
		}
	}
}


/**
*
* Desktop
*
*/

@include min-screen($screen__l) {

	.filter {
		&.block {
			margin-bottom: $indent__xl;
		}

		&-title {
			display: none;
		}

		&-content {
			.item {
				margin: $indent__s 2px;
			}
		}

		&-actions {
			margin-bottom: $indent__l;
		}

		&.active &-options,
		&-options {
			background: transparent;
			clear: both;
			display: block;
			overflow: initial;
			position: static;
		}

		&-subtitle {
			display: none;
			position: static;
		}
        &-hide-desktop {
            display: none !important;
        }
		&-no-options {
			.filter-title {
				&:before {
					background: rgba(255,255,255,.5);
					content: '';
					display: block;
					height: 40px;
					left: -15px;
					margin-top: -60px;
					position: relative;
					width: 100px;
					z-index: 99;
				}
			}
		}
	}

    .column.main {
        .filter.block {
            margin-bottom: 0;
            display: inline-block;
            vertical-align: top;
        }
        .filter-current {
            display: block;
        }
        .filter-options-item {
            display: inline-block;
            vertical-align: top;
            padding: 0;
            position: relative;
            margin-right: 10px;

            .filter-options-title {
                display: inline-block;
                padding: 7px 14px;
                text-align: center;
                font-size: 14px;
                line-height: 16px;
                border: 2px solid $sparkjop_DarkBlue;
                border-radius: 5px;
                min-width: 175px;
                background-color: $sparkjop_White;
                transition: background 0.25s ease;
                color:$sparkjop_DarkBlue;

                &:after {
                    display: none;
                }

                &.active,
                &:hover {
                    background-color: $sparkjop_DarkBlue;
                    color: $sparkjop_White;
                    &:after {
                        color: $sparkjop_White;
                    }
                }
            }

            &:after {
                @include material-icon($icon-down);
                position: absolute;
                font-size: 24px;
                line-height: 16px;
                color:$sparkjop_DarkBlue;
                z-index: 5;
                right: 5px;
                top: 10px;
            }

            .filter-options-content {
                padding: 0.5rem;
                margin-top: 0.5rem;
                border: 1px solid $sparkjop_DarkBlue;
                border-radius: 5px;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
                display: none;
                position: absolute;
                min-width: 100%;
                left: 0;
                background-color: $sparkjop_White;
                z-index: 105;
                max-height: 435px;
                overflow-y: auto;

                .field.search {
                    margin-bottom: 0.5rem;
                }

                .item {
                    margin: 0;
                    a {
                        display: block;
                        padding: 0.4rem 0.5rem;
                        border-radius: 5px;

                        &:hover {
                            color: $sparkjop_DarkBlue;
                            background-color: transparentize($sparkjop_DarkBlue, 0.75);
                        }
                    }
                }

                .actions {
                    margin: 0.5rem;
                    .action {
                        cursor: pointer;
                        width: 100%;
                    }
                }
            }

            .mage-dropdown-dialog + .filter-options-content {
                display: block;
            }
        }
    }

	.page-layout-1column {
		.toolbar-products {
			position: absolute;
			top: 0;
			width: 100%;
		}

		.products ~ .toolbar-products {
			position: static;
		}

		&.page-with-filter .column.main {
			padding-top: 45px;
			position: relative;
			z-index: 1;
		}

		.filter {
			&.block {
				border-top: 1px solid $border-color__base;
			}

			&-content {
				margin-top: $indent__s;
			}

			&-subtitle {
				display: none;
			}

			&-options {
				&-item {
					border: 0;
					display: inline-block;
					margin-right: $indent__m;
					position: relative;

					&.active {
						z-index: 2;

						.filter-options-content {
							visibility: visible;
						}

						&:hover {
							z-index: 3;
						}

						&:after,
						&:before {
							@include lib-arrow(up, 8px, $color-black);
							bottom: -1px;
							content: '';
							display: block;
							left: $indent__xs;
							position: absolute;
							z-index: 3;
						}

						&:after {
							border-bottom-color: $color-white;
							margin-top: 2px;
							z-index: 4;
						}
					}
				}

				&-title {
					padding: 0 $indent__base 0 0;

					&:after {
						right: 2px;
						top: 3px;
						z-index: 3;
					}
				}

				&-content {
					$_shadow: 0 3px 5px 0 rgba(50, 50, 50, .75);

					background: $color-white;
					box-shadow: $_shadow, 1;
					border: 1px solid $border-color__base;
					padding: $indent__xs 0;
					position: absolute;
					top: 100%;
					visibility: hidden;
					width: 180px;
					z-index: 2;

					.item {
						margin: 0;
						padding: $indent__xs;

						a {
							margin-left: 0;
						}

						&:hover {
							background-color: $color-gray91;
						}
					}
				}
			}

			&-current {
				display: inline;
				line-height: 35px;

				&-subtitle {
					color: $text__color__muted;
					display: inline;
					font-size: $font-size__base;
					font-weight: normal;
					padding: 0;

					&:after {
						content: ':';
					}
				}

				.item,
				.items {
					display: inline;
				}

				.item {
					margin-right: $indent__m;
					white-space: nowrap;
				}

				.action.remove {
					line-height: normal;
				}
			}

			&-actions {
				display: inline;
				white-space: nowrap;

				& ~ .filter-options {
					margin-top: $indent__m;
				}
			}
		}
	}
}
