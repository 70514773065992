/* Modal popup */
.modal-popup .modal-inner-wrap {
    max-width: 600px;

    #stock-modal {
        .stock-wrapper {
            background: $sparkjop_Gray;
            height: 1.5em;
            color: $sparkjop_Red;

            .stock-bar {
                background: $sparkjop_Green;
                text-align: center;
                color: $sparkjop_White;
                height: 100%;
                padding-top: 1px;
            }
        }
        .product-title, .stock-inventory {
            font-size: 1.2em;
            font-weight: bold;
        }
        .store-name {
            .shop-select {
                margin: 0 0 20px;
            }
        }
    }
    .modal-header {
        padding: 1rem;
        .action-close{
            z-index: 9;
            padding: 17px 11px;
        }
    }
    .modal-content{
        .block-authentication{
            border-top: 0;
            .actions-toolbar{
                .primary{
                    padding-top: 0.25rem;
                    .action{
                        @include sparkjop-button();
                    }
                }
                .secondary{
                    display: flex;
                    align-items: baseline;
                }
            }
        }
    }
    .modal-footer button {
        @include sparkjop-button();
    }
}

// Mobile
@include max-screen($screen__m){
    body._has-modal{
        height: 100%;
        overflow: hidden;
        position: relative;
    }
    .modal-popup.modal-slide[data-role="modal"]{
        position: fixed;
        left: 0;
        top: 0;
        background: $sparkjop_White;
        overflow: hidden;
        .modal-inner-wrap{
            background: $sparkjop_White;
            .modal-header{
                padding: 0;
                .modal-title {
                    padding: 1.7rem 1rem;
                    font-size: 20px;
                }
            }
            .modal-content{
                padding: 1.5rem 2rem;
            }
            .block-title{
                font-size: 21px;
            }
            .actions-toolbar{
                .primary{
                    margin-top: 1.25rem;
                }
                .secondary{
                    padding-top: 1.25rem;
                }
                .action{
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
