/*
*
* Common Product View Extensions
* 
*/

.product-info-main {

    .product-reviews-summary {
        padding: 1rem 0;
    }

    .bundle-actions {
        margin-top: 1.5rem;
    }

}

.product-info-main .box-tocart, 
.product-options-bottom .box-tocart {
    width: 100%;

    .input-text.qty {
        border-radius: $polarcore-product-input-text-qty__border-radius;
    }

    .fieldset,
    .actions {
        display: flex;
        width: 100%;
    }

    .actions .action.primary.tocart {
        width: 100%;

        span:before {
            @include material-icon($icon-cart);
        }

    }

    
    .field.qty {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: auto;

        .label {
            display: none;
        }

    }

}

.product-social-links {

    .action.towishlist {

        span {
            display: flex;
            justify-content: center;
            align-items: center; 
        }

        span:before {
            @include material-icon($icon-wishlist);
            font-size: 1em !important;
            transform: scale(1.5);
            padding-right: 0.5rem;
        }

    }

}

.actions .action.primary.tocart {

    span {
        display: flex;
        justify-content: center;
        align-items: center; 
    }

    span:before {
        @include material-icon($icon-cart);
        font-size: 1em !important;
        transform: scale(1.75);
        padding-right: 0.5rem;
    }

}

.product.info.detailed {

    .data.item.title {
        margin: 0;
        margin-top: 1rem;
        border: 1px solid $border-color__base;

        .switch {
            position: relative;
        }

        .switch:after {
            @include material-icon($icon-down);
            position: absolute;
            right: 1rem;
            top: 1rem;
            bottom: auto;
            left: auto;
            z-index: 10;
        }

        &.active {
            border-bottom: 0;
            .switch:after {
                transform: rotate(180deg);
            }
        }

    }

    .data.item.content {
        border: 1px solid $border-color__base;
        border-top: 0;
    }

    .review-add,
    #product-review-container {
        padding: 1rem;
        width: 100%;
    }

}

/*
*
* Mobile only
* 
*/

@include max-screen($screen__m) {
    .product.info.detailed {
        #reviews {
            flex-direction: column;
        }
    }
    
}
