.swatch-attribute-label {
    font-weight: 100;
    position: relative;
    margin: 1rem 0 0 0;
    display: inline-flex;
    font-size: 17px;

    &.required {
        padding-right: 10px;
    }

    &[data-required="1"]::after {
        content: '*';
        color: red;
        position: absolute;
        right: -11px;
        top: -2px;
        font-weight: bold;
        font-size: 1em;
    }
}

.swatch-attribute-selected-option {
    color: initial;
    padding-left: 9px;
    font-weight: 600;
    font-size: 17px;
}

.swatch-attribute-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.swatch-opt {
    margin: 0;
}
.swatch-option {
    padding: 1px 2px;
    min-width: 30px;
    max-width: 90px;
    height: 20px;
    margin: 0 10px 5px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    border: 1px solid rgb(218, 218, 218);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: box-shadow 0.25s ease;

    &.text {
        box-sizing: content-box;
        background: #f9f9f9;
        border: 1px solid #f0f0f0;
        color: #7f7f7f;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        padding: 4px 4px;
        min-width: 22px;
        margin-right: 7px;
        border-radius: 2px;
    }

    &.selected {
        border: $polarcore-swatch-option-selected__border;
        color: #333;
    }

    &.text.selected {
        background-color: #fff;
    }

    &:not(.disabled):hover {
        border: 1px solid #f0f0f0;
        color: #333;
    }

    &.color,
    &.image {
        $swatch-size: 82px;
        width: $swatch-size;
        height: $swatch-size;
        min-width: $swatch-size;
        max-width: $swatch-size;
        max-height: $swatch-size;
        min-height: $swatch-size;
        border: 2px solid #f5f6fc;
        border-radius: 50%;
        &.selected {
            border: 2px solid #f5f6fc;
            color: #333;
        }
        &:not(.disabled):hover {
            outline: 0;
            border: 2px solid #f5f6fc;
            opacity: 0.8;
        }
        .filter-current & {
            display: none;
        }
        .filter-options & {
            width: 100%;
            height: 100%;
            min-height: unset;
            max-height: unset;
            min-width: unset;
            max-width: unset;
            border-radius: 50%;
            margin: 0;
            &,
            &:hover {
                border: 0;
            }
            & ~ .am-shopby-swatch-label {
                //display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: white;
                padding: 0 0.4rem;
                font-size: 0.8em;
                border-radius: 0 0 5px 5px;
                height: 50%;
                & span.label, & span.count {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    font-size: 11px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    &.color {
        .filter-options &:before {
            content: '';
            border-width: 0;
            border-style: solid;
            border-color: white;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            border-radius: 50%;
            position: absolute;
            left: 4px;
            top: 4px;
            transition: all 0.3s ease;
        }

        .filter-options &.selected:before {
            border-width: 3px;
        }
    }

    &.disabled {
        display: none;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
                to left top,
                rgba(255, 255, 255, 0) 48%,
                $sparkjop_Grey3 49% 51%,
                rgba(255, 255, 255, 0) 52%
            );
        }
    }
}

.product-options-wrapper .swatch-attribute.level_1 .swatch-option[option-empty="true"] {
    display: none;
}

.swatch-attribute {

    // Color/Variant Swatches
    &.level_1 {
        padding-bottom: 0.25rem;
        background: $sparkjop_White;

        .swatch-option {
            min-width: 50px;
            max-width: unset;
            min-height: 80px;
            max-height: unset;
            border-radius: 7px;
            border: 1px solid $sparkjop_CodGrey;

            &:not(.disabled):hover {
                opacity: 1;
                border: 1px solid $sparkjop_CodGrey;
            }

            &.selected {
                background: $sparkjop_White;
                box-shadow: 0 0 0 2px $color__action;
            }
        }

        .swatch-attribute-options{
            > div:last-of-type{
                margin-right: 0;
            }
        }
    }

    &.manufacturer,
    &.level_2 {
        .swatch-option {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: calc(5rem + 0.25rem);
            border-radius: 20px;
            background-color: $sparkjop_White;
            color: $sparkjop_CodGrey;
            border: 1px solid $sparkjop_CodGrey;
            font-size: 14px;
            line-height: 1;
            padding: 0.5rem;
            margin: 0rem 0.6rem 0.6rem 0rem;
            font-weight: 400;

            &.selected {
                color: $sparkjop_White;
                background: $sparkjop_DarkBlue2;
                border: 1px solid $sparkjop_DarkBlue2;
                font-size: 14px;
                font-weight: 600;

                .products-grid & {
                    box-shadow: none;

                    &:before {
                        font-size: 0.9em;
                        font-weight: normal;
                    }
                }
            }

            &.disabled {
                background-color: $sparkjop_WhiteGrey4;
                border: 1px solid $sparkjop_Grey3;
                opacity: 0.6;
            }

            &.selected.disabled {
                color: $sparkjop_CodGrey;
                border: 1px solid $sparkjop_DarkBlue2;

                &:after {
                    background: linear-gradient(
                            to left top,
                            rgba(255, 255, 255, 0) 48%,
                            $sparkjop_DarkBlue2 49% 51%,
                            rgba(255, 255, 255, 0) 52%
                    );
                }
            }
        }
    }
    
    .filter-options & {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        &.manufacturer,
        &.level_2 {
            .swatch-option {
                min-width: unset;
                min-height: unset;
                width: 1.75rem;
                height: 1.75rem;
                padding: 0.85rem;
				font-weight: normal;
                line-height: 0;
                border: 1px solid $color__muted;
            }
        }
        &.color {
		    justify-content: space-around;
			a.am-swatch-link {
				width: 30%;
                height: 6.5rem;
                margin: 0.5rem 0.25rem 0 0.25rem !important;
			}
        }
        .swatch-option-link-layered {
			display: flex;
			flex-direction: column;
            margin: 0.5rem 0.5rem 0 0 !important;
            width: 3.75rem;
            height: 3.75rem;
            .swatch-option {
                border-radius: 50%;
                box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
				&.color {
                    border-radius: 0;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
                }
                &.text {

                }
            }
			input.input-hidden {
                display: none;
            }
        }
    }
}

.swatch-opt {
    margin: 20px 0 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

body.polarcore.page-products.catalog-category-view {
    .swatch-option-tooltip {
        pointer-events: none;
    }
    .polarcore__details-wrapper {
        .swatch-attribute.level_2 {
            pointer-events: none;
        }
    }
}

.swatch-option-tooltip {
    .image {
        display: none !important;
    }
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}
.swatch-option-loading {
    content: url(../Magento_Swatches/images/loader-2.gif);
}

// Magento 2.2 fix input
// https://github.com/magento/magento2/blob/2.2.0/app/code/Magento/Swatches/view/frontend/web/css/swatches.css

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}

// Mobile
@include max-screen($screen__m) {
    .swatch-attribute {
        .filter-options & {
            margin-left: -0.5rem;
        }
    }
}
