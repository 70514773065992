/**
*
* PolarCore Grid/Listings enhancements
*
*/
.block-products-list {
    margin-top: -40px;
}

.products {
    margin: 0;
    &-grid {
        .slick-prev {
            height: 42px;
            width: 42px;
            left: 0;
        }

        .slick-next {
            height: 42px;
            width: 42px;
            right: 0;
        }

        .product-item-details {
            .brand-filler {
                height: 20px;
            }

            .brand-name {
                padding: 0 0.5rem;
                margin-bottom: 0.25rem;
                font-size: 14px;
                color: $color__black;
                font-weight: 700;
                font-style: italic;
            }

            .product-item-name {
                margin: 0.5rem 0 0 0;
                padding: 0rem 1rem;
                font-weight: normal;
                font-size: 1em;
            }

            .price-box {
                margin: 0;
                justify-content: center;
                flex-direction: row-reverse;
                align-items: center;
                .price {
                    font-size: 24px;
                    font-weight: bold;
                    padding: 0;
                    color: $sparkjop_CodGrey;
                }
                .minimal-price {
                    .price {
                        color: $color__action;
                    }
                }
                .special-price {
                    .price {
                        color: $sparkjop_special-price__color;
                    }
                }
                .old-price {
                    margin-right: 0.5rem;

                    .price {
                        color: $sparkjop_old-price__color;
                        font-size: 16px;
                        font-weight: 100;
                    }
                }
            }

            &__top {
                display: flex;
                flex-direction: column;
                transform: translateY(0);
                width: 100%;
            }

        }

        .product-item {
            border-radius: 5px;
            border: 0;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            margin-bottom: 1rem;
            flex-direction: column;
            padding: 0;
            position: relative;
            &,
            &:hover {
                box-shadow: 0px 1px 5px rgba(0,0,0,0.2);
            }
            &.has-options {
                .product-item-info {
                    padding: 0;
                }
                &:hover{
                    z-index: 9;
                    .product-item-details{
                        display: block;
                        &__top {
                            html:not(.isTouch) & {
                                background: $sparkjop_White;
                                opacity: 1;
                                z-index: 2;
                                visibility: visible;
                            }
                        }
                        .polarcore__details-wrapper {
                            html:not(.isTouch) & {
                                height: auto;
                                overflow: visible;
                                display: block;
                                max-height: 500px;
                                top: 6.75rem;
                                opacity: 1;
                                transition: all 450ms ease, opacity 100ms;
                                box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
                            }
                            // Swatches take some extra time to load, so we hide the hover effect until they're loaded
                            body[data-mage-init] &{
                                display: none;
                            }
                        }
                    }
                }
            }
            &-info {
                padding: 1rem;
                background-color: $color__white;
                border-radius: 5px 5px 0 0;
            }
            &-details{
                display: flex;
                flex-flow: column;
                padding-top: 0.5rem;
                z-index: 100;
            }
            &-details__bottom {
                background-color: $color__primary;
                padding: 0.5rem 1rem;
                margin-top: auto;
                border-radius: 0px 0px 5px 5px;
                z-index: 1;
                bottom: 0;
                width: 100%;
                &.visible-on-item-hover{
                    visibility: visible;
                }
                .availability {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & > * {
                        display: flex;
                        align-items: center;
                    }
                    i {
                        font-size: 1em;
                        margin-right: 0.5rem;
                    }
                    .available i {
                        color: $color__success;
                    }
                }
            }
            .polarcore__details-wrapper {
                html:not(.isTouch) & {
                    width: 100%;
                    max-height: 0;
                    opacity: 0;
                    border-radius: 5px;
                    overflow: hidden;
                    position: absolute;
                    top: 0rem;
                    transition: all 350ms ease, opacity 1ms ease 349ms, top 1ms ease 349ms;
                }
                div[class*="swatch-opt"]{
                    background-color: $sparkjop_White;
                }
            }
        }

        .product-item-actions {
            display: flex;
            justify-content: center;
            align-items: center;

            .actions {
                &-primary {
                    text-align: center;
                }

                &-secondary {
                    display: none;
                }
            }
        }

        .product-item:hover {

            .product-item-inner {
                display: block;
            }

        }

        .product-item-inner {
            display: none;
            .swatch-attribute-options {
                justify-content: center;
            }
        }

        .product-item-actions {
            width: 100%;
        }
    }
}

.block.widget {

    .product-item-details {
        padding: 1rem;
    }

}

.products-list .product-item-details {
    text-align: left;

    .product-item-actions {
        display: flex;

        .actions-primary + .actions-secondary {
            width: auto;
            margin-left: 1rem;
            margin-right: auto;
        }
    }
}

//
//  Mobile Portrait
//  _____________________________________________
@media screen and (max-width: 439px) {

    .block-products-list {
        margin-top: -50px;
    }

    .page-products {
        .products-grid {
            .product-item {
                margin: 0.5rem;
                width: calc((100% / 2) - 1rem);
                &:first-child:nth-last-child(1) {
                    width: 100%;
                }
                &-info {
                    padding: 0.5rem;
                }
                &-photo {
                    .tw-productlabels {
                        left: calc(-0.5rem - 5px);
                    }
                }
            }
        }
    }
}

//
//  Mobile Landscape
//  _____________________________________________
@media screen and (min-width: 440px) and (max-width: 639px) {
    .page-products {
        .products-grid {
            .product-item.product {
                width: calc((100% / 2) - 1rem);
                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}
//
//  Tablet Portrait
//  _____________________________________________
@media screen and (min-width: 640px) and (max-width: 767px) {
    .page-products {
        .products-grid {
            .product-item.product {
                width: calc((100% / 3) - 1rem);
                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}
//
//  Tablet Landscape + Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item.product {
                width: calc((100% / 4) - 1rem);
                &:not(.slick-slide):nth-of-type(4n) {
                    margin-right: 0;
                }
            }
        }
    }

    html.isTouch .products-grid .product-items .product-item .polarcore__details-wrapper .product-item-details__bottom {
        display: none;
    }
}
