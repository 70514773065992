body {
    #ajaxcart-loading {
        .status-bar,
        .inner {
            max-width: 600px;
            p {
                font-weight: 700;
                margin-bottom: 20px;
            }
        }
    }
    #ajaxcart {
        .inner {

            & > * {
                flex-shrink: 0;
            }

            max-width: 650px;
            .nosto-wrapper{
                .nosto-title{
                    text-align: center;
                }
                .products-grid{
                    .product-item-info{
                        padding: 0rem;
                    }
                }
            }
        }
        .button-close-top {
            display: flex;
            align-items: center;
            padding: 12px;
            i {
                font-size: 30px;
            }
        }
        .success {
            background: $sparkjop_LightBlue;
            color: $sparkjop_CodGrey;
            font-size: 20px;
        }
        .inner{
            .product {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-name {
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0;
                    text-align: center;
                }
                .label.qty{
                    display: none;
                }
                .options {
                    display: none;
                }
                .price-box {
                    text-align: center;

                    .minicart-price{
                        display: flex;
                        justify-content: center;
                        align-items: baseline;
                        margin: 1rem;
                        span.price {
                            font-size: 20px;
                        }

                        .special-price{
                            .price{
                                color: $sparkjop_special-price__color;
                                font-size: 20px;
                            }
                        }

                        .old-price {
                            margin-right: 0.8rem;
                            color: $sparkjop_old-price__color;
                            span.price {
                                font-size: 16px;
                                font-weight: 400;
                                color: $sparkjop_old-price__color;
                            }
                        }

                        .price-suffix {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .block.related {
            width: 100%;
            padding: 10px 80px;
            background: #f9f9f9;
            margin: 0;
            .block-title {
                text-align: center;
                strong {
                    font-family: $font-family__base;
                    font-size: 16px;
                    color: $sparkjop_CodGrey;
                    margin: 0.5rem 0 0 0;
                    display: block;
                }
            }
            .products-grid {
                .product-item {
                    margin: 0 1rem 1rem 0.5rem;
                }
            }
            .actions {
                background: transparent;
                padding: 0;
                margin: 0;
                .actions-primary {
                    padding: 0;

                    .tocart {
                        min-width: auto;
                    }

                    span {
                        padding: 8px;
                        font-size: 12px;
                    }
                }
            }
            .slick-arrow {
                &.slick {
                    &-prev,
                    &-next {
                        &:before {
                            background: $sparkjop_LightGrey;
                            border-radius: 5px;
                        }
                    }
                    &-prev {
                        margin-left: -25px;
                    }
                    &-next {
                        right: 0;
                        margin-right: -20px;
                    }
                }
            }
        }
        .actions {
            #button-close {
                @include sparkjop-button();
                width: 40%;
                display: flex;
                justify-content: center;
                margin: 0 10px;
                padding: 0 0.5rem;
                align-items: center;
                height: 40px;
            }
            #button-cart,
            #button-checkout {
                @include sparkjop-button-cta();
                width: 40%;
                display: flex;
                justify-content: center;
                margin: 0 10px;
                padding: 0 0.5rem;
                align-items: center;
                height: 40px;
                font-weight: 700;
            }
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    body {
        #ajaxcart {
            .inner {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                left: 1rem;
                top: 1rem;
                margin: 0 auto;
                transform: none;
                max-width: 92%;
                > .product {
                    padding: 1rem 0 0 0;
                }
            }
            .product {
                display: flex;
                flex-direction: initial;
                justify-content: center;
                align-items: flex-start;
                flex-wrap: wrap;
                flex-grow: 1;
                width: 100%;
            }

            .success {
                font-size: 16px;
            }
            .button-close-top {
                padding: 10px 12px;
            }
            .actions {
                flex-direction: initial;
                justify-content: space-evenly;
                padding: 1rem 0;
                #button-close{
                    width: 32%;
                    margin: 0;
                }
                #button-cart{
                    width: 48%;
                    font-weight: 500;
                    margin: 0;
                }
            }
            .block.related {
                padding: 10px 50px 0px 50px;
                .slick-list {
                    padding: 0 15% 0 0;
                }
                .slick-arrow {
                    &.slick {
                        &-prev,
                        &-next {
                            background: #ebebeb;
                            border-radius: 5px;
                        }
                        &-prev {
                            left: 0;
                            margin-left: -46px;
                        }
                        &-next {
                            right: 0;
                            margin-right: -20px;
                        }
                    }
                }
                .product {
                    &-item-info {
                        padding: 0 14px 14px 14px;
                    }
                    &-image-container {
                        max-width: 90px;
                        max-height: 90px;
                    }
                    .price-box {
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
        #ajaxcart-loading {
            .status-bar,
            .inner {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}

// Desktop only
@include min-screen($screen__l) {
    body {
        #ajaxcart {
            .products-grid{
                .slick-prev{
                    left: 0;
                }
                .slick-next{
                    right: 0;
                }
            }
            .block.related {
                .slick-arrow {
                    &.slick-prev,
                    &.slick-next {
                        top: 45%;
                    }
                }
            }
        }
    }
}
