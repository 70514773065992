.stock-filter-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .stock-filter-element {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        .stock-filter-text {
            margin-right: 1rem;
            text-transform: uppercase;
            color: $sparkjop_Grey;
            font-weight: 700;
        }
    }
}

.stock-filter-toggle {
    isolation: isolate;
    position: relative;
    height: 26px;
    width: 50px;
    border-radius: 15px;
    background: #d6d6d6;
    overflow: hidden;
}

.stock-filter-toggle-inner {
    z-index: 2;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 22px;
    width: 46px;
    border-radius: 15px;
    overflow: hidden;
}

.stock-filter-active-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background: $sparkjop_Green;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.05s linear 0.05s;
}

.stock-filter-checkbox {
    display: none;
}

.stock-filter-indicator {
    height: 100%;
    width: 200%;
    background: white;
    border-radius: 13px;
    transform: translate3d(-75%, 0, 0);
    transition: transform 0.1s cubic-bezier(0.420, 0.000, 1.000, 1.000);
}

.stock-filter-checkbox:checked ~ .stock-filter-active-bg {
    transform: translate3d(-50%, 0, 0);
}

.stock-filter-checkbox:checked ~ .stock-filter-toggle-inner .stock-filter-indicator {
    transform: translate3d(25%, 0, 0);
}
