.cms-no-route {
    .page-title-wrapper {
        display: none;
    }
    .page-main {
        margin: 0;
        padding: 0 !important;
        width: 100%;
        max-width: 100%;
    }
    .columns .column.main {
        padding: 0;
        width: 100%;
    }
    .no-route-wrapper {
        background-image: url(../images/404.jpeg);
        background-size: cover;
        background-position: bottom;
        padding: 4rem 0 6rem 0;
        width: 100%;
        max-width: $layout__max-width;
        min-height: 600px;
        margin: auto;
        .content {
            margin-left: auto;
            width: 30%;
            padding: 0 0 2rem 0;
        }
        p {
            margin: 1rem 0;
        }
        a {
            @include sparkjop-button-cta;
            margin-top: 1rem;
            color: #fff;
            text-decoration: none;
            &:visited {
                color: #fff;
            }
        }
    }
}

@include max-screen($screen__m) {
    body:not(.cms-index-index) {
        main.page-main {
        }
        .page-footer {
            margin-top: 0;
        }
    }
    .cms-no-route {
        .page-main {
            padding: 0 !important;
        }
        .no-route-wrapper {
            max-height: 300px;
            min-height: initial;
            padding: 0.5rem 0 0rem 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: -70px 0px;
            background-color: #f9f9f9;
            .content {
                margin-left: auto;
                width: 70%;
                padding: 14px 20px 30px 40px;
                h1 {
                    font-size: 18px;
                    &:last-of-type {
                        margin-top: 16px;
                    }
                }
                p {
                    margin: 1rem 0 0 0;
                }
            }
        }
    }
}
@include screen($screen__m, ($layout__max-width - $polarcore-header-breakpoint__offset)) { 
    .cms-no-route {
        .no-route-wrapper {
            .content {
                margin-left: auto;
                width: 40%;
                padding: 0 30px 2rem 0px;
            }
        }
    }
}
