$sparkjop_White: #ffffff;
$sparkjop_LightBlue: #edf1ff;
$sparkjop_footer: #d5dae8;
$sparkjop_DarkBlue: #2c448e;
$sparkjop_DarkBlue2: #2d468c;
$sparkjop_Orange: #ffb03b;
$sparkjop_Yellow: #ffea00;
$sparkjop_Black: #000000;
$sparkjop_Gray: #d1d1d1;
$sparkjop_Red: #e80c00;
$sparkjop_Red2: #E02B27;
$sparkjop_Red3: #FAE5E5;
$sparkjop_Green: #40c353;
$sparkjop_Grey: #595959;
$sparkjop_Grey2: #5e5e5e;
$sparkjop_Grey3: #474747;
$sparkjop_LightGrey: #a3a3a3;
$sparkjop_LightGrey2: #aaaaaa;
$sparkjop_WhiteGrey: #f9f9f9;
$sparkjop_WhiteGrey2: #eaeaea;
$sparkjop_WhiteGrey3: #e5e5e5;
$sparkjop_WhiteGrey4: #f5f5f5;
$sparkjop_CodGrey: #1a1a1a;
$sparkjop_input-border__color: $sparkjop_LightGrey;
$sparkjop_special-price__color: $sparkjop_Black;
$sparkjop_old-price__color: $sparkjop_Grey;
