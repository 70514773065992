body {
    #search_autocomplete {
        max-height: calc(100vh - 150px);
        overflow-y: auto;
    }

    .block-search {
        .form.minisearch {
            .search-autocomplete {
                .smile-elasticsuite-autocomplete-result {
                    dl.autocomplete-list {
                        dd {
                            cursor: pointer;
                            margin-bottom: 5px;
                            padding: 5px;

                            &:hover {
                                background-color: $color__primary;
                            }

                            &.categories {
                                span.category-mini-crumb {
                                    padding-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
