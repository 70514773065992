.block {
    &.newsletter {
        button {
            white-space: nowrap;
        }
    }
}
.page-footer {
    color: $color__base;
    background-color: #d5dae8;

    .footer.content {
        background-color: #d5dae8;
    }
    .block-static-block{
        margin: 0;
    }
    .content {
        .block.newsletter {
            background-color: white;
            padding: 1rem;
            border-radius: 1.5rem;
            box-shadow: 0 0 4px rgba(0,0,0,0.2);
            flex-grow: unset;
            @include min-screen($screen__l) {
                width: 40%;
            }
            .title {
                margin-bottom: 1rem;
                text-align: center;
                strong {
                    color: black;
                    text-transform: none;
                    font-size: 1.4em;
                }
            }
            .form.subscribe {
                position: relative;
                input#newsletter, input#mce-EMAIL {
                    border-radius: 2rem;
                    height: 44px;
                    padding-left: 1rem;
                }
                .control:before {
                    display: none;
                }
                .actions {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    width: auto;
                    button.subscribe {
                        background-color: transparent;
                        color: $color__action;
                        border: none;
                        span {
                            display: none;
                        }
                        &:after {
                            content: '\f1d8';
                            font-family: "Font Awesome 5 Free";
                            font-size: 1.5rem;
                            transform: rotateX(45deg);
                        }
                    }
                }
            }

            #mc_embed_signup div.mce_inline_error, #mce-error-response, #mce-success-response {
                padding: 5px 10px;
                font-weight: bold;
                z-index: 1;
            }
            #mc_embed_signup input.mce_inline_error {
                border-color: #6B0505;
            }
            #mc_embed_signup div.mce_inline_error, #mce-error-response {
                color: #6B0505;

                a {
                    color: inherit;
                    font-weight: inherit;
                }
            }
            #mce-success-response {
                color: green;
            }
        }
        .payment-block {
            img {
                min-width: 35px;
            }
            .wpb_single_image {
                img {
                    width: 100%;
                    max-width: 70px;
                }
            }
        }
        h3 {
            font-family: $font-family__base;
            font-size: 1.5em;
            width: max-content;
            &:after {
                content: '';
                display: block;
                width: auto;
                margin-top: 1rem;
                border-bottom: 2px solid $color__action;
            }
        }
        a {
            color: $sparkjop_CodGrey;
            font-size: 1em;
            font-weight: 400;
            line-height: 2em;
            &:before {
                display: none;
            }
        }
        li {
            list-style: none;
            a {
                font-size: 1.2em;
            }
        }
        i[class*="fa"] {
            vertical-align: middle;
            font-size: 2em;
            color: $color__action;
            &:before {
                vertical-align: middle;
            }
        }
        p {
            font-size: 1.1em;
        }
        .trygg-logo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .info-icon {
            display: flex;
            align-items: center;
            img {
                width: auto;
            }
        }
        @include min-screen($screen__m) {
            .general-links {
                padding-left: 3rem;
            }
        }
        .full-width {
            .wpcloud-root {
                width: 100%;
                margin: 0 auto;
            }
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    .page-footer {
        .content {
            & > * {
                padding: unset;
            }
            a{
                font-size: 14px;
            }
            .wpcloud-root {
                margin: 0 auto;
            }
            .block.newsletter {
                width: 100%;
                width: -webkit-stretch;
                margin-bottom: 1rem;
            }

            .general-links {
                text-align: right;
                &,
                h3,
                h3:after {
                    margin-left: auto;
                }
            }
            .information-block {
                &,
                h3,
                h3:after {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
                .wpcmdl-grid {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .info-icon {
                    width: auto;
                    margin: 1.5rem 0 0 0;
                    & ~ .wpcmdl-cell {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
            .payment-block,
            .social-links {
                * {
                    text-align: center;
                }
                h3 {
                    display: none;
                }
                .wpb_text_column + .wpcmdl-grid > * {
                    width: calc((100% / 4) - 16px);
                }
            }
            .social-links {
                order: -1;
            }
        }
    }
}

// Tablet

@include screen($screen__m, $screen__l) {
    .page-footer {
        .content {
            .info-icon {
                width: 15%;
                margin: 0;
                & ~ .wpcmdl-cell {
                    width: calc(85% - 0.5rem);
                    margin-left: 0.5rem;
                    margin-right: 0;
                }
            }
            .block.newsletter {
                width: 55%;
            }
            .wpcloud-root {
                padding: 0 1vw 0 5vw;
            }
        }
    }
}
