//  Layout
$layout-column__sidebar-width: 2.5;
$_breadcrumbs-container-margin: 0;
$layout-indent__width: 1rem;
$layout__max-width: 1440px;

// Colors
$color__primary: $sparkjop_LightBlue !default;
$color__action: $sparkjop_DarkBlue !default;
$color__accent: $sparkjop_Yellow !default;
$color__cta: $sparkjop_Orange !default;
$color__base: $sparkjop_Black !default;
$color__muted: $sparkjop_Gray !default;
$color__success: $sparkjop_Green !default;
$color__danger: $sparkjop_Red !default;
$color__white: #ffffff !default;
$color__black: #000000 !default;
$color__vipps: #F75B24 !default;

// Trollweb labels
$tw-labels-discount__color: $color__base !default;
$tw-labels-discount__background: $color__danger !default;
$tw-labels-new__color: $color__action !default;
$tw-labels-new__background: $color__primary !default;

// General
$font-family__base: "Muli", Helvetica, sans-serif;
$font-family__headers: "Bitter", serif;
$text-color: $color__base;
$border-color__base: $color__muted;
$footer__background-color: $color__primary;
$footer-links-color: $color__base;

//  Navigation
$navigation__level0-item-line-height: 47px;
$navigation__level0-item-font-size: 15px;

// Links
$product-name-link__color: $color__base !default;
$product-name-link__color__active: $color__base !default;
$product-name-link__color__hover: $color__base !default;
$product-name-link__color__visited: $color__base !default;
$polarcore-link__color: $color__action;
$polarcore-link__color-hover: lighten($polarcore-link__color, 5%);

// Default Button
$polarcore-button__color: $color__action;
$polarcore-button__background: $color__white;
$polarcore-button__background-hover: darken($polarcore-button__background, 5%);
$polarcore-button__background-active: lighten($polarcore-button__background, 5%);
$polarcore-button__border-width: 2px 2px 2px 2px;
$polarcore-button__border-style: solid;
$polarcore-button__border-color: darken($polarcore-button__color, 10%);

// Primary Button
$polarcore-primary-button__color: $color__white;
$polarcore-primary-button__background: $color__action;
$polarcore-primary-button__border-color: $polarcore-primary-button__background;
$polarcore-primary-button__background-hover: darken($polarcore-primary-button__background, 5%);
$polarcore-primary-button__background-active: lighten($polarcore-primary-button__background, 5%);

// Cart Button
$polarcore-cart-button__color: $color__white !default;
$polarcore-cart-button__background: $color__cta;
$polarcore-cart-button__border-color: $polarcore-cart-button__background;
$polarcore-cart-button__background-hover: darken($polarcore-cart-button__background, 5%);
$polarcore-cart-button__background-active: lighten($polarcore-cart-button__background, 5%);

// Ratings
$icon-star: '\E83A';
$rating-icon__color: #F0F0F0;
$rating-icon__active__color: #fdb42b;

// PolarCore Tabs mode
$polarcore-tabs__mode: 'horizontal';
