
// Desktop
@include min-screen($screen__l + 1px) {
    .navigation {
        ul {
            padding: 0;

            li {
                position: relative;

                &.level0 {
                    a {
                        font-size: 18px;

                        &.level-top {
                            padding: 0.75rem;
                            color: #1a1a1a;
                            line-height: 1;
                            border-radius: 5px;

                            &:after {
                                content: "";
                                width: 105%;
                            }
                        }
                    }

                    &.level-top {
                        padding: 0 0.5rem;

                        &:first-of-type {
                            padding-left: 0;
                        }

                        &:last-of-type {
                            padding-right: 0;
                        }
                    }

                    &.active {
                        .level-top {
                            font-weight: 500;
                        }
                    }

                    &.menu-brands > ul {
                        display: none !important;
                    }

                    &:hover > a,
                    a.ui-state-active {
                        color: #ffffff;
                        background-color: $sparkjop_DarkBlue;
                    }

                    // Submeny styling
                    .submenu {
                        padding: 0.75rem 0;
                        margin-top: -0.75rem;
                        border-radius: 5px;
                        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
                        border: 1px solid $sparkjop_DarkBlue;

                        li {
                            padding: 0 0.75rem;

                            &.parent {
                                > a {
                                    &:after {
                                        content: "keyboard_arrow_right" !important;
                                        font-size: 22px;
                                        font-weight: normal;
                                        line-height: 16px;
                                        text-shadow: none;
                                        color: $sparkjop_DarkBlue;
                                    }
                                }
                            }

                            &:hover {
                                > a {
                                    color: $sparkjop_DarkBlue;
                                    text-shadow: 0 0 0.01px $sparkjop_DarkBlue, 0 0 0.1px $sparkjop_DarkBlue, 0 0 0.1px $sparkjop_DarkBlue;
                                    background-color: transparentize($sparkjop_DarkBlue, 0.75);
                                }
                            }

                            a {
                                padding: 0.5rem;
                                font-size: 16px;
                                line-height: 1;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-obfuscator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: background-color 0.25s ease;
    will-change: background-color;

    &.fade-in {
        background-color: rgba(0, 0, 0, 0.35);
    }
}
