.products-grid {
    .product-item {
        &-photo {
            position: relative;
            .tw-productlabels {
                width: 100%;
                margin-top: 0;
                .tw-productlabels__label {
                    &--discount {
                        float: right;
                        height: 30px;
                        margin: 8px 0 0 0;
                        .tw-productlabels__text {
                            background-color: transparent;
                            margin-top: -18px;
                        }
                    }
                    &--new {
                        float: left;
                        margin: 8px 0 0 8px;
                    }
                }
            }
        }
        &-details {
            .product-item-link {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0.25rem;
            }
            .price-box {
                margin: 0 0 1rem;
                flex-direction: row-reverse;
                .price {
                    font-size: 18px;
                }
                .special-price .price {
                    color: $sparkjop_special-price__color;
                }
            }
            .swatch-attribute {
                &-options {
                    justify-content: center;
                    margin: 0;
                    .swatch-option{
                        cursor: pointer;
                    }
                }
                // Color/Variant Swatches
                &.level_1 {
                    padding-bottom: 0.25rem;
                    background: $sparkjop_White;
                    .swatch-option {
                        min-width: unset;
                        max-width: unset;
                        min-height: unset;
                        max-height: unset;
                        border: none;

                        &.selected {
                            border: none;
                            box-shadow: none;
                        }

                        &:not(.disabled):hover {
                            border: none;
                        }
                    }
                    .swatch-attribute-options {
                        > div:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
                // Size swatches
                &.manufacturer,
                &.level_2 {
                    .swatch-option {
                        border: none;
                        padding: 0;
                        margin: 0 0 0.5rem 0;
                        width: auto;
                        background: none;
                        display: inline-block;
                        border-radius: unset;
                        height: unset;
                        min-width: unset;
                        &:before {
                            content: '·';
                            margin: 0 0.5rem;
                        }
                        &:first-child:before {
                            display: none;
                            content: '';
                            margin: 0;
                        }
                    }
                    .more-text-swatches {
                        margin: 0 0 0.5rem 0;
                        display: block;
                        width: 100%;
                    }
                }
                &.color {
                    .swatch-more {
                        &:after {
                            margin: 0;
                            line-height: 20px;
                        }
                    }
                }
                .swatch-more {
                    font-size: 0;
                    &:after {
                        $plus-sign-size: 22px;
                        content: '+';
                        font-size: $plus-sign-size;
                        line-height: $plus-sign-size/2;
                        vertical-align: middle;
                        font-weight: bold;
                        color: $color__base;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}

.polarcore {
    #amscroll-navbar {
        position: static;
        background-color: transparent !important;
        text-align: center;
        color: #161616;
        font-size: 14px;
        margin: 0;
        padding: 0;

        progress {
            display: inline-block;
            width: 300px;
            padding: 0;
            border: 0 none;
            background: #ebebeb;
            border-radius: 14px;
        }

        progress::-moz-progress-bar {
            border-radius: 12px;
            background: #ebebeb;
        }


        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            progress {
                height: 15px;
            }
        }

        progress::-webkit-progress-bar {
            background: transparent;
        }

        progress::-webkit-progress-value {
            border-radius: 12px;
            background: #40c353;
        }
    }

    .load-button-primary {
        margin-top: 30px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    #amasty-shopby-product-list {
        .toolbar-products {
            justify-content: flex-end;
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    .products-grid {
        .product-item-photo {
            .tw-productlabels {
                &__label--new {
                    margin-left: 0;
                }
            }
        }
    }
}

// Desktop
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            &-details__top {
                &.out-of-stock {
                    min-height: 75px;
                }
            }
        }
    }
}

// Mobile + Tablet
@include max-screen($screen__l + 1px){
    .products-grid{
        .product-item-info{
            padding: 0;
        }
        .product-items{
            .product-item{
                &-details{
                    .price-box{
                        .old-price,
                        .regular-price,
                        .special-price{
                            .price{
                                font-size: 14px;
                            }
                        }
                        .price{
                            font-size: 14px;
                        }
                    }
                    .product-item-link{
                        font-size: 14px;
                    }
                    .polarcore__details-wrapper{
                        display: none;
                    }
                }
                &-details__bottom{
                    flex-direction: column;
                    .availability{
                        margin: 0.1rem 0;
                    }
                }
            }
        }
    }
}
