#product-review-container {
    .block-title {
        display: none;
    }
    #show-more-review,
    #add-review {
        @include sparkjop-button();
        order: 100;
        margin: auto;
    }
    #add-review {
        width: 60%;
        display: block;
    }
    .review {
        &-list {
            margin-bottom: 20px;
        }
        &-item {
            border-bottom: 1px solid #f5f6fc;
            display: flex;
            flex-wrap: wrap;
        }
        &-title {
            order: 2;
            font-family: $font-family__headers;
            width: 100%;
            margin: 0 0 5px 0;
            color: #2c448e;
            font-size: 20px;
            font-weight: 400;
        }
        &-ratings {
            order: 1;
            width: 100%;
            margin: -10px 0 10px 0;
            max-width: initial;
        }
        &-content {
            order: 3;
            margin: 0 0 6px 0;
            font-size: 16px;
            overflow-y: auto;
            &.show-more {
                max-height: 95px;
                overflow: hidden;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    right: 0;
                    margin: 0;
                    height: 50px;
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
                }
            }
        }
        &-details {
            order: 4;
            margin: 0;
            width: 100%;
        }
        &-author {
            .review-details-value {
                font-weight: 400;
            }
        }
        &-date {
            .review-details-label {
                display: none;
            }
        }
        &-toolbar {
            display: none;
        }
    }
    .rating-summary.item {
        .label {
            display: none;
        }
    }
}
.review {
    &-add {
        display: none;
    }
}
#review-form {
    max-width: 100%;
    .field {
        .label {
            width: 24%;
        }
        .control {
            width: 76%;
            .label {
                display: none;
            }
        }
    }
    .action.submit.primary {
        @include sparkjop-button();
    }
}

@include max-screen($screen__m) {
    #review-form {
        .field {
            .label {
                width: 100%;
            }
            .control {
                width: 100%;
            }
        }
    }
}
