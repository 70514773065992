// /**
//  * Copyright © 2013-2017 Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$toolbar-background: transparent;
$toolbar-element-background: $color__white;
$pager-action__background: $toolbar-element-background;
$pager-action__hover__background: darken($toolbar-element-background, 7%);
$pager__font-weight: bold;

//
//  Common
//  _____________________________________________

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__l;
    text-align: center;

    select {
        background-color: $toolbar-element-background;
        box-shadow: $button__shadow;
    }

    &-amount {
        line-height: $toolbar-mode-icon-font-size + 2;
        padding: 7px 0;
        position: relative;
        text-align: left;
        vertical-align: middle;
        font-weight: bold;
        margin-right: auto;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    &-products {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $toolbar-background;
        margin-bottom: 0;

        &:before,
        &:after {
            content: unset;
        }

        &:last-of-type {
            flex-direction: column;

            .field.limiter {
                margin-top: 1rem;
            }

        }

        .pages {
            display: none;

            .products.wrapper ~ & {
                display: block;
            }
        }
    }

    .pages {
        margin-bottom: $indent__m;

    }

    .pages-items {
        border: 1px solid $polarcore__border-color;
        border-radius: 3px;

        .item .page {
            border-right: 1px solid $polarcore__border-color;
            padding: 0.25rem 1.25rem;

            &:hover {
                background: $sparkjop_WhiteGrey;
            }
        }

        .item:last-of-type .page {
            border-right: none;
        }

    }

    .pages-item-previous {
        & + .item .page {
            border-left: 1px solid $polarcore__border-color;
        }
    }
}

.sorter {
    .page-products & {
        position: relative;
        z-index: 1;
        display: inline-block;
        vertical-align: top;
    }

    &:after {
        @include material-icon($icon-down);
        position: absolute;
        top: 18px;
        right: 7px;
        line-height: 0;
        padding: 0;
        color: $color__action;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }

    &-options {
        @include sparkjop-button();
        color: $color__action;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
        min-width: 175px;
        max-height: unset;
        height: 35px;
        text-align: center;
        text-align-last: center;

        &:focus, &:active {
            border: 2px solid $sparkjop_DarkBlue;
            background-color: $sparkjop_White;
            color: $sparkjop_DarkBlue;
        }
    }

    &-label {
        display: none;
    }

    &-action {
        @include lib-icon-font(
                $icon-sorter,
            $_icon-font-size: 25px,
            $_icon-font-color: $color__action,
            $_icon-font-color-hover: $color__action
        );
        @include lib-icon-text-hide();

        &.sort-desc:before {
            display: none;
        }

        &.sort-asc:before {
            display: none
        }
    }
}

.modes {
    display: none;
}

.limiter {
    display: none;

    &-options {
        margin: 0 5px 0 7px;
        width: auto;
    }

    &-label {
        font-weight: 400;
    }

    .page-products .toolbar & {
        display: none;
    }

    .control {
        display: inline-block;
    }
}


//
//  Mobile + Tablet
//  _____________________________________________

@include max-screen($screen__l) {
    .toolbar {
        &-sorter {
            display: inline-flex;
            width: calc(50% - .5rem);

            .sorter-options {
                height: 50px;
                width: 100%;
            }

            .sorter-action {
                top: 8px;
                right: -30px;
                position: absolute;
            }

            &:after {
                top: 25px;
                right: 10px;
            }
        }

        &-amount {
            width: 100%;
            text-align: center;
            line-height: 1em;
            margin-top: 0;
            margin-bottom: 0.5rem;
            padding: 0;
        }

        .pages {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
    }
    #layered-filter-block {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .toolbar-products {
        margin-bottom: 0;
    }

    .toolbar-sorter {
        .sorter-options {
            padding-right: 2rem;
        }
    }
}

@include min-screen($screen__m) {
    .page-products {
        .columns {
            position: relative;
            z-index: 1;
        }
    }

    .toolbar-amount {
        display: block !important;
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: left;
            margin-bottom: 0;
        }
    }

    .modes {
        display: none;
        float: left;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        background-color: $toolbar-element-background;
        box-shadow: $button__shadow;
        color: $text__color__muted;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: 4px 8px;
        text-align: center;

        &:hover {
            color: $text__color__muted;
            background: darken($toolbar-element-background, 2%);
        }

        &.active {
            box-shadow: $button__shadow-active;
            background: darken($toolbar-element-background, 7%);
            color: $color-gray62;
        }

        @include lib-icon-font(
                $icon-grid,
            $_icon-font-size: $toolbar-mode-icon-font-size,
            $_icon-font-text-hide: true,
            $_icon-font-color: $text__color__muted,
            $_icon-font-color-hover: $text__color__muted
        );
    }

    .mode-list {
        &:before {
            content: $icon-list;
        }
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}
