.minicart-wrapper {
    .block-minicart {
        right: 5px;
        padding: 0;
        background: $sparkjop_White;
        border: none;
        box-shadow: 0px 1px 15px rgba(0,0,0,0.1);
        ::-webkit-scrollbar {
            width: 8px;
            border-radius: 5px;
            &-track {
                background: #f1f1f1;
                border-radius: 5px;
            }
        }
        ::-webkit-scrollbar-thumb {
            background: $sparkjop_Grey;
            border-radius: 5px;
            &:hover {
                background: #555;
            }
        }
        &:before{
            border-color: transparent transparent $sparkjop_White transparent;
        }
        &:after{
            border-color: transparent transparent $sparkjop_LightGrey transparent;
        }
        .subtitle.empty {
            padding: 30px 0 50px;
        }
        .block-content {
            display: flex;
            flex-direction: column;
            .title {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 1rem;
                color: $sparkjop_CodGrey;
                background-color: $sparkjop_WhiteGrey;
                span {
                    text-transform: none;
                    font-size: 18px;
                    font-weight: 700;
                }
                .items-total {
                    margin: 0 0 0 6px;
                    &:before {
                        content: '('
                    }
                    &:after {
                        content: ')';
                    }
                }
            }
            > .actions {
                margin-top: 1rem;
                text-align: center;
                margin: 0;
                padding: 1rem;
                background-color: $sparkjop_WhiteGrey;
                box-shadow: 0px -1px 8px -1px rgba(0, 0, 0, 0.15), 0px -5px 8px -10px rgba(0, 0, 0, 0.15);
                z-index: 9;
                a.action.primary {
                    @include sparkjop-button-cta();
                    width: 100%;
                    span{
                        text-transform: none;
                    }
                }
            }
            .subtotal {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 10px;
                span {
                    text-transform: none;
                }
                .label {
                    display: block;
                    span {
                        text-transform: none;
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
        }
        .items-total {
            display: flex;
            font-size: 18px;
            font-weight: 700;
            span {
                text-transform: unset;
                &.count {
                    margin-right: 5px;
                }
            }
        }
    }
    .minicart-items {
        &-wrapper {
            border: none;
            max-height: 300px;
            margin: 0;
            padding: 0.25rem 1rem 0.5rem;
        }
        .action.delete {
            transform: unset;
            &:before {
                content: "";
                display: block;
                height: 32px;
                width: 32px;
                background-size: 32px 32px;
                background-image: url(../icons/trashcan.svg);
                background-repeat: no-repeat;
                position: absolute;
                right: 0;
                top: 0.25rem;
            }
        }
        .product-item {
            &:first-child {
                padding-top: 10px;
                margin-top: 0;
            }
            background: $sparkjop_White;
            border: none;
            margin: 0.5rem 0.5rem 0 0.5rem;
            padding: 0.5rem 0;
            &-name {
                margin: 0 0 0.5rem 0;
                width: 80%;
                a {
                    display: block;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 700;
                    color: $sparkjop_CodGrey;
                }
            }
            &-details {
                cursor: default;
                padding-left: 5.5rem;
                .details-qty {
                    margin-bottom: 0.3rem;
                    margin-top: 0;
                }
                .actions{
                    .action.edit{
                        display: none;
                    }
                }
                span{
                    text-transform: none;
                }
            }
            .product.options {
                display: flex;
                margin: 0;
                color: $sparkjop_Grey;
                flex-direction: column;
                .product-options-wrapper{
                    display: flex;
                }
                span{
                    text-transform: none;
                }
                .label {
                    font-weight: 100;
                    &:after {
                        content: ':';
                    }
                }
                .values {
                    font-weight: 100;
                    width: 70%;
                    margin: 0 0 0 4px;
                    span {
                        text-transform: none;
                    }
                }
            }
            .price-container {
                .minicart-price {
                    display: flex;
                    flex-direction: row;
                    .old-price{
                        margin-right: 0.5rem;
                        color: $sparkjop_old-price__color;
                        .price{
                            color: $sparkjop_old-price__color;
                        }
                    }
                    .special-price{
                        .price{
                            color: $sparkjop_special-price__color;
                        }
                    }
                    .price-suffix {
                        margin-left: 5px;
                    }
                }
                span {
                    font-size: 18px;
                    &.regular-price{
                        color: $sparkjop_CodGrey;
                    }
                }
            }
        }
    }
}
@include max-screen($screen__s) {
    .minicart-wrapper {
        .block-minicart {
            width: 340px;
        }
    }
}

@include screen($screen__xxs , $screen__xs) {
    .minicart-wrapper {
        .block-minicart {
            width: 290px;
        }
    }
}
