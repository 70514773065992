//
//  List default styles reset
//  ---------------------------------------------

.abs-reset-list {
    @include lib-list-reset-styles();
    & > li {
        margin: 0;
    }
}

//
//  Link as a button
//  ---------------------------------------------

.abs-action-link-button {
    @include lib-button();
    @include lib-link-as-button();
    border-radius: $button__border-radius;
}

//
//  Product options list
//  ---------------------------------------------

@mixin abs-product-options-list() {
    dt {
        clear: left;
        float: left;
        margin: 0 $indent__s $indent__xs 0;

        &:after {
            content: ': ';
        }
    }

    dd {
        float: left;
        margin: 0 0 $indent__xs;
    }
}


.abs-product-options-list {
    @include abs-product-options-list();
}

@include min-screen($screen__m) {
    .abs-product-options-list-desktop {
        @include abs-product-options-list();
    }
}

//
//  Button reset width: floats: margins
//  ---------------------------------------------

.abs-button-responsive {
    @include lib-button-responsive();
}

@include min-screen($screen__m) {
    .abs-button-desktop {
        width: auto;
    }
}

@include max-screen($screen__m) {
    .abs-button-responsive-smaller {
        @include lib-button-responsive();
    }
}

//
//  Blocks in 2 columns
//  ---------------------------------------------

@mixin abs-blocks-2columns() {
    width: 48.8%;

    &:nth-child(odd) {
        clear: left;
        float: left;
    }

    &:nth-child(even) {
        float: right;
    }
}

@include min-screen($screen__m) {
    .abs-blocks-2columns {
        @include abs-blocks-2columns();
    }
}

@include min-screen($screen__s) {
    .abs-blocks-2columns-s {
        @include abs-blocks-2columns();
    }
}

//
//  Reset image alignment in container
//  ---------------------------------------------

.abs-reset-image-wrapper {
    height: auto;
    padding: 0 !important;

    .product-image-photo {
        position: static;
    }
}

//
//  Adaptive images
//  ---------------------------------------------

.abs-adaptive-images {
    display: block;
    height: auto;
    max-width: 100%;
}

.abs-adaptive-images-centered {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

//
//  Title for login blocks
//  ---------------------------------------------

.abs-login-block-title {
    strong {
        font-weight: 500;
    }

    font-size: 18px;
    border-bottom: 1px solid $secondary__color;
    margin-bottom: 15px;
    padding-bottom: 12px;
}

//
//  Abstract block title
//  ---------------------------------------------

.abs-block-title {
    & > strong {
        @include lib-heading(h3);
    }

    margin-bottom: 15px;
}

//
//  Account blocks
//  ---------------------------------------------

.abs-account-blocks {
    .block-title {
        @extend .abs-block-title;
        & > .action {
            margin-left: 15px;
        }
    }

    .box-title {
        display: inline-block;
        margin: 0 0 $indent__xs;

        & > span {
            @include lib-heading(h4);
        }

        & > .action {
            font-weight: 400;
            margin-left: $indent__s;
        }
    }

    .block-content {
        p:last-child {
            margin-bottom: 0;
        }

        .box {
            margin-bottom: $indent__base;
        }
    }
}

//
//  Simple Dropdown
//  ---------------------------------------------

.abs-dropdown-simple {
    @include lib-dropdown(
        $_dropdown-list-item-padding: 5px 5px 5px 23px,
        $_dropdown-list-min-width   : 200px,
        $_icon-font-margin          : 0 0 0 5px,
        $_icon-font-vertical-align  : middle
    );
}

//
//  Input quantity
//  ---------------------------------------------

.abs-input-qty {
    text-align: center;
    width: 47px;
}

//
//  Marging for blocks & widgets
//  ---------------------------------------------

.abs-margin-for-blocks-and-widgets {
    margin-bottom: $indent__xl;
}

//
//  Remove button for blocks
//  ---------------------------------------------

.abs-remove-button-for-blocks {
    @include lib-icon-font(
        $icon-remove,
        $_icon-font-size        : 26px,
        $_icon-font-line-height : 15px,
        $_icon-font-text-hide   : true,
        $_icon-font-color       : $color-gray19,
        $_icon-font-color-hover : $color-gray19,
        $_icon-font-color-active: $color-gray19
    );
}

//
//  Reset left margin
//  ---------------------------------------------

@mixin abs-reset-left-margin() {
    margin-left: 0;
}

.abs-reset-left-margin {
    @include abs-reset-left-margin();
}

@include min-screen($screen__m) {
    .abs-reset-left-margin-desktop {
        @include abs-reset-left-margin();
    }
}

@include min-screen($screen__s) {
    .abs-reset-left-margin-desktop-s {
        @include abs-reset-left-margin();
    }
}

//
//  Action with icon remove with text
//  ---------------------------------------------

.abs-action-remove {
    @extend .abs-action-button-as-link;
    left: $indent__s;
    margin-left: 70%;
    position: absolute;
    top: 31px;
    width: auto;
}

//
//  Action with icon remove with text for desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    @if $form-field-type-label-inline__width != false and $form-field-type-label-inline__width != '' {
        .abs-action-remove-desktop {
            margin-left: $form-field-type-label-inline__width + 50%;
            top: 6px;
        }
    }
}

//
//  Add Recipient
//  ---------------------------------------------

.abs-add-fields {
    .fieldset {
        margin-bottom: 50px;

        .field {
            &:not(.choice) {
                .control {
                    width: 70%;
                }
            }
        }

        .actions-toolbar {
            &:not(:first-child) {
                @extend .abs-add-clearfix;

                & > .secondary {
                    .action {
                        &.add {
                            margin-top: $indent__l;
                        }
                    }

                    float: left;
                }
            }
        }

        .fields {
            .actions-toolbar {
                margin: 0;
            }
        }
    }

    .message {
        &.notice {
            margin: $indent__l 0 0;
        }
    }

    .additional {
        margin-top: 55px;
        position: relative;
    }

    .action {
        &.remove {
            @extend .abs-action-remove;
        }
    }
}

//
//  Add Recipient for desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-add-fields-desktop {
        .fieldset {
            .field {
                &:not(.choice) {
                    .control {
                        width: 50%;
                    }
                }
            }

            .additional {
                .action {
                    &.remove {
                        @extend .abs-action-remove-desktop;
                    }
                }
            }
        }
    }
}

//
//  Margin for forms
//  ---------------------------------------------

@mixin abs-margin-for-forms-desktop() {
    margin-left: $form-field-type-label-inline__width;
}

@include min-screen($screen__m) {
    .abs-margin-for-forms-desktop {
        @include abs-margin-for-forms-desktop();
    }
}

@include min-screen($screen__s) {
    .abs-margin-for-forms-desktop-s {
        @include abs-margin-for-forms-desktop();
    }
}

//
//  Visibility hidden / show visibility hidden
//  ---------------------------------------------

.abs-hidden {
    @include lib-visibility-hidden();
}

//
//  Visually hidden / show visually hidden
//  ---------------------------------------------

.abs-visually-hidden {
    @include lib-visually-hidden();
}

@include max-screen($screen__s) {
    .abs-visually-hidden-mobile {
        @include lib-visually-hidden();
    }
}

@include max-screen($screen__m) {
    .abs-visually-hidden-mobile-m {
        @include lib-visually-hidden();
    }
}

@include min-screen($screen__s) {
    .abs-visually-hidden-desktop-s {
        @include lib-visually-hidden();
    }
}

@include min-screen($screen__m) {
    .abs-visually-hidden-desktop {
        @include lib-visually-hidden();
    }
}

//
//  Visually hidden reset
//  ---------------------------------------------

.abs-visually-hidden-reset {
    @include lib-visually-hidden-reset();
}

//
//  Clearfix
//  ---------------------------------------------

.abs-add-clearfix {
    @include lib-clearfix();
}

@include min-screen($screen__m) {
    .abs-add-clearfix-desktop {
        @include lib-clearfix();
    }
}

@include min-screen($screen__s) {
    .abs-add-clearfix-desktop-s {
        @include lib-clearfix();
    }
}

@include max-screen($screen__s) {
    .abs-add-clearfix-mobile {
        @include lib-clearfix();
    }
}

@include max-screen($screen__m) {
    .abs-add-clearfix-mobile-m {
        @include lib-clearfix();
    }
}

//
//  Box-sizing
//  ---------------------------------------------

@mixin abs-add-box-sizing() {
    box-sizing: border-box;
}

.abs-add-box-sizing {
    @include abs-add-box-sizing();
}

@include min-screen($screen__l) {
    .abs-add-box-sizing-desktop {
        @include abs-add-box-sizing();
    }
}

@include min-screen($screen__s) {
    .abs-add-box-sizing-desktop-s {
        @include abs-add-box-sizing();
    }
}

@include min-screen($screen__m) {
    .abs-add-box-sizing-desktop-m {
        @include abs-add-box-sizing();
    }
}

//
//  Revert field type
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-revert-field-type-desktop {
        .fieldset {
            & > .field,
            .fields > .field {
                @include lib-form-field-type-revert($_type: block);

                &:not(:first-child):last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

//
//  Settings icons
//  ---------------------------------------------

.abs-navigation-icon {
    @include lib-icon-font(
        $_icon-font-content    : $icon-down,
        $_icon-font-size       : 34px,
        $_icon-font-line-height: 1.2,
        $_icon-font-position   : after,
        $_icon-font-display    : block
    );

    &:after {
        position: absolute;
        right: $indent__xs;
        top: 0;
    }
}

//
//  Split button
//  ---------------------------------------------

.abs-split-button {
    @include lib-dropdown-split(
        $_options-selector                       : '.items',
        $_dropdown-split-button-border-radius-fix: true
    );
    vertical-align: middle;
}

//
//  Action addto
//  ---------------------------------------------
.abs-action-addto-product {
    @extend .abs-action-link-button;
    @include lib-button-s();
}

.abs-actions-addto-gridlist {
    @include lib-icon-font(
        $_icon-font-content       : inherit,
        $_icon-font-size          : 29px,
        $_icon-font-color         : $addto-color,
        $_icon-font-color-hover   : $addto-hover-color,
        $_icon-font-text-hide     : true,
        $_icon-font-vertical-align: middle,
        $_icon-font-line-height   : 24px
    );
}

//
//  Large button
//  ---------------------------------------------

.abs-button-l {
    @include lib-button-l();
}

//
//  Button as a link
//  ---------------------------------------------

.abs-action-button-as-link {
    @include lib-button-as-link();
    border-radius: 0;
    font-size: inherit;
    font-weight: $font-weight__regular;

    &:active,
    &:not(:focus) {
        box-shadow: none;
    }
}

//
//  Button revert secondary color
//  ---------------------------------------------

.abs-revert-secondary-color {
    @include lib-button-revert-secondary-color();
}

//
//  Button revert secondary size
//  ---------------------------------------------

.abs-revert-secondary-size {
    @include lib-button-revert-secondary-size();
}

//
//  Box-tocart block
//  ---------------------------------------------

.abs-box-tocart {
    margin: $indent__s 0;
}

//
//  Excl/Incl tax
//  ---------------------------------------------

.abs-adjustment-incl-excl-tax {
    .price-including-tax,
    .price-excluding-tax,
    .weee {
        font-size: 14px;
        display: inline-block;
        white-space: nowrap;
    }

    .price-including-tax + .price-excluding-tax {
        display: inline-block;
        font-size: 11px;

        &:before {
            content: '('attr(data-label)': ';
        }

        &:after {
            content: ')';
        }
    }
}

//
//  Cart tax total
//  ---------------------------------------------

.abs-tax-total {
    cursor: pointer;
    padding-right: 12px;
    position: relative;
    @include lib-icon-font(
        $icon-down,
        $_icon-font-size       : 26px,
        $_icon-font-line-height: 10px,
        $_icon-font-margin     : 3px 0 0 0,
        $_icon-font-position   : after
    );

    &:after {
        position: absolute;
        right: -$indent__s;
        top: 3px;
    }

    &-expanded {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-up,
            $_icon-font-position: after
        );
    }
}

.abs-tax-total-expanded {
    @include lib-icon-font-symbol(
        $_icon-font-content: $icon-up,
        $_icon-font-position: after
    );
}

//
//  Checkout shipping methods title
//  ---------------------------------------------

.abs-methods-shipping-title {
    font-size: 14px;
    font-weight: $font-weight__bold;
    margin: 0 0 15px;
}

//
//  Checkout order review price
//  ---------------------------------------------

.abs-checkout-cart-price {
    display: auto;
}

//
//  Checkout order product name
//  ---------------------------------------------

.abs-checkout-product-name {
    display: auto;
}

//
//  Checkout order review
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-checkout-order-review {
        tbody tr {
            &:not(:last-child) {
                border-bottom: $border-width__base solid $border-color__base;
            }

            @extend .abs-add-clearfix-mobile;

            .col {
                &.item {
                    &:before {
                        display: none;
                    }
                }

                &.qty,
                &.price,
                &.subtotal {
                    box-sizing: border-box;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }
            }

            .product-item-name {
                margin: 0;
            }
        }
    }
}

//
//  Add colon
//  ---------------------------------------------

.abs-colon {
    &:after {
        content: ': ';
    }
}

//
//  Icon - create add
//  ---------------------------------------------

.abs-icon-add {
    @include lib-icon-font(
        $_icon-font-content       : $icon-expand,
        $_icon-font-size          : 10px,
        $_icon-font-line-height   : 10px,
        $_icon-font-vertical-align: middle
    );
}

@include max-screen($screen__m) {
    .abs-icon-add-mobile {
        @include lib-icon-font(
            $_icon-font-content       : $icon-expand,
            $_icon-font-size          : 10px,
            $_icon-font-line-height   : 10px,
            $_icon-font-vertical-align: middle,
            $_icon-font-margin        : 0 5px 0 0,
            $_icon-font-display       : block
        );
    }
}

//
//  Dropdown items - create new
//  ---------------------------------------------

.abs-dropdown-items-new {
    .items .item:last-child {
        &:hover {
            background: $color-gray91;
        }
    }

    .action.new {
        @extend .abs-icon-add;
        &:before {
            margin-left: -17px;
            margin-right: $indent__xs;
        }
    }
}

//
//  Abstract toggle title block
//  ---------------------------------------------

@include max-screen($screen__m) {
    .abs-toggling-title-mobile {
        border-bottom: $border-width__base solid $border-color__base;
        border-top: $border-width__base solid $border-color__base;
        cursor: pointer;
        margin-bottom: 0;
        padding: $indent__s $indent__xl $indent__s $layout__width-xs-indent;
        position: relative;

        @include lib-icon-font(
            $_icon-font-content  : $icon-down,
            $_icon-font-size     : 28px,
            $_icon-font-text-hide: false,
            $_icon-font-position : after,
            $_icon-font-display  : block
        );

        &:after {
            position: absolute;
            right: $indent__s;
            top: 0;
        }

        &.active {
            @include lib-icon-font-symbol(
                $_icon-font-content : $icon-up,
                $_icon-font-position: after
            );
        }
    }
}

//
//  Abstract no display
//  ---------------------------------------------

.abs-no-display {
    display: none;
}

@include max-screen($screen__s) {
    .abs-no-display-s {
        display: none;
    }
}

@include min-screen($screen__m) {
    .abs-no-display-desktop {
        display: none;
    }
}

//
//  Status
//  ---------------------------------------------

.abs-status {
    display: inline-block;
    margin-bottom: $indent__base;
}

//
//  Pager toolbar for non-catalog pages mobile
//  ---------------------------------------------


@include max-screen($screen__m) {
    .abs-pager-toolbar-mobile {
        .toolbar-amount,
        .limiter,
        .pages {
            float: none;
            margin-bottom: $indent__m;
        }
    }
}

//
//  Pager toolbar for non-catalog pages mobile
//  ---------------------------------------------

@include max-screen($screen__s) {
    .abs-pager-toolbar-mobile-s {
        .toolbar-amount,
        .limiter,
        .pages {
            margin-bottom: $indent__m;
        }
    }
}

//
//  Pager toolbar for non-catalog pages desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .abs-pager-toolbar {
        @extend .abs-add-clearfix-desktop;
        margin-bottom: $indent__base;
        position: relative;
        .limiter {
            float: right;
            position: relative;
            z-index: 1;
        }

        .toolbar-amount {
            float: left;
            line-height: normal;
            padding: 7px 0 0;
            position: relative;
            z-index: 1;
        }

        .pages {
            position: absolute;
            width: 100%;
            z-index: 0;
        }
    }
}

//
//  Items counter in blocks
//  ---------------------------------------------

.abs-block-items-counter {
    color: $primary__color__lighter;
    font-size: 12px;
    white-space: nowrap;
}

//
//  Shopping cart items
//  ---------------------------------------------

.abs-shopping-cart-items {
    .action {
        &.continue {
            border-radius: 3px;
            font-weight: $font-weight__bold;
            @include lib-link-as-button();
            @include lib-button(
                $_button-padding              : 7px 15px 7px 0,
                $_button-icon-use             : true,
                $_button-font-content         : $icon-prev,
                $_button-icon-font-size       : 32px,
                $_button-icon-font-line-height: 16px,
                $_button-icon-font-position   : before
            );

            &:active {
                box-shadow: $button__shadow;
            }
        }

        &.update {
            @include lib-button-icon(
                $icon-update,
                $_icon-font-size       : 24px,
                $_icon-font-line-height: 16px
            );
            padding-left: $indent__xs;
        }
    }
}

@include max-screen($screen__m) {
    .abs-shopping-cart-items-mobile {
        .actions {
            text-align: center;
        }

        .action {
            &.update,
            &.continue,
            &.clear {
                margin: 0 auto $indent__s;
            }

            &.update,
            &.clear {
                display: block;
            }
        }
    }
}

@include min-screen($screen__m) {
    .abs-shopping-cart-items-desktop {
        float: left;
        position: relative;
        width: 73%;

        .actions {
            text-align: right;
        }

        .action {
            &.continue {
                float: left;
            }

            &.clear {
                margin-right: $indent__s;
            }
        }
    }
}

//
//  Form Field Date
//  ---------------------------------------------

.abs-field-date {
    .control {
        @extend .abs-add-box-sizing;
        position: relative;
    }

    input {
        @extend .abs-field-date-input;
    }
}

//
//  Form Field Date Input
//  ---------------------------------------------

.abs-field-date-input {
    margin-right: $indent__s;
    width: calc(100% - #{$icon-calendar__font-size} - #{$indent__s});
}

//
//  Form Field Tooltip
//  ---------------------------------------------

.abs-field-tooltip {
    @extend .abs-add-box-sizing;
    position: relative;

    input {
        margin-right: $indent__s;
        width: calc(100% - #{$checkout-tooltip-icon__font-size} - #{$indent__s} - #{$indent__xs});
    }
}

//
//  Checkout title
//  ---------------------------------------------

.abs-checkout-title {
    border-bottom: $checkout-step-title__border;
    padding-bottom: $checkout-step-title__padding;
    @include lib-typography(
        $_font-size  : $checkout-step-title__font-size,
        $_font-weight: $checkout-step-title__font-weight,
        $_font-family: false,
        $_font-style : false,
        $_line-height: false
    );
}

//
//  Shopping cart sidebar and checkout sidebar totals
//  ---------------------------------------------

.abs-sidebar-totals {
    .mark {
        font-weight: $font-weight__regular;
        padding-left: 4px;

        strong {
            font-weight: $font-weight__regular;
        }
    }

    .amount {
        padding-right: 4px;
        text-align: right;
        white-space: nowrap;

        strong {
            font-weight: $font-weight__regular;
        }
    }

    .grand {
        .mark,
        .amount {
            padding-top: $indent__base;
        }

        .amount {
            padding-right: 4px;
            text-align: right;

            strong {
                font-weight: $font-weight__bold;
            }
        }
    }

    .msrp {
        margin-bottom: $indent__s;
    }

    .totals-tax {
        &-summary {
            .mark,
            .amount {
                border-top: $border-width__base solid $border-color__base;
                border-bottom: $border-width__base solid $border-color__base;
                cursor: pointer;
            }

            .amount .price {
                @include lib-icon-font(
                    $icon-down,
                    $_icon-font-size     : 30px,
                    $_icon-font-text-hide: true,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );
                padding-right: $indent__m;
                position: relative;

                &:after {
                    position: absolute;
                    right: -5px;
                    top: -12px;
                }
            }

            &.expanded {
                .mark,
                .amount {
                    border-bottom: 0;
                }

                .amount .price {
                    @include lib-icon-font-symbol(
                        $_icon-font-content : $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }

        &-details {
            display: none;
            border-bottom: $border-width__base solid $border-color__base;

            &.shown {
                display: table-row;
            }
        }
    }

    .table-caption {
        @extend .abs-no-display;
    }
}

//
//  Shopping cart and payment discount codes block
//  ---------------------------------------------

.abs-discount-block {
    & > .title {
        border-top: $border-width__base solid $border-color__base;
        cursor: pointer;
        font-weight: $font-weight__semibold;
        @include lib-icon-font(
            $_icon-font-content : $icon-down,
            $_icon-font-size    : 30px,
            $_icon-font-position: after,
            $_icon-font-display : block
        );
        margin-bottom: 0;
        overflow: hidden;
        padding: 7px $indent__l 7px $indent__xs;
        position: relative;

        &:after {
            position: absolute;
            right: -5px;
            top: -5px;
        }

        strong {
            .column.main & {
                font-size: 18px;
                font-weight: $font-weight__regular;
            }
        }
    }

    & > .content {
        display: none;
    }

    &.active {
        & > .title {
            @include lib-icon-font-symbol(
                $_icon-font-content : $icon-prev,
                $_icon-font-position: after
            );
        }

        & > .content {
            display: block;
        }
    }
}
